import { InvoiseUrSet, InvoiseUrrSet, erpUtilitiesUmcService, PaymentUrSet, PaymentDocuments } from "erp-utilities-umc-service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import DataTable, { TableStyles, TableColumn } from "react-data-table-component";
import LoggedTemplate from "../components/LoggedTemplate";
import { AuthData } from "../contexts/AuthContextProvider";
import styles from "../components/LoggedTemplate.module.css";
import { stat } from "fs";
import { Reports } from "./Reports";
import { MobileHeaderNav } from "../components/MobileHeaders";

moment.locale('ru');

interface IProps {
    context: AuthData;
}




export function MobileReports(props: IProps) {
    const [state, setState] = useState({

    });

    useEffect(() => {
    }, []);


    if (!props.context.isMobile) return <></>;

    return (<LoggedTemplate>
        <div>
            <MobileHeaderNav context={props.context} title="Отчёты" backUrl={"/"} ></MobileHeaderNav>
            <Reports context={props.context}></Reports>
        </div>
    </LoggedTemplate>);
}
