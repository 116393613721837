import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PageTemplate from "../components/PageTemplate";




export function ToDo() {
    let navigate = useNavigate();
    const goHome = () => {
        navigate("/");
    };
    const goBack = () => {
        navigate(-1);
    };
    return <PageTemplate>
        <Row className=" mt-auto">
            <Col>
                <h4 className="text-center mb-3">Страница в разработке</h4>
                <div className="text-center">
                    <Button variant="outline-primary mx-2" onClick={goBack}>Вернуться назад</Button>
                    <Button variant="primary mx-2" onClick={goHome}>Перейти на главную</Button>
                </div>
            </Col>
        </Row>
    </PageTemplate>;
}
