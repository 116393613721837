import { TableStyles } from "react-data-table-component";
import moment from "moment";
moment.locale('ru');

export const paginationComponentOptions = {
	rowsPerPageText: 'Строк на странице',
	rangeSeparatorText: 'из',
	selectAllRowsItem: true,
	selectAllRowsItemText: 'Все',
};

export const tableStyles: TableStyles = {
    rows: {
        style: {
            minHeight: '40px',
        }
    },
    headCells: {
        style: {
            fontWeight: '600', // override the cell padding for head cells
            fontSize: '14px'
        },
    },
    cells: {
        style: {
            fontSize: '14px'
        },
    },
};

export const tableStylesPointer: TableStyles = {
    rows: {
        style: {
            minHeight: '40px',
            cursor: 'pointer'
        }
    },
    headCells: {
        style: {
            fontWeight: '600', // override the cell padding for head cells
            fontSize: '14px'
        },
    },
    cells: {
        style: {
            fontSize: '14px'
        },
    },
};

export const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
