import { useContext, useState } from "react";
import { AuthContext, User } from "../contexts/AuthContextProvider";
import styles from "../components/LoginTemplate.module.css";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import { Button, FloatingLabel, Form, Modal, Nav } from "react-bootstrap";
import LoginTemplate from "../components/LoginTemplate";
import { erpUtilitiesUmcService } from "erp-utilities-umc-service";
import { UserRequest, erpUtilitiesUmcUrmService } from "erp-utilities-umc-urm-service";
import { HttpMiddleware, HttpRequestConfig, HttpResponse, csrf } from "@sap-cloud-sdk/http-client";
import { LoginNav } from "../components/LoginNav";
import { RemoveNullFields } from "../components/RemoveNullFieldsMiddleware";
import InputMask from "react-input-mask";


export function Reg() {
  const [state, setState] = useState({ btnDisabled: false, error: '', showPd: false, lknum: '', phoneNumber: '', email: '', firstName: '', lastName: '' });
  let context = useContext(AuthContext);
  let navigate = useNavigate();
  console.log('Reg page load, user =', context.user);
  if (context.user?.Account) return <Navigate to="/"></Navigate>;

  const formSubmit = async (e) => {
    e.preventDefault();


    let data = new FormData(e.target);
    try {
      console.log('Reg submit');

      var contractAccountId = state.lknum.replace(/[^0-9]/g, ''); //data.get("contractAccountId").toString();
      if (contractAccountId.length != 12) {
        context.showMessage(<>Некорректный номер лицевого счёта.<br />(должен состоять из 12 цифр)</>, true);
        return;
      }
      var emailAddress = data.get("emailAddress").toString();
      var firstName = state.firstName; //data.get("firstName").toString();
      var lastName = state.lastName; // data.get("lastName").toString();
      var nameMid = data.get("nameMid").toString();
      var personalDataAgree = data.get("personalDataAgree")?.toString() == "on";
      var phoneNumber = state.phoneNumber; //data.get("phoneNumber").toString();
      phoneNumber = phoneNumber.replace('+7', '').replace(/[\s\_]/g, '');
      if (phoneNumber.length != 14) {
        context.showMessage('Некорректный номер телефона', true);
        return;
      }

      var receivingInfoAgree = data.get("receivingInfoAgree")?.toString() == "on";
      //data.forEach((v,key)=>console.log('[data]',key,v));
      console.log('contractAccountId', contractAccountId);
      console.log('emailAddress', emailAddress);
      console.log('firstName', firstName);
      console.log('lastName', lastName);
      console.log('nameMid', nameMid);
      console.log('personalDataAgree', personalDataAgree);
      console.log('phoneNumber', phoneNumber);
      console.log('receivingInfoAgree', receivingInfoAgree);

      setState({ ...state, btnDisabled: true, error: '' });
      context.setLoading(true);
      const { userRequestApi } = erpUtilitiesUmcUrmService();
      var user = userRequestApi.entityBuilder()
        .contractAccountId(contractAccountId)
        .emailAddress(emailAddress)
        .firstName(firstName)
        .lastName(lastName)
        .nameMid(nameMid)
        .personalDataAgree(personalDataAgree.toString())
        .phoneNumber(phoneNumber)
        .receivingInfoAgree(receivingInfoAgree.toString())
        .usrCategory("363")
        .build();

      var req = await userRequestApi.requestBuilder()
        .create(user)
        .addCustomQueryParameters(context.getOdataExtraFields())
        .skipCsrfTokenFetching()
        .middleware([RemoveNullFields, csrf({ url: `${UserRequest._defaultBasePath}` })])
        .execute({
          url: context.host,
          headers: { "x-requested-with": "XMLHttpRequest" }
        });
      console.log('reg result', req);


      context.showMessage(<>
        <div className="mb-4 pb-2 text-center">На телефон, указанный при регистрации, отправлено<br />SMS с паролем.</div>
        <div className="text-center">
          <Button onClick={() => { context.showMessage('', false); navigate('/login'); }} variant="primary" className="px-4">Войти в систему</Button>
        </div>
      </>, false);

    } catch (err) {
      console.warn('odata err', err);
      //alert('Регистрация не удалась, проверьте логин и пароль.');
      var message = err.rootCause?.response?.data?.error?.message?.value ?? err.message;
      if (message == 'Имя пользователя существует') message = 'Имя пользователя существует. Воспользуйтесь восстановлением пароля.';
      if (message != '') context.showMessage(message, true);
      setState({
        ...state,
        btnDisabled: false
      });
      context.setLoading(false);
      return;
    }
    context.setLoading(false);
  };


  const inputs = () => <>
    <FloatingLabel label="Фамилия*" className="mb-3 has-validation">
      <Form.Control type="text" placeholder="Фамилия*" name="lastName" onChange={e => setState({ ...state, lastName: e.target.value })}
        required className={state.lastName !== '' ? '' : 'is-invalid'} />
    </FloatingLabel>

    <FloatingLabel label="Имя*" className="mb-3 has-validation">
      <Form.Control type="text" placeholder="Имя*" name="firstName" onChange={e => setState({ ...state, firstName: e.target.value })}
        required className={state.firstName !== '' ? '' : 'is-invalid'} />
    </FloatingLabel>

    <FloatingLabel label="Отчество" className="mb-3 has-validation">
      <Form.Control type="text" placeholder="Отчество" name="nameMid" />
    </FloatingLabel>

    <FloatingLabel label="Номер лицевого счета" className="mb-3 has-validation">
      <InputMask mask="999999999999" value={state.lknum} onChange={e => setState({ ...state, lknum: e.target.value })}>
        <Form.Control type="text" placeholder="Номер лицевого счета" name="contractAccountId" required className={state.lknum !== '' ? '' : 'is-invalid'} />
      </InputMask>
      <div className={`invalid-feedback loginForm_invalid_feedback`}>
        {state.error}
      </div>
    </FloatingLabel>

    <FloatingLabel label="Мобильный телефон*" className="mb-3 has-validation">
      <InputMask mask="+7 (999) 999-99-99" value={state.phoneNumber} onChange={e => setState({ ...state, phoneNumber: e.target.value })}>
        <Form.Control type="text" placeholder="Мобильный телефон*" name="phoneNumber" required className={state.phoneNumber !== '' ? '' : 'is-invalid'} />
      </InputMask>
    </FloatingLabel>

    <FloatingLabel label="Адрес электронной почты*" className="mb-3 has-validation">
      <Form.Control type="email" placeholder="Адрес электронной почты*" name="emailAddress" required className={/\S+@\S+\.\S+/.test(state.email) ? '' : 'is-invalid'}
        pattern='\S+@\S+\.\S+' onChange={e => setState({ ...state, email: e.target.value })} />
    </FloatingLabel>

    <Form.Check type="checkbox" name="personalDataAgree" label={`Согласие на обработку персональных данных*`} id="personalDataAgree"
      onChange={(e) => { if (e.target.checked) setState({ ...state, showPd: true }) }} required className="mb-3" />
    <Form.Check type="checkbox" name="receivingInfoAgree" label={`Получать уведомления и информацию через SMS`} id="receivingInfoAgree" className="mb-3" />

  </>

  return (
    <LoginTemplate>
      {!context.isMobile &&
        <>
          <h4 className={`my-4 ${styles.loginHeader}`}>Регистрация</h4>
          <LoginNav context={context} />
          <form onSubmit={formSubmit}>
            {inputs()}
            <Button type="submit" variant="primary" className="mt-3 w-100" disabled={state.btnDisabled}>Зарегистрироваться</Button>
          </form>
        </>
      }
      {context.isMobile && <>
        <form onSubmit={formSubmit} className="d-flex flex-column justify-content-between" style={{ minHeight: "100vh" }}>
          <LoginNav context={context} />
          {inputs()}
          <Button type="submit" variant="primary" className="mt-auto w-100 mb-2" disabled={state.btnDisabled}>Зарегистрироваться</Button>
        </form>
      </>}

      {state.showPd && <>

        <Modal show={state.showPd} onHide={() => setState({ ...state, showPd: false })} centered>
          <Modal.Header closeButton>
            <Modal.Title>Внимание</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              В соответствии с Федеральным законом "О персональных данных" от 27.07.2006 № 152-ФЗ своей волей и в своем интересе на обработку с
              использованием средств автоматизации либо без использования таковых, при использовании личного кабинета с
              использованием открытых каналов передачи данных сети интернет (в том числе на сбор (в том числе от третьих лиц),
              систематизацию, накопление, хранение, уточнение, обновление, изменение, использование, распространение, передачу,
              обезличивание, блокирование и уничтожение) моих персональных данных, в том числе в целях предоставления их участникам
              информационного обмена в рамках процесса установления и применения социальной нормы потребления электрической энергии
              на территории Самарской области, в органы социальной защиты населения, в органы государственной власти, органы местного
              самоуправления, в иные учреждения и организации, а также из иных информационных ресурсов, из архивов, органов регистрационного учета и иных учреждений и организаций.
            </p>
            <p>
              В случае реализации мной своего права на создание личного кабинета, расположенного на официальном сайте гарантирующего
              поставщика ПАО "Самараэнерго", я использую выбранный мной пароль для доступа к своему личному кабинету и несу ответственность
              за его конфиденциальность и сохранность, а также за все действия, которые будут осуществлены в личном кабинете с использованием моего пароля и логина.
            </p>
            <Button variant="primary" className="mt-3 w-100" onClick={() => setState({ ...state, showPd: false })}>Закрыть</Button>
          </Modal.Body>
        </Modal>

      </>}

    </LoginTemplate>
  );

}