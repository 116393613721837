import { Dispatch, SetStateAction, useEffect, useState } from "react";
import LoggedTemplate from "../components/LoggedTemplate";
import { AuthData } from "../contexts/AuthContextProvider";

// import TreeView from '@mui/lab/TreeView';
// import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';

import { Badge, Button, Col, Container, Modal, Row } from "react-bootstrap";

import { Meter, MeterList } from "../components/MeterList";
import { NotifSet, setNotif } from "erp-utilities-umc-service";
import DataTable, { TableColumn, TableStyles } from "react-data-table-component";
import { MobileHeaderNav } from "../components/MobileHeaders";
import context from "react-bootstrap/esm/AccordionContext";
import { paginationComponentOptions, tableStyles, tableStylesPointer } from "../components/Table";
import { useParams } from "react-router-dom";

// 0007, 0008, 0009 - это модально
// 0010, 0011, 0012 - это только в списке
// 0001, 0005, 0006 - это на всех страницах
// 0002, 0003, 0004 это Взыскание. Если нет ограничений, 0002 с пустым bpcontact

interface IProps {
    context: AuthData;
}
export const isNotReadedNotif = (n: NotifSet) => n.bpcontact?.length > 0 && n.zstatus != 'X'

export function Notifications(props: IProps) {
    const { vkona } = useParams();
    const [selectedMeter, setSelectedMeter] = useState<Meter | undefined>(undefined);
    const [popup, setPopup] = useState<NotifSet | undefined>(undefined)

    var notifArray = props.context.notifications.find(n => n.Vkona == selectedMeter?.ca.vkona)?.Notifications ?? [];
    var penalty = notifArray.find(n => n.activity == '0003' || n.activity == '0004' || n.activity == '0004')

    const conditionalRowStyles = [
        {
            when: (row: NotifSet) => isNotReadedNotif(row),
            style: {
                fontWeight: 'bold'
            },
        }
    ];


    if (props.context.isMobile) {

        const columnsActsMobile: TableColumn<NotifSet>[] = [
            {
                name: 'Дата',
                selector: row => row.date,
                format: row => row.date,
                sortable: false,
                width: "7rem"
            },
            {
                name: 'Уведомление',
                selector: row => row.acttxt,
                sortable: false,
                width: 'calc(100vw - 10rem)'
            }
        ]

        return (<>
            <LoggedTemplate>
                <div>
                    <MobileHeaderNav context={props.context} title="Уведомления" backUrl={"/"} ></MobileHeaderNav>
                    <MeterList context={props.context} onSelect={setSelectedMeter} defaultSelectedVkona={vkona}></MeterList>

                    <h5 className="mt-4 mb-3">Взыскание задолженности</h5>
                    <DataTable
                        columns={columnsActsMobile}
                        data={notifArray.filter(n => n.activity == '0002' || n.activity == '0003' || n.activity == '0004')}
                        customStyles={tableStylesPointer}
                        noDataComponent={<div style={{ padding: '24px' }}>Нет данных для отображения</div>}
                        pagination
                        style={{ "fontSize": "0.75rem" }}
                        highlightOnHover
                        onRowClicked={setPopup}
                        conditionalRowStyles={conditionalRowStyles}
                        paginationComponentOptions={paginationComponentOptions}
                    />

                    <h5 className="mt-4 mb-3">Уведомления</h5>
                    <DataTable
                        columns={columnsActsMobile}
                        data={notifArray.filter(n => n.activity != '0002' && n.activity != '0003' && n.activity != '0004')}
                        customStyles={tableStylesPointer}
                        noDataComponent={<div style={{ padding: '24px' }}>Нет данных для отображения</div>}
                        pagination
                        style={{ "fontSize": "0.75rem" }}
                        highlightOnHover
                        onRowClicked={setPopup}
                        conditionalRowStyles={conditionalRowStyles}
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>
                <NotificationPopup n={popup} setN={setPopup} context={props.context} />
            </LoggedTemplate>
        </>);
    }

    const columnsActsDesktop: TableColumn<NotifSet>[] = [
        {
            name: 'Дата',
            selector: row => row.date,
            sortable: false,
            width: "10rem"
        },
        // {
        //     name: 'Уведомление',
        //     selector: row => row.acttxt,
        //     sortable: false,
        //     width: '15rem'
        // },
        {
            name: 'Уведомление',
            selector: row => row.textShort,
            sortable: false,
            width: 'calc(1140px - 18rem)'
        },
        // {
        //     name: 'activity',
        //     selector: row => row.activity,
        //     sortable: true
        // },
        /*{
           name: 'bpcontact',
           selector: row => row.bpcontact,
           sortable: true
       },
       {
           name: 'textLong',
           selector: row => row.textLong,
           sortable: true
       },
       {
           name: 'zstatus',
           selector: row => row.zstatus,
           sortable: true
       },*/
    ];


    return (
        <LoggedTemplate>

            <MeterList context={props.context} onSelect={setSelectedMeter} defaultSelectedVkona={vkona}></MeterList>

            {selectedMeter && <div className="white-card mx-2 mb-4">
                <h5 className="pb-3">Взыскание задолженности</h5>

                <DataTable
                    columns={columnsActsDesktop}
                    data={notifArray.filter(n => n.activity == '0002' || n.activity == '0003' || n.activity == '0004')}
                    highlightOnHover
                    customStyles={tableStylesPointer}
                    noDataComponent={<div style={{ padding: '24px' }}>Нет данных для отображения</div>}
                    pagination
                    onRowClicked={setPopup}
                    conditionalRowStyles={conditionalRowStyles}
                    paginationComponentOptions={paginationComponentOptions}
                />
            </div>}

            {selectedMeter && <div className="white-card mx-2 mb-4">
                <h5 className="pb-3">Уведомления</h5>

                <DataTable
                    columns={columnsActsDesktop}
                    data={notifArray.filter(n => n.activity != '0002' && n.activity != '0003' && n.activity != '0004')}
                    highlightOnHover
                    customStyles={tableStylesPointer}
                    noDataComponent={<div style={{ padding: '24px' }}>Нет данных для отображения</div>}
                    pagination
                    onRowClicked={setPopup}
                    conditionalRowStyles={conditionalRowStyles}
                    paginationComponentOptions={paginationComponentOptions}
                />
            </div>}
            <NotificationPopup n={popup} setN={setPopup} context={props.context} />
        </LoggedTemplate>
    );
}

export function NotificationPopup(props: { n: NotifSet | undefined, setN: Dispatch<SetStateAction<NotifSet>>, context: AuthData }) {

    return (<Modal show={props.n != undefined} centered autoFocus backdrop="static" size="lg">
        <Modal.Header>
            <Modal.Title className="d-flex align-items-center gap-2">
                {props.n?.acttxt}
                <Badge bg="primary">
                    Сведения о л/с №{props.n?.vkona}
                </Badge>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>{props.n?.textLong}</div>
        </Modal.Body>
        <Modal.Footer className="">
            <Button variant="primary" onClick={async () => {
                props.setN(undefined)
                await props.context.setNotificationReaded(props.n)
            }}>Прочитано</Button>
        </Modal.Footer>
    </Modal>)
}