import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Container, FloatingLabel, Form, InputGroup, Modal, Row } from "react-bootstrap";
import LoggedTemplate from "../components/LoggedTemplate";
import { AuthData } from "../contexts/AuthContextProvider";
import 'moment/locale/ru'
import { ReactComponent as EditImage } from './../images/edit.svg';
import { ReactComponent as MobileEditImage } from './../images/mobile_edit.svg';
import { ReactComponent as CloseImage } from './../images/icn_close_gray.svg';
import { Switch } from "@mui/material";
import { Accounts, ContractConsumptionValues, erpUtilitiesUmcService } from "erp-utilities-umc-service";
import { csrf } from "@sap-cloud-sdk/http-client";
import InputMask from "react-input-mask";
import { MobileHeaderNav } from "../components/MobileHeaders";

interface IProps {
    context: AuthData;
}


export async function HandleGrantSwitchChange(context: AuthData, flag: string, event: React.ChangeEvent<HTMLInputElement>, done) {
    context.setLoading(true);
    try {
        console.log(flag, 'is', event.target.checked, 'now for partner', context.user.Account.accountId);
        const { zmfustatusSetApi, accountAddressDependentEmailsApi } = erpUtilitiesUmcService();
        if (!event.target.checked)
            await zmfustatusSetApi.requestBuilder()
                .delete(context.user.Account.accountId, flag)
                .addCustomQueryParameters(context.getOdataExtraFields())
                .skipCsrfTokenFetching()
                .middleware([csrf({ url: `${ContractConsumptionValues._defaultBasePath}?sap-user=${encodeURIComponent(context.user.login)}&sap-password=${encodeURIComponent(context.user.password)}` })])
                .execute({ url: context.host });
        else {
            var status = zmfustatusSetApi.entityBuilder()
                .partner(context.user.Account.accountId)
                .status(flag)
                .action('Y')
                .build();
            await zmfustatusSetApi.requestBuilder()
                .create(status)
                .addCustomQueryParameters(context.getOdataExtraFields())
                .skipCsrfTokenFetching()
                .middleware([csrf({ url: `${ContractConsumptionValues._defaultBasePath}?sap-user=${encodeURIComponent(context.user.login)}&sap-password=${encodeURIComponent(context.user.password)}` })])
                .execute({ url: context.host });
        }
        if (done) done();
        return true;
    } catch (e) {
        console.error('odata err', e);
        return false;
    } finally {
        context.setLoading(false);
    }
};

export async function LoadGrantState(context: AuthData, code: string) {
    const { zmfustatusSetApi, accountAddressesApi } = erpUtilitiesUmcService();
    var statusSms = await zmfustatusSetApi
        .requestBuilder()
        .getByKey(context.user.Account.accountId, code)
        .addCustomQueryParameters(context.getOdataExtraFields())
        .execute(
            { url: context.host }
        );
    console.log('statusSms', code, statusSms);
    return statusSms.action == 'X';
}


export function Settings(props: IProps) {

    const [state, setState] = useState({
        account: {} as Accounts,
        allowSms: false,
        allowEmail: false,
        emailEditorVisible: false,
        phoneEditorVisible: false,
        new_phone: '',
        new_phone_correct: true,
        new_email: '',
        new_email_correct: true,
        email_confirm_code: ''
    });

    const fetchInfo = async () => {
        if (props.context.user == null) return;
        props.context.setLoading(true);
        console.log('fetchUrSet', props.context.getCurrentContract());
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { accountsApi, zmfustatusSetApi, accountAddressesApi,
            contractAccountsApi,
            contractsApi,
            devicesApi,
            premisesApi,
            registersToReadApi } = erpUtilitiesUmcService();
        try {
            var account = await accountsApi
                .requestBuilder()
                .getByKey(props.context.user.Account.accountId)
                .addCustomQueryParameters(
                    {
                        ...props.context.getOdataExtraFields(),
                        "$expand":
                            ['StandardAccountAddress',
                                'ContractAccounts',
                                'StandardAccountAddress/AccountAddressDependentMobilePhones',
                                'StandardAccountAddress/AccountAddressDependentEmails',
                                'ContractAccounts/Contracts/Premise',
                                'ContractAccounts/Contracts/Devices/RegistersToRead'].join(',')
                    }
                )
                .execute(
                    { url: props.context.host }
                );
            console.log('account', account);
            console.log('account.standardAccountAddress', account.standardAccountAddress);



            const allowSms = await LoadGrantState(props.context, 'E0003'), allowEmail = await LoadGrantState(props.context, 'E0006');
            setState(s => ({
                ...s,
                account: account,
                allowSms: allowSms,
                allowEmail: allowEmail,
                new_phone: account.standardAccountAddress.accountAddressDependentMobilePhones[0]?.phoneNo,
                new_email: account.standardAccountAddress.accountAddressDependentEmails[0]?.email
            }));
        } catch (e) {
            console.error('odata err', e);
        }
        props.context.setLoading(false);
    };

    useEffect(() => {
        fetchInfo();
    }, []);


    const saveEmailVerifyFlag = async (newVerify) => {
        console.log('saveEmailVerifyFlag, newVerify = ', newVerify);
        props.context.setLoading(true);
        try {
            var accEmail = state.account.standardAccountAddress.accountAddressDependentEmails[0];
            console.log('account.standardAccountAddress.accountAddressDependentEmails[0]', accEmail);
            accEmail.verify = newVerify;
            accEmail.zkey = state.email_confirm_code;

            const { accountAddressDependentEmailsApi } = erpUtilitiesUmcService();

            await accountAddressDependentEmailsApi.requestBuilder()
                .update(accEmail)
                .replaceWholeEntityWithPut()
                .addCustomQueryParameters(props.context.getOdataExtraFields())
                .skipCsrfTokenFetching()
                .middleware([csrf({ url: `${ContractConsumptionValues._defaultBasePath}?sap-user=${encodeURIComponent(props.context.user.login)}&sap-password=${encodeURIComponent(props.context.user.password)}` })])
                .execute({ url: props.context.host });

            props.context.setLoading(false);
        } catch (e) {
            console.error('odata err', e);
        }
        await fetchInfo();
    }

    const saveEmail = async (e) => {
        e.preventDefault();
        if (!state.new_email_correct) {
            props.context.showMessage('Некорректный E-Mail', true);
            setState((s) => ({ ...s, emailEditorVisible: false }));
            return;
        }
        console.log('saveEmail', e);
        props.context.setLoading(true);
        try {
            var accEmail = state.account.standardAccountAddress.accountAddressDependentEmails[0];
            console.log('account.standardAccountAddress.accountAddressDependentEmails[0]', accEmail);
            accEmail.email = state.new_email;

            const { accountAddressDependentEmailsApi } = erpUtilitiesUmcService();

            await accountAddressDependentEmailsApi.requestBuilder()
                .update(accEmail)
                .replaceWholeEntityWithPut()
                .addCustomQueryParameters(props.context.getOdataExtraFields())
                .skipCsrfTokenFetching()
                .middleware([csrf({ url: `${ContractConsumptionValues._defaultBasePath}?sap-user=${encodeURIComponent(props.context.user.login)}&sap-password=${encodeURIComponent(props.context.user.password)}` })])
                .execute({ url: props.context.host });

            props.context.setLoading(false);
            setState((s) => ({ ...s, emailEditorVisible: false }));
        } catch (e) {
            console.error('odata err', e);
        }
        await fetchInfo();
    }

    const savePhone = async (e) => {
        e.preventDefault();
        if (!state.new_phone_correct) {
            props.context.showMessage('Некорректный номер телефона', true);
            setState((s) => ({ ...s, phoneEditorVisible: false }));
            return;
        }
        console.log('savePhone', e);
        props.context.setLoading(true);
        try {
            var newPhone = state.new_phone;
            if (newPhone.indexOf("+7") == 0) newPhone = newPhone.substring(2);
            if (newPhone.indexOf("7") == 0 || newPhone.indexOf("8") == 0) newPhone = newPhone.substring(1);
            newPhone = newPhone.replace(/\s/g, '');

            var accPhone = state.account.standardAccountAddress.accountAddressDependentMobilePhones[0];
            console.log('account.standardAccountAddress.accountAddressDependentMobilePhones[0]', accPhone);
            accPhone.phoneNo = newPhone;

            const { accountAddressDependentMobilePhonesApi } = erpUtilitiesUmcService();

            await accountAddressDependentMobilePhonesApi.requestBuilder()
                .update(accPhone)
                .replaceWholeEntityWithPut()
                .addCustomQueryParameters(props.context.getOdataExtraFields())
                .skipCsrfTokenFetching()
                .middleware([csrf({ url: `${ContractConsumptionValues._defaultBasePath}?sap-user=${encodeURIComponent(props.context.user.login)}&sap-password=${encodeURIComponent(props.context.user.password)}` })])
                .execute({ url: props.context.host });

            props.context.setLoading(false);
            setState(s => ({ ...s, phoneEditorVisible: false }));
        } catch (e) {
            console.error('odata err', e);
        }
        await fetchInfo();
    }

    if (state == null) return <></>;
    var acc = state.account ?? {} as Accounts;
    if (acc.contractAccounts == null || acc.contractAccounts.length == 0) return <></>;
    console.log('acc', acc);
    var addr = acc.standardAccountAddress.addressInfo;
    console.log('addr', addr);



    const modals = () => <>
        <Modal show={state.emailEditorVisible} onHide={() => setState(s => ({ ...s, emailEditorVisible: false }))} centered>
            <Modal.Header closeButton>
                <Modal.Title>Введите электронную почту</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={(e) => { saveEmail(e); }}>


                    <FloatingLabel label="Введите электронную почту" className="mb-3 has-validation">
                        <Form.Control type="email" placeholder="Электронная почта" required style={{ backgroundColor: "#ACACAC" }}
                            className={state.new_email_correct ? '' : 'is-invalid'}
                            value={state.new_email} onChange={(e) => {
                                setState(s => ({
                                    ...s,
                                    new_email: e.target.value.replace(/[^a-zA-Z0-9\-@\.\_]/gi, ''),
                                    new_email_correct: /\S+@\S+\.\S+/.test(state.new_email)
                                }))
                            }} />
                    </FloatingLabel>

                    <Button type="submit" variant="primary" className="mt-3 w-100">Сохранить</Button>
                </form>
            </Modal.Body>
        </Modal>

        <Modal show={state.phoneEditorVisible} onHide={() => setState(s => ({ ...s, phoneEditorVisible: false }))} centered>
            <Modal.Header closeButton>
                <Modal.Title>Введите мобильный телефон</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={(e) => { savePhone(e); }}>

                    <FloatingLabel label="Введите мобильный телефон" className="mb-3 has-validation">
                        <InputMask mask="+7 (999) 999-99-99" value={state.new_phone}
                            className={state.new_phone_correct ? '' : 'is-invalid'}
                            onChange={e => setState(s => ({
                                ...s,
                                new_phone: e.target.value,
                                new_phone_correct: e.target.value.replace('_', '').length === 18
                            }))}>
                            <Form.Control type="text" placeholder="Мобильный телефон" required style={{ backgroundColor: "#ACACAC" }} />
                        </InputMask>
                    </FloatingLabel>
                    <Button type="submit" variant="primary" className="mt-3 w-100">Сохранить</Button>
                </form>
            </Modal.Body>
        </Modal>
    </>

    if (props.context.isMobile) {
        var ld = [
            ['ФИО', [acc.lastName, acc.firstName, acc.middleName].join(' ')],
            ['Адрес счета', addr.shortForm]
        ] as ((string | JSX.Element)[])[];
        if (acc.standardAccountAddress.accountAddressDependentMobilePhones?.length > 0)
            ld.push([
                'Телефон',
                `+7${acc.standardAccountAddress.accountAddressDependentMobilePhones[0].phoneNo}`,
                <MobileEditImage onClick={() => { setState(s => ({ ...s, phoneEditorVisible: true })) }} style={{ "cursor": "pointer" }} />
            ] as (string | JSX.Element)[])

        if (acc.standardAccountAddress.accountAddressDependentEmails?.length > 0) {
            var verify = acc.standardAccountAddress.accountAddressDependentEmails[0].verify;
            var emailVerifyElements: JSX.Element | string = null;
            console.log('acc.standardAccountAddress.accountAddressDependentEmails[0].verify', verify);
            switch (verify) {
                case '00':
                    emailVerifyElements = <Button className="my-2 btn-sm w-100" onClick={async () => {
                        await saveEmailVerifyFlag('10');
                        props.context.showMessage('E-mail с кодом отправлен!', false);
                    }}>Подтвердить</Button>
                    break;
                case '01':
                    emailVerifyElements = <div className="d-flex">
                        <Form.Control type="number" placeholder="Код" required
                            value={state.email_confirm_code} onChange={(e) => {
                                setState(s => ({ ...s, email_confirm_code: e.target.value }))
                            }} className="me-2" />

                        <Button className="btn-sm d-block" onClick={async () => {
                            await saveEmailVerifyFlag('20');
                            if (verify == '01')
                                props.context.showMessage('E-mail подтвердить не удалось!', true);
                        }}>Подтвердить</Button>
                    </div>
                    break;
                case '02':
                    emailVerifyElements = <div className="very-small-gray">E-mail подтверждён</div>;
                    break;
            }
            ld.push([
                'Электронная почта',
                acc.standardAccountAddress.accountAddressDependentEmails[0].email,
                <MobileEditImage onClick={() => { setState(s => ({ ...s, emailEditorVisible: true })) }} style={{ "cursor": "pointer" }} />,
                emailVerifyElements
            ] as (string | JSX.Element)[]);
        }

        const renderLine = (line: (string | JSX.Element)[], index: number) => <>
            {index > 0 &&
                <div style={{ borderBottom: "0.5px solid #CACDD7" }}></div>}

            <div className="py-3 d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <div className="very-small-gray pb-1">{line[0]}</div>
                        <div>{line[1]}</div>
                    </div>
                    {line.length > 2 && <div>
                        {line[2]}
                    </div>}
                </div>

                {line.length > 3 && line[3] != null &&
                    <div className="mt-1">
                        {line[3]}
                    </div>}
            </div>
        </>


        return (
            <LoggedTemplate>
                <div>
                    <MobileHeaderNav context={props.context} title="Мой профиль" backUrl={"/"} ></MobileHeaderNav>
                    <div>
                        <h5 className="mt-4 mb-3">Личные данные</h5>
                        <div className="px-3 mb-4 white-card">
                            {ld.map(renderLine)}
                        </div>

                        <h5 className="mt-4 mb-3">Лицевые счета</h5>

                        <Accordion alwaysOpen className="mb-3">


                            {acc.contractAccounts.map((ca, i) => {
                                var contr = ca.contracts?.length > 0 ? ca.contracts[0] : null;
                                var dev = contr?.devices?.length > 0 ? contr.devices[0] : null;
                                var zones = dev?.registersToRead.map(r => r.zwarttxt).join('/');
                                var devAddr = contr.premise.addressInfo.shortForm.split(' - ').reverse()[0];
                                devAddr = devAddr
                                    .replaceAll(',', ', ')
                                    .replaceAll('.', '. ')
                                    .replaceAll('  ', ' ')
                                    .replaceAll(', ,', ', ');


                                console.log('ca', ca);
                                console.log('contr', contr);
                                console.log('dev', dev);
                                console.log('zones', zones);

                                return <Accordion.Item eventKey={i.toString()}>

                                    <Accordion.Header className={i > 0 ? 'mt-3' : ''}>
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <div style={{
                                                    width: "2.625rem", height: "2.625rem", backgroundColor: "#F08A13",
                                                    borderRadius: "0.3125rem", fontSize: "0.75rem", fontWeight: 600, color: "white"
                                                }}
                                                    className="d-flex align-items-center justify-content-center">
                                                    {ca.vkona.substring(ca.vkona.length - 4)}
                                                </div>
                                                <div className="ms-2">
                                                    <div style={{ fontWeight: 700 }}>Счёт {ca.vkona}</div>
                                                    {dev && <div className="very-small-gray mt-1">
                                                        Счётчик «{zones}» {dev.serialNumber}
                                                    </div>}
                                                </div>
                                            </div>

                                            <div className="pt-3">{devAddr}</div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {[
                                            ['Тип тарифа', ca.ttypbez],
                                            ['День', ca.preisbtr1],
                                            ['Ночь', ca.preisbtr2],
                                            ['Полупик', ca.preisbtr3],
                                            ['Тип отопления', ca.hottype],
                                            ['Оборудование МЖД', ca.devhtype],
                                            ['Количество этажей', ca.floorcnt],
                                            ['Оборудование квартиры', ca.equipment],
                                            ['Количество прописанных', ca.regcnt],
                                            ['Количество проживающих', ca.livecnt],
                                            ['Общая площадь помещения', ca.homes],
                                            ['Количество комнат', ca.roomcnt],
                                        ]
                                            .filter(l => l[1].toString() != '' && l[1].toString() != '0')
                                            .map((line) => {
                                                return (
                                                    <div className="py-2">
                                                        <div className="small-gray pb-1">{line[0].toString()}</div>
                                                        <div>{line[1].toString()}</div>
                                                    </div>
                                                )
                                            })}
                                    </Accordion.Body>
                                </Accordion.Item>
                            })}

                        </Accordion>

                    </div>
                    {modals()}
                </div>
            </LoggedTemplate>
        );
    }


    var ca = acc.contractAccounts[0];
    var contr = ca.contracts?.length > 0 ? ca.contracts[0] : null;

    console.log('ca', ca);
    console.log('contr', contr);
    return (
        <LoggedTemplate>
            <Container fluid>
                <Row>
                    <Col className="py-3 px-2" style={{
                        "display": "grid", "gridTemplateColumns": "repeat(2, 1fr)", "gridAutoRows": "1fr",
                        "gap": "2rem", "gridTemplateRows": "max-content"
                    }}>

                        <Card bg="Light" className="p-3" style={{ backgroundColor: "white" }}>
                            <Card.Body>
                                <Card.Title>Личные данные</Card.Title>
                                <Card.Text>
                                    {[
                                        ['Имя', acc.firstName],
                                        ['Отчество', acc.middleName],
                                        ['Фамилия', acc.lastName],
                                    ].map((line) => {
                                        return (
                                            <Row className="py-1">
                                                <Col md="6" style={{ fontWeight: "bold" }}>{line[0]}</Col>
                                                <Col md="6">{line[1]}</Col>
                                            </Row>
                                        )
                                    })}
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card bg="Light" className="p-3" style={{ backgroundColor: "white" }}>
                            <Card.Body>
                                <Card.Title>Контактная информация</Card.Title>
                                <Card.Text>
                                    {acc.standardAccountAddress.accountAddressDependentMobilePhones?.length > 0 ?
                                        <Row className="py-1">
                                            <Col md="6" style={{ fontWeight: "bold" }}>Мобильный телефон</Col>
                                            <Col md="5">+7{acc.standardAccountAddress.accountAddressDependentMobilePhones[0].phoneNo}</Col>
                                            <Col md="1"><EditImage onClick={() => { setState(s => ({ ...s, phoneEditorVisible: true })) }} style={{ "cursor": "pointer" }} /></Col>
                                        </Row> : <></>
                                    }
                                    {acc.standardAccountAddress.accountAddressDependentEmails?.length > 0 ?
                                        <Row className="py-1">
                                            <Col md="6" style={{ fontWeight: "bold" }}>Электронная почта</Col>
                                            <Col md="5">
                                                {acc.standardAccountAddress.accountAddressDependentEmails[0].email}
                                                {acc.standardAccountAddress.accountAddressDependentEmails[0].verify == '00' &&
                                                    <Button className="my-2 btn-sm d-block" onClick={async () => {
                                                        await saveEmailVerifyFlag('10');
                                                        props.context.showMessage('E-mail с кодом отправлен!', false);
                                                    }}>Подтвердить</Button>}
                                                {acc.standardAccountAddress.accountAddressDependentEmails[0].verify == '01' &&
                                                    <Row className="my-2">
                                                        <Col>
                                                            <Form.Control type="number" placeholder="Код" required style={{ backgroundColor: "#ACACAC" }}
                                                                value={state.email_confirm_code} onChange={(e) => {
                                                                    setState(s => ({ ...s, email_confirm_code: e.target.value }))
                                                                }} className="form-control-sm" />
                                                        </Col>
                                                        <Col>
                                                            <Button className="btn-sm d-block" onClick={async () => {
                                                                await saveEmailVerifyFlag('20');
                                                                if (acc.standardAccountAddress.accountAddressDependentEmails[0].verify == '01')
                                                                    props.context.showMessage('E-mail подтвердить не удалось!', true);
                                                            }}>Подтвердить</Button></Col>
                                                    </Row>}
                                                {acc.standardAccountAddress.accountAddressDependentEmails[0].verify == '02' &&
                                                    <div className="small-gray my-2">E-mail подтверждён</div>}
                                            </Col>
                                            <Col md="1"><EditImage onClick={() => { setState(s => ({ ...s, emailEditorVisible: true })) }} style={{ "cursor": "pointer" }} /></Col>
                                        </Row> : <></>
                                    }
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card bg="Light" className="p-3" style={{ backgroundColor: "white" }}>
                            <Card.Body>
                                <Card.Title>Дополнительная информация по лиц. счету</Card.Title>
                                <Card.Text>
                                    {[
                                        ['Тип тарифа', ca.ttypbez],
                                        ['День', ca.preisbtr1],
                                        ['Ночь', ca.preisbtr2],
                                        ['Полупик', ca.preisbtr3],
                                        ['Тип отопления', ca.hottype],
                                        ['Оборудование МЖД', ca.devhtype],
                                        ['Количество этажей', ca.floorcnt],
                                        ['Оборудование квартиры', ca.equipment],
                                        ['Количество прописанных', ca.regcnt],
                                        ['Количество проживающих', ca.livecnt],
                                        ['Общая площадь помещения', ca.homes],
                                        ['Количество комнат', ca.roomcnt],
                                    ]
                                        .filter(l => l[1].toString() != '')
                                        .map((line) => {
                                            return (
                                                <Row className="py-1">
                                                    <Col md="6" style={{ fontWeight: "bold" }}>{line[0].toString()}</Col>
                                                    <Col md="6">{line[1].toString()}</Col>
                                                </Row>
                                            )
                                        })}
                                </Card.Text>
                            </Card.Body>
                        </Card>


                        <Card bg="Light" className="p-3" style={{ backgroundColor: "white" }}>
                            <Card.Body>
                                <Card.Title>Дополнительная информация по лиц. счету</Card.Title>
                                <Card.Text>
                                    {[
                                        ['Номер дома/здания', addr.houseNo],
                                        ['Улица', addr.street],
                                        ['Квартира', addr.roomNo],
                                        ['Город', addr.city],
                                        ['Страна', addr.countryName],
                                        ['Город/регион', addr.regionName],
                                        ['Почтовый индекс', addr.postalCode],
                                    ]
                                        .filter(l => l[1].toString() != '')
                                        .map((line) => {
                                            return (
                                                <Row className="py-1">
                                                    <Col md="6" style={{ fontWeight: "bold" }}>{line[0].toString()}</Col>
                                                    <Col md="6">{line[1].toString()}</Col>
                                                </Row>
                                            )
                                        })}
                                </Card.Text>
                            </Card.Body>
                        </Card>


                        <Card bg="Light" className="p-3" style={{ backgroundColor: "white" }}>
                            <Card.Body>
                                <Card.Title>Соглашения</Card.Title>
                                <Card.Text>
                                    <Row className="py-1">
                                        <Col md="10" style={{ fontWeight: "bold" }}>Получать счета на электронную почту</Col>
                                        <Col md="2">
                                            <Switch
                                                checked={state.allowEmail}
                                                onChange={checked => { HandleGrantSwitchChange(props.context, 'E0006', checked, fetchInfo) }}
                                            /></Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col md="10" style={{ fontWeight: "bold" }}>Получать уведомления и информацию через SMS</Col>
                                        <Col md="2">
                                            <Switch
                                                checked={state.allowSms}
                                                onChange={checked => { HandleGrantSwitchChange(props.context, 'E0003', checked, fetchInfo) }}
                                            /></Col>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>


            {modals()}

        </LoggedTemplate >
    );
}
