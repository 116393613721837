import styles from "../components/LoginTemplate.module.css";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import { Button, FloatingLabel, Form, ListGroup, Modal, Badge } from "react-bootstrap";
import { AuthData } from "../contexts/AuthContextProvider";
import { Logo } from "./SvgImages";
import { ReactComponent as MobileBack } from './../images/mobile_back.svg';
import { ReactComponent as MobileMenu } from './../images/mobile_menu.svg';
import { useEffect, useState } from "react";
import { ReactComponent as CloseImage } from './../images/icn_close_gray.svg';
import { ReactComponent as ArrowRight } from './../images/mobile_arrow_right.svg';
import { Switch } from "@mui/material";
import { LoadGrantState, HandleGrantSwitchChange } from "../pages/Settings";
import { ContractAccounts, NotifSet } from 'erp-utilities-umc-service';
import { GetNotReadedNotifications } from "./NotificationsAlerts";

interface IMobileHeaderNavProps {
    context: AuthData;
    title: string | JSX.Element;
    backUrl: string;
}

export function MobileHeaderNav(props: IMobileHeaderNavProps) {
    const navigate = useNavigate();

    if (!props.context.isMobile) return <></>;
    return (<><div className="mobile-header pt-1" id="mobile-header-fixed" style={{
        position: "fixed",
        "width": "100%",
        zIndex: 111,
        top: 0
    }}>
        <Button variant="link" onClick={() => navigate(props.backUrl)} style={{ color: "white", fontWeight: 400, textDecoration: "none" }} className="ps-0">
            <MobileBack className="me-2 mb-1"></MobileBack> Назад</Button>
        <h1 className="pt-2 pb-3">{props.title}</h1>
    </div>
        <div style={{ height: "8rem" }}></div>
    </>)
}
interface IProps {
    context: AuthData;
}

export function MobileBottomForm({ children, context: AuthData, close, title }) {
    return (<>

        <div className="spinner-wrapper" onClick={close} style={{ zIndex: 6000, background: "rgba(0, 0, 0, 0.65)" }}></div>
        <div style={{
            "width": "100vw", height: "80vh", overflowY: "scroll", backgroundColor: "#EAEDF2", bottom: 0, left: 0, right: 0, position: "fixed", zIndex: 600001,
            borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem"
            // transition: "transform 0.3s ease-out", transform: "translate(0, -50px)"
        }} className="d-flex flex-column">
            <div className="d-flex w-100 justify-content-between px-3 py-4">
                <h5 className="mb-1" style={{ fontSize: "1.25rem", fontWeight: 700 }}>{title}</h5>
                <div style={{ fontSize: "1rem" }}><CloseImage onClick={close} /></div>
            </div>
            {children}
        </div>
    </>)
}

export function MobileHeaderLogged(props: IProps) {
    const [state, setState] = useState({
        menuOpened: false,
        allowSms: false,
        allowEmail: false,
        emailEditorVisible: false,
        phoneEditorVisible: false,
    });
    const navigate = useNavigate();

    const fetchInfo = async () => {
        const allowSms = await LoadGrantState(props.context, 'E0003'),
            allowEmail = await LoadGrantState(props.context, 'E0006');
        setState(s => ({ ...s, allowSms, allowEmail }));
    }

    useEffect(() => {
        if (state.menuOpened)
            fetchInfo();
    }, [state.menuOpened]);


    if (!props.context.isMobile) return <></>;
    let context = props.context;
    let notReaded = GetNotReadedNotifications(context).reduce((a, b) => a + b.notReaded, 0);

    return (<>
        <div className="d-flex justify-content-between align-items-center mobile-header" style={{ background: "white" }}>
            {Logo({ maxHeight: "2rem" })}
            <div className='position-relative' onClick={() => navigate('/notifications')} style={{ cursor: "pointer" }}>
                {notReaded > 0 && <Badge bg='danger' pill className='position-absolute top-0 start-100 translate-middle'>{notReaded}</Badge>}
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="black" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                </svg>
            </div>


            <Button variant="link" onClick={(e) => setState(s => ({ ...s, menuOpened: true }))}><MobileMenu /></Button>
        </div>

        {state.menuOpened &&
            <MobileBottomForm context={props.context} close={() => { setState(s => ({ ...s, menuOpened: false })) }} title="Настройки">
                <div className="d-flex flex-column h-100">
                    <div className="white-card mx-3">
                        <ListGroup>

                            <ListGroup.Item className="py-3" onClick={() => navigate("/settings")}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <div>Профиль</div>
                                        <div className="small" style={{ color: "#ACACAC" }}>Личные данные</div>
                                    </div>
                                    <ArrowRight /></div>
                            </ListGroup.Item>


                            <ListGroup.Item className="py-3" onClick={() => navigate("/changepasswd")}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <div>Изменить пароль</div>
                                        <div className="small" style={{ color: "#ACACAC" }}>Конфиденциальность</div>
                                    </div>
                                    <ArrowRight /></div>
                            </ListGroup.Item>

                            <ListGroup.Item className="py-3" onClick={() => navigate("/docs")}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <div>Договорная документация</div>
                                        <div className="small" style={{ color: "#ACACAC" }}>Договоры, акты</div>
                                    </div>
                                    <ArrowRight /></div>
                            </ListGroup.Item>

                            <ListGroup.Item className="py-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>Получать счета на электронную почту</div>
                                    <div>
                                        <Switch
                                            checked={state.allowEmail}
                                            onChange={checked => { HandleGrantSwitchChange(props.context, 'E0006', checked, fetchInfo) }}
                                        />
                                    </div>

                                </div>
                            </ListGroup.Item>


                            <ListGroup.Item className="py-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>Получать уведомления и информацию через SMS</div>
                                    <div>
                                        <Switch
                                            checked={state.allowSms}
                                            onChange={checked => { HandleGrantSwitchChange(props.context, 'E0003', checked, fetchInfo) }}
                                        />
                                    </div>

                                </div>
                            </ListGroup.Item>


                        </ListGroup>
                    </div>
                    <div className="m-2 mt-auto">
                        <Button variant="link" onClick={() => navigate('/logout')}>Выйти из профиля</Button>
                    </div>
                </div>
            </MobileBottomForm >}
    </>);
}