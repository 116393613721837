import { Accounts, ContractAccounts, erpUtilitiesUmcService, NotifSet } from "erp-utilities-umc-service";
import { createContext, useState } from "react";
import { useEffect } from "react";
import { env } from 'process';
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { isNotReadedNotif } from "../pages/Notifications";

export const AuthContext = createContext<AuthData>(null);
export const localStorageUserKey = 'fl_user4';


export interface AuthData {
  user: User;
  handleUserChange: (newUser: User) => void;
  getCurrentContract: () => ContractAccounts;
  isLoading: boolean;
  setLoading: (newState: boolean) => void;
  getOdataExtraFields: () => Record<string, string>;
  host: string;
  showMessage(text: string | JSX.Element, isError: boolean);
  isMobile: boolean;
  notifications: VkonaNotifications[];
  setNotificationReaded: (n: NotifSet) => void;
}

export interface User {
  login: string;
  password: string;
  Account: Accounts | null;
  ContractSelectedIndex: number;
}

interface VkonaNotifications {
  Vkona: string
  Notifications: NotifSet[]
}


var getInitialState = function (): User {
  const user = localStorage.getItem(localStorageUserKey);
  return user ? JSON.parse(user) : null;
}

export const IsPWA = () => window.matchMedia('(display-mode: standalone)').matches;
export const IsCordova = () => navigator.userAgent.indexOf('Samaraenergo') > -1;
export const IsIOS = () => /^(iPhone|iPad|iPod)/.test(navigator.userAgent || navigator.vendor || navigator.platform);
export const IsAndroid = () => /^(Android)/.test(navigator.userAgent || navigator.vendor || navigator.platform);

export const AuthContextProvider = ({ children }) => {

  const [user, setUser] = useState<User | null>(getInitialState);
  const handleUserChange = async (newUser: User) => {
    // загрузка инфы
    setUser(newUser);
  };
  const [notifications, setNotifications] = useState<VkonaNotifications[]>([])

  const getCurrentContract = () => {
    try { return user.Account.contractAccounts[user.ContractSelectedIndex ?? 0]; }
    catch { return user?.Account?.contractAccounts[0]; }
  }


  const getOdataExtraFields = () => {
    if (user)
      return {
        "sap-language": "RU",
        "sap-user": user.login,
        "sap-password": encodeURIComponent(user.password)
      };
    else
      return { "sap-language": "RU" };
  }
  const host = process.env.NODE_ENV === 'development' ? '/' : '/'; //https://host163.ru:12812

  // загрузить список всех уведомлений
  const reloadNotifications = () => {
    if (user)
      user.Account.contractAccounts.forEach(ca =>
        (async (ca) => {
          const { operations: { getNotif } } = erpUtilitiesUmcService();
          try {
            console.log('fetch notificationApi 4 ca', ca);

            var set = await getNotif({ contractAccountId: ca.vkona })
              .addCustomQueryParameters(getOdataExtraFields())
              .execute({ url: host });

            console.log('notificationApi set loaded', set);
            setNotifications(ns => [{ Vkona: ca.vkona, Notifications: set }, ...ns.filter(n => n.Vkona != ca.vkona)])
          } catch (e) {
            console.error('odata notificationApi err', e);
          }
        })(ca))
  }

  // прочтение уведомления
  const setNotificationReaded = async (n: NotifSet) => {
    if (!isNotReadedNotif(n)) return

    setLoading(true);
    try {
      const { operations: { setNotif } } = erpUtilitiesUmcService();
      var newSet = await setNotif({ bpcontact: n.bpcontact, contractAccountId: n.vkona })
        .addCustomQueryParameters(getOdataExtraFields())
        .execute({ url: host });
      console.log('setNotif set loaded', newSet);
      reloadNotifications()
    } catch (e) {
      console.error('odata setNotif err', e);
      showMessage(e.rootCause?.response?.data?.error?.message?.value ?? e.message, true);
    }
    finally {
      setLoading(false);
    }
  }

  // сохранение пользователя
  useEffect(() => {
    var sj = JSON.stringify(user);
    localStorage.setItem(localStorageUserKey, sj);
    if (user && IsCordova() && window.parent)
      window.parent.postMessage(JSON.stringify({
        type: 'store',
        name: localStorageUserKey,
        value: sj
      }), '*');
    // загрузка уведмлений
    reloadNotifications();
  }, [user])

  const [isLoading, setFetchStatus] = useState<boolean>(false);
  const setLoading = (newState: boolean) => {
    setFetchStatus(newState);
  }


  const [messState, setMessState] = useState({ menuClosed: true, message: '' as (string | JSX.Element), isMessageErr: false });
  const showMessage = (text: string | JSX.Element, isError: boolean) => {
    setMessState(s => ({ ...s, message: text, isMessageErr: isError }));
  }
  var isMobile = useMediaQuery(
    { maxDeviceWidth: 600 },
    //{ deviceWidth: 600 } // `device` prop
  );

  if (isMobile && process.env.NODE_ENV !== 'development') isMobile = false;
  if (IsPWA() || IsCordova()) isMobile = true;

  return (
    <AuthContext.Provider value={{
      user, handleUserChange, getCurrentContract, isLoading, setLoading,
      getOdataExtraFields, host, showMessage, isMobile, notifications, setNotificationReaded
    }}>
      {children}

      <Modal show={messState.message !== ''} onHide={() => setMessState({ ...messState, message: '' })} centered>
        <Modal.Header closeButton>
          <Modal.Title>{messState.isMessageErr ? 'Ошибка' : 'Сообщение'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{messState.message}</p>
        </Modal.Body>
      </Modal>

    </AuthContext.Provider>
  );
};
