import { Home } from "./pages/Home";
import { Bills } from "./pages/Bills";
import { Pays } from "./pages/Pays";
import { ToDo } from "./pages/ToDo";
//import "@fontsource/open-sans"; // Defaults to weight 400.
import "@fontsource/inter"; // Defaults to weight 400.
import './App.scss';


import {
  Routes,
  Route,
  Navigate,
  HashRouter
} from "react-router-dom";
import { Login } from "./pages/Login";
import { useContext } from "react";
import { AuthContext, IsCordova, IsPWA } from "./contexts/AuthContextProvider";
import { NotFound } from "./pages/404";
import Spinner from "./components/Spinner";
import { Files } from "./pages/Files";
import { RecoverPassword } from "./pages/RecoverPassword";
import { Logout } from "./pages/Logout";
import { Reg } from "./pages/Reg";
import { Settings } from "./pages/Settings";
import { ResetPassword } from "./pages/ResetPassword2";
import { Readings } from "./pages/Readings";
import { ReadingsDone } from "./pages/ReadingDone";
import { ChangePassword } from "./pages/ChangePassword";
import { YMInitializer } from "react-yandex-metrika";
import React from "react";
import { MobileReports } from "./pages/MobileReports";
import { Notifications } from "./pages/Notifications";

const ProtectedRoute = ({ children }) => {
  let context = useContext(AuthContext);
  if (context?.user?.Account == null)
    return <Navigate to="login" replace />;
  return children;
};

export default function App() {
  let context = useContext(AuthContext);
  return (
    <React.Fragment>
      <div className={context.isMobile ? "AppMobile" : "App"}>
        <Spinner />
        {process.env.NODE_ENV !== 'development' && <YMInitializer accounts={[85796689]} options={{
          params: {
            IsPWA: IsPWA(),
            IsCordova: IsCordova()
          }
        }} />}
        <HashRouter>
          <Routes>
            <Route path="ToDo" element={<ToDo />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reg" element={<Reg />} />
            <Route path="/logout" element={<Logout context={context} />} />
            <Route path="/recover" element={<RecoverPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/" element={<ProtectedRoute><Home context={context} /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute><Settings context={context} /></ProtectedRoute>} />
            <Route path="/bills" element={<ProtectedRoute><Bills context={context} /></ProtectedRoute>} />
            <Route path="/readings" element={<ProtectedRoute><Readings context={context} /></ProtectedRoute>} />
            <Route path="/changepasswd" element={<ProtectedRoute><ChangePassword context={context} /></ProtectedRoute>} />
            <Route path="/readingsdone/:vkona/:summ" element={<ProtectedRoute><ReadingsDone context={context} /></ProtectedRoute>} />
            <Route path="/pays" element={<ProtectedRoute><Pays context={context} /></ProtectedRoute>} />
            <Route path="/docs" element={<ProtectedRoute><Files context={context} /></ProtectedRoute>} />
            <Route path="/notifications/:vkona?" element={<ProtectedRoute><Notifications context={context} /></ProtectedRoute>} />
            <Route path="/mobilereports" element={<ProtectedRoute><MobileReports context={context} /></ProtectedRoute>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </HashRouter>
      </div>
    </React.Fragment>
  );
}

