import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PageTemplate from "../components/PageTemplate";

function Svg404() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="444" height="279.71831417253975" fill="none"><rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none" className="selected" />
        <defs>
            <filter id="filter0_d_1521_2576" x="0.8277320265769958" y="58.7864990234375" width="207.0449981689453" height="240.76699829101562" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="-6.68073" />
                <feGaussianBlur stdDeviation="16.7018" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 0 0.968627 0 0 0 0.5 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1521_2576" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1521_2576" result="shape" />
            </filter>
            <filter id="filter1_d_1521_2576" x="125.15599822998047" y="56.40810012817383" width="207.47000122070312" height="246.9669952392578" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="-6.68073" />
                <feGaussianBlur stdDeviation="16.7018" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 0 0.968627 0 0 0 0.5 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1521_2576" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1521_2576" result="shape" />
            </filter>
            <filter id="filter2_d_1521_2576" x="236.156005859375" y="58.7864990234375" width="207.0449981689453" height="240.76699829101562" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="-6.68073" />
                <feGaussianBlur stdDeviation="16.7018" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 0 0.968627 0 0 0 0.5 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1521_2576" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1521_2576" result="shape" />
            </filter>
        </defs>
        <g className="currentLayer"><title>Layer 1</title><g filter="url(#filter0_d_1521_2576)" id="svg_1">
            <path d="M40.9121 235.571V206.606L113.537 92.1901H138.509V132.282H123.73L77.9463 204.737V206.096H181.15V235.571H40.9121ZM124.409 266.149V226.737L125.089 213.911V92.1901H159.575V266.149H124.409Z" fill="#284695" id="svg_2" />
        </g><g filter="url(#filter1_d_1521_2576)" id="svg_3">
                <path d="M235.572 269.972C220.962 269.915 208.391 266.319 197.858 259.184C187.382 252.049 179.312 241.715 173.65 228.181C168.044 214.647 165.269 198.366 165.325 179.34C165.325 160.369 168.128 144.202 173.735 130.838C179.397 117.474 187.467 107.31 197.943 100.344C208.475 93.3227 221.018 89.8118 235.572 89.8118C250.125 89.8118 262.64 93.3227 273.116 100.344C283.648 107.366 291.746 117.559 297.409 130.923C303.071 144.231 305.875 160.369 305.818 179.34C305.818 198.423 302.987 214.732 297.324 228.266C291.718 241.8 283.677 252.134 273.201 259.269C262.724 266.404 250.182 269.972 235.572 269.972ZM235.572 239.478C245.538 239.478 253.494 234.466 259.44 224.443C265.386 214.42 268.331 199.386 268.274 179.34C268.274 166.145 266.915 155.16 264.197 146.382C261.535 137.605 257.741 131.008 252.815 126.591C247.945 122.174 242.197 119.966 235.572 119.966C225.662 119.966 217.734 124.921 211.788 134.831C205.842 144.74 202.841 159.577 202.784 179.34C202.784 192.704 204.115 203.859 206.777 212.806C209.495 221.697 213.317 228.379 218.244 232.852C223.17 237.269 228.946 239.478 235.572 239.478Z" fill="#284695" id="svg_4" />
            </g><g filter="url(#filter2_d_1521_2576)" id="svg_5">
                <path d="M276.241 235.571V206.606L348.865 92.1901H373.838V132.282H359.058L313.275 204.737V206.096H416.478V235.571H276.241ZM359.738 266.149V226.737L360.417 213.911V92.1901H394.903V266.149H359.738Z" fill="#284695" id="svg_6" />
            </g></g></svg>
}

export function NotFound() {
    let navigate = useNavigate();
    const goHome = () => {
        navigate("/");
    }
    const goBack = () => {
        navigate(-1);
    }
    return (
        <PageTemplate>

            <Container>
                <Row>
                    <Col xs="12" className="text-center">

                        {Svg404()}

                        <h4 className="my-5" style={{ color: "#111A39" }}>
                            Запрашиваемая страница не существует<br />
                            или была перемещена по другому адресу</h4>
                    </Col>
                    <Col xs="12" className="text-center">
                        <Button variant="outline-primary mx-2" onClick={goBack}>Вернуться назад</Button>
                        <Button variant="primary mx-2" onClick={goHome}>Перейти на главную</Button>
                    </Col>
                </Row>
            </Container>
        </PageTemplate>);
}
