import { HttpRequestConfig } from "@sap-cloud-sdk/http-client";

export function RemoveNullFields(options) {
    return async (requestConfig: HttpRequestConfig) => {
        console.log('[doSomethingBeforeMiddleware] requestConfig', requestConfig);
        console.log('[doSomethingBeforeMiddleware] options', options);
        // пустые поля из объекта надо убрать потому что сервер ожидает определённую структуру, отличную от стандартной одаты
        requestConfig.data = Object.fromEntries(Object.entries(requestConfig.data).filter(([_, v]) => v != null));
        // уберем пустые поля и у друзей тоже
        if (requestConfig.data.DependentMeterReadingResults)
            for (let index = 0; index < requestConfig.data.DependentMeterReadingResults.length; index++) {
                const element = requestConfig.data.DependentMeterReadingResults[index];
                requestConfig.data.DependentMeterReadingResults[index] =
                    Object.fromEntries(Object.entries(element).filter(([_, v]) => v != null));
            }
        // ReadingResult должен быть строкой для отправки
        if (requestConfig.data.ReadingResult)
            requestConfig.data.ReadingResult = requestConfig.data.ReadingResult.toString();
        if (requestConfig.data.DependentMeterReadingResults)
            for (let index = 0; index < requestConfig.data.DependentMeterReadingResults.length; index++) {
                const element = requestConfig.data.DependentMeterReadingResults[index];
                if (!element.ReadingResult) continue;
                requestConfig.data.DependentMeterReadingResults[index].ReadingResult = element.ReadingResult.toString();
            }

        return options.fn(requestConfig);
    };
}