import { Alert } from "react-bootstrap";
import { AuthData } from "../contexts/AuthContextProvider";
import { isNotReadedNotif, NotificationPopup } from "../pages/Notifications";
import { ContractAccounts } from "erp-utilities-umc-service";

export const getAddr = (ca: ContractAccounts) => {
    var shortForm = ca.contracts[0]?.premise?.addressInfo?.shortForm
    if (!shortForm) return undefined
    var title = `${shortForm}`;
    title = title
        .replaceAll(',', ', ')
        .replaceAll('.', '. ')
        .replaceAll('  ', ' ')
        .replaceAll(', ,', ', ');
    var parts = title.split(' - ', 2)
    if (parts.length == 1) return undefined
    var addr = parts[1].trim()
    if (addr.length == 0) return undefined
    return addr
}

export function GetNotReadedNotifications(context: AuthData) {

    return context.user.Account.contractAccounts.map(ca => {
        var addr = getAddr(ca)
        var notifications = context.notifications.find(n => n.Vkona == ca.vkona)?.Notifications ?? []
        var notReaded = notifications.filter(n => n.bpcontact?.length > 0 && n.zstatus != 'X').length
        return { addr, notifications, notReaded, vkona: ca.vkona }
    })
}
export function NotificationsAlerts(props: { context: AuthData }) {
    let context = props.context;
    var modalNotification = context.notifications.map(c => c.Notifications).flat().filter(n => n.activity == '0007' || n.activity == '0008' || n.activity == '0009').find(n => isNotReadedNotif(n))
    return <>

        {/* Информация о взысканиях */}
        {context.user.Account.contractAccounts.map(ca => {
            var notifications = context.notifications.find(n => n.Vkona == ca.vkona)?.Notifications ?? []
            var penalty = notifications.find(n => n.bpcontact?.length > 0 && (n.activity == '0003' || n.activity == '0004') && isNotReadedNotif(n))
            //penalty = notifications.find(n => n.bpcontact?.length) // Отладка
            if (!penalty) return <></>
            return <Alert className='mx-2' variant="danger" onClose={async () => await context.setNotificationReaded(penalty)} dismissible>
                <Alert.Heading className='fs-6'>
                    Сведения о л/с №{ca.vkona}
                </Alert.Heading>
                <div>{penalty.textLong}</div>
            </Alert>
        })}

        {/* уведомления на странице */}
        {context.user.Account.contractAccounts.map(ca => {
            var notifications = context.notifications.find(n => n.Vkona == ca.vkona)?.Notifications ?? []
            var notifsShowOnPage = notifications.filter(n => n.activity == '0001' || n.activity == '0005' || n.activity == '0006').filter(n => isNotReadedNotif(n))
            //penalty = notifications.find(n => n.bpcontact?.length) // Отладка
            return notifsShowOnPage.map(n => <Alert className='mx-2' variant="primary" onClose={async () => await context.setNotificationReaded(n)} dismissible>
                <Alert.Heading className='fs-6'>
                    Сведения о л/с №{ca.vkona}
                </Alert.Heading>
                <div>{n.textLong}</div>
            </Alert>)
        })}

        {/* уведомление модально */}
        {modalNotification && <NotificationPopup n={modalNotification} setN={function (v): void { }} context={context} />}</>
}