import { Col, Container, Nav, Row } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContextProvider';
import { LogoW } from "./SvgImages";
import styles from "./LoginTemplate.module.css";

export function LoginFooter() {
    return (<Row style={{ borderTop: "1px solid #ACACAC" }} className={`py-4 mt-auto px-1 ${styles.loginFooter} align-items-center`}>

        <div className='d-flex flex-nowrap w-100' style={{ "justifyContent": "space-around", "lineHeight": "140%", "alignContent": "center" }}>

            <div className='me-2' style={{ fontWeight: "normal", fontSize: "0.875rem" }}>
                Личный кабинет<br />
                в вашем смартфоне
            </div>

            <div className='mx-2' style={{ flexGrow: 1 }}>
                <a className="me-2" href="https://play.google.com/store/apps/details?id=ru.samaraenergo.umc.mobile&hl=ru" target='_blank'><img src={require('../images/googleplay.png')} /></a>
                <a href='https://itunes.apple.com/ru/app/лк-пао-самараэнерго/id1211198060?l=ru&ls=1&mt=8' target='_blank'><img src={require('../images/appstore.png')} /></a>
            </div>

            <a className='mx-2' href='https://www.samaraenergo.ru/' target='_blank' rel="noreferrer">samaraenergo.ru</a>
            <a className='mx-2' href={`${process.env.PUBLIC_URL}/files/FAQ.pdf`} target="_blank" rel="noreferrer">Вопрос/ответ</a>
            <a className='mx-2' href='https://www.samaraenergo.ru/files/Politika_v_otnoshenii_PDn.pdf' target="_blank" rel="noreferrer">Положение о ПД</a>
            <a className='mx-2' href='https://www.samaraenergo.ru/fiz-licam/headquarters/' target="_blank" rel="noreferrer">Форма обратной связи</a>
            <a className='ms-2' href={`mailto:lk@samaraenergo.ru?subject=Новый личный кабинет&body=${encodeURIComponent(`Суть вопроса:`)}`} target="_blank" rel="noreferrer">Отправить сообщение</a>
        </div>
    </Row>);
}

export default function LoginTemplate({ children }) {
    let context = useContext(AuthContext);
    //if (context.user?.Account != null) return <Navigate to="/" />;

    if (context.isMobile)
        return (
            <Container fluid style={{ minHeight: '100vh' }}>
                <Row style={{ minHeight: '100vh' }}>
                    <Col className='d-flex flex-column justify-content-between'>
                        {children}
                    </Col>
                </Row>
            </Container>
        );



    return (
        <Container fluid className={styles.loginForm}>
            <Row style={{ minHeight: "55vh" }}>
                <Col xs="5" className={`p-5 ${styles.leftLoginForm} d-flex flex-column justify-content-between`} style={{ alignSelf: "flex-start", minHeight: "55vh" }}>
                    <div>
                        <Link to="/">
                            {LogoW({})}
                        </Link>
                        <div className={styles.sub_header_login}>Контакт-центр 8 800 1000 763</div>
                    </div>
                    <div>
                        Личный<br />
                        кабинет для<br />
                        физических лиц
                    </div>
                </Col>
                <Col xs="7" className="d-flex flex-column justify-content-between">
                    <Nav
                        className='justify-content-end'
                        activeKey="/"
                        style={{ "borderRadius": "5px" }}
                    >
                        <Nav.Item>
                            <NavLink to="/login" className={`nav-link ${styles.navlink} ${styles.navlinkactive}`} >
                                Для физических лиц</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <a href="/sap/bc/ui5_ui5/sap/z_umc_lk_v01/"
                                className={`nav-link ${styles.navlink}`}>
                                Для юридических лиц</a>
                        </Nav.Item>
                    </Nav>

                    <div style={{ width: "370px" }} className="mx-auto">
                        {children}
                    </div>
                </Col>
            </Row>
            {/* <Row className='mt-auto'>
                <Col className='text-center fs-6'>
                    Регистрация в личном кабинете – это возможность участия
                    в <a href="https://www.samaraenergo.ru/fiz-licam/action/" target='_blank'>Акциях для клиентов</a>
                </Col>
            </Row> */}
            <LoginFooter />
        </Container>);


}