import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import LoggedTemplate from "../components/LoggedTemplate";
import { AuthData } from "../contexts/AuthContextProvider";
import styles from "../components/LoggedTemplate.module.css";
import { stat } from "fs";
import { BillsInvoicesList } from "./BillsInvoicesList";
import { BillsInvoicesPay } from "./BillsInvoicesPay";
import { ContractAccounts, Contracts, Devices, RegistersToRead, erpUtilitiesUmcService } from "erp-utilities-umc-service";
import { useEffect, useState } from "react";
import { ReadingsNew } from "./ReadingsNew";
import { ReadingsList } from "./ReadingsList";
import { ReactComponent as IconAlert } from './../images/alert_orange.svg';
import { FetchMeters, Meter, MeterList } from "../components/MeterList";
import { MobileHeaderNav } from "../components/MobileHeaders";

interface IProps {
    context: AuthData;
}

export function Readings(props: IProps) {
    const [state, setState] = useState({ selectedMeter: null as Meter });


    return (
        <LoggedTemplate>
            {props.context.isMobile &&
                <MobileHeaderNav context={props.context} title="Показания счетчика" backUrl={"/"} ></MobileHeaderNav>}

            <MeterList context={props.context} onSelect={(m) => { setState((s) => ({ ...s, selectedMeter: m })) }}></MeterList>
            <ReadingsNew context={props.context} selectedMeter={state.selectedMeter} />
        </LoggedTemplate>
    )
}

