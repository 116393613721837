import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext, AuthData, IsCordova } from "../contexts/AuthContextProvider";
import LoggedTemplate from "../components/LoggedTemplate";

interface IProps {
    context: AuthData;
}
export function Logout(props: IProps) {
    let context = useContext(AuthContext);
    const navigate = useNavigate();
    const deleteAllCookies = () => {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    useEffect(() => {
        if (props.context.user == null) return;

        (async () => {
            props.context.setLoading(true);

            if (context.user) {
                context.handleUserChange(null);
                deleteAllCookies();
                window.localStorage.clear();
                if (IsCordova() && window.parent)
                    window.parent.postMessage(JSON.stringify({
                        type: 'clearstore'
                    }), '*');
            }
            try {
                var r = await fetch('/sap/public/bc/icf/logoff');
                var t = await r.text();
                console.log('logout result', t);
            } catch (e) {
                console.warn('Ошибка при выходе', e);
            } finally {
                window.location.href = `${process.env.PUBLIC_URL}/index.html?_=${Date.now()}`;
            }
            props.context.setLoading(false);
        })();
    }, []);

    return <LoggedTemplate>Выход...</LoggedTemplate>
    //window.location.href = `/sap/public/bc/icf/logoff?redirectURL=${encodeURIComponent(process.env.PUBLIC_URL)}`;
    //return (<Navigate to={"/"} />);
}
