import styles from "../components/LoginTemplate.module.css";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import { Button, FloatingLabel, Form, Modal, Nav } from "react-bootstrap";
import { AuthData } from "../contexts/AuthContextProvider";
import { Logo } from "./SvgImages";

interface IProps {
    context: AuthData;
}

export function LoginNav(props: IProps) {
    return (<>
        {props.context.isMobile && <div className="text-center my-5">{Logo({})}</div>}

        <Nav className='flex-nowrap mb-4'>
            <Nav.Item style={{ flex: 1 }}>
                <NavLink to="/login" className={({ isActive }) => `nav-link ${styles.navlink2} ${(isActive ? styles.navlinkactive2 : '')}`} >
                    Вход</NavLink>
            </Nav.Item>
            <Nav.Item style={{ flex: 1 }}>
                <NavLink to="/reg" className={({ isActive }) => `nav-link ${styles.navlink2} ${(isActive ? styles.navlinkactive2 : '')}`} >
                    Регистрация</NavLink>
            </Nav.Item>
        </Nav>
    </>)
}