import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LoginFooter } from "./LoginTemplate";
import loginStyles from "../components/LoginTemplate.module.css";
import { Logo } from "./SvgImages";

export default function PageTemplate({ background = "#F4F5F7", children }) {
    return (
        <Container style={{ height: "100vh", background: background }} className={loginStyles.loginForm}>
            <Row className='align-items-center py-3 px-2'>
                <Col xs="3" lg="2">
                    <Link to="/">{Logo({ maxWidth: "100%" })}</Link>
                </Col>
                <Col xs="5" lg="5" style={{ "color": "#666666" }}>
                    Самарское отделение публичного акционерного общества<br />
                    энергетики и электрификации “Самараэнерго”
                </Col>
                <Col className='text-end' style={{ "color": "#666666" }}>
                    Контакт-центр
                </Col>
                <Col className='text-end col-auto' style={{ "color": "#284695", fontSize: "20px" }}>
                    8 (800) 1000 763
                </Col>
            </Row>
            {children}
            <LoginFooter />
        </Container>
    );
}