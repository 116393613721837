import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContextProvider";
import "./spinner.css";

function Spinner() {
  const { isLoading } = useContext(AuthContext);

  if (isLoading === true) {
    return (
      <div className="spinner-wrapper">
        <div className="loader"></div>
      </div>
    );
  }

  return null;
}

export default Spinner;
