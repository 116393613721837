import LoggedTemplate from "../components/LoggedTemplate";
import { AuthData } from "../contexts/AuthContextProvider";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as IconOk } from './../images/clarity_success.svg';
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { wait } from "@testing-library/user-event/dist/utils";
import { PaymentIframe } from "../components/Payment";
import { ReactComponent as IconMobileReadingsOk } from './../images/mobile_readings_ok.svg';


interface IProps {
    context: AuthData;
}

export function ReadingsDone(props: IProps) {
    let { vkona, summ } = useParams();
    const [state, setState] = useState({ toPay: Number(summ) });
    console.log('summ', summ);
    const [startPayment, setStartPayment] = useState(false);
    const navigate = useNavigate();

    if (props.context.isMobile)
        return (<LoggedTemplate>
            <div>
                <div className="mobile-header mb-4 pt-5">
                    <div className="text-center py-4">
                        <IconMobileReadingsOk />
                        <h5 className="m-0 py-3">Показания переданы!</h5>
                    </div>
                </div>

                <h5 style={{ fontSize: "1.25rem" }} className="m-0 p-0">Оплатить услуги</h5>
                <div className="py-4" style={{ fontSize: "1rem", lineHeight: "140%" }}>
                    Оплаченная сумма будет зачислена на<br /> Ваш лицевой счет и учтена при расчете
                </div>

                <div className="pb-3">
                    <FloatingLabel label="Сумма к оплате, ₽" className="has-validation">
                        <Form.Control type="number" placeholder="Сумма к оплате, ₽" name="summ" required value={state.toPay > 0 ? state.toPay : ''}
                            style={{ fontWeight: "600" }}
                            onChange={(e) => {
                                var parsed = Number(e.target.value);
                                if (!isNaN(parsed))
                                    setState((s) => ({ ...s, toPay: parsed }))
                            }} />
                    </FloatingLabel>
                </div>
                <div className="pb-0">
                    <Button variant="primary" className="w-100" disabled={!(state.toPay > 1)} onClick={() => {
                        setStartPayment((s) => {
                            wait(2000).then(() => setStartPayment(false));
                            return true;
                        });
                    }}>
                        Оплатить</Button>
                </div>
                <div className="mt-2">
                    <Button variant="link" className="w-100" disabled={!(state.toPay > 1)} onClick={() => navigate('/readings')} style={{fontWeight: 400}}>
                        Вернуться к показаниям</Button>
                </div>
                <div className="text-start mt-3" style={{ fontStyle: "italic", fontSize: "0.8125rem", lineHeight: "150%", color: "#6D6D71" }}>
                    Сумма фактического начисления за услуги ПАО «Самараэнерго», зависящая от Вашего индивидуального
                    технического подключения, может незначительно отличаться от указанной суммы (например, данный расчет не
                    учитывает СОИ).
                </div>
            </div>
            <PaymentIframe startPayment={startPayment} toPay={state.toPay} context={props.context} vkona={vkona} />
        </LoggedTemplate >);

    return (
        <LoggedTemplate>
            <div className="white-card text-center py-4" style={{ "maxWidth": "28rem", margin: "2rem auto" }}>
                <IconOk />
                <h5 className="m-0 pt-3" style={{ color: "#08B233" }}>Показания счётчика успешно переданы!</h5>
            </div>

            <div className="white-card text-center py-4" style={{ "maxWidth": "28rem", margin: "2rem auto" }}>
                <h5 className="pt-3" style={{ color: "#111A39", fontSize: "1.375rem" }}>Оплатить услуги</h5>
                <div className="mt-2 mb-4" style={{ fontSize: "1rem", lineHeight: "140%" }}>
                    Оплаченная сумма будет зачислена на<br /> Ваш лицевой счет и учтена при расчете
                </div>
                <div className="mt-5 w-75 mx-auto">
                    <FloatingLabel label="Сумма к оплате, ₽" className="has-validation">
                        <Form.Control type="number" placeholder="Сумма к оплате, ₽" name="summ" required value={state.toPay > 0 ? state.toPay : ''}
                            style={{ fontWeight: "600" }}
                            onChange={(e) => {
                                var parsed = Number(e.target.value);
                                if (!isNaN(parsed))
                                    setState((s) => ({ ...s, toPay: parsed }))
                            }} />
                    </FloatingLabel>
                </div>
                <div className="mt-2 w-75 mx-auto">
                    <Button variant="primary" className="w-100" disabled={!(state.toPay > 1)} onClick={() => {
                        setStartPayment((s) => {
                            wait(2000).then(() => setStartPayment(false));
                            return true;
                        });
                    }}>
                        Оплатить</Button>
                </div>
                <div className="mt-2 w-75 mx-auto">
                    <Button variant="link" className="w-100" disabled={!(state.toPay > 1)} onClick={() => navigate('/')}>
                        Отмена</Button>
                </div>
                <div className="text-start mt-5 mb-3" style={{ fontStyle: "italic", fontSize: "0.8125rem", lineHeight: "150%", color: "#6D6D71" }}>
                    Сумма фактического начисления за услуги ПАО «Самараэнерго», зависящая от Вашего индивидуального
                    технического подключения, может незначительно отличаться от указанной суммы (например, данный расчет не
                    учитывает СОИ).
                </div>
            </div>

            <PaymentIframe startPayment={startPayment} toPay={state.toPay} context={props.context} vkona={vkona} />
        </LoggedTemplate >
    )
}

