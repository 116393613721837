import { InvoiseUrSet, InvoiseUrrSet, erpUtilitiesUmcService, PaymentUrSet, PaymentDocuments } from "erp-utilities-umc-service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Dropdown, Button } from "react-bootstrap";
import DataTable, { TableStyles, TableColumn } from "react-data-table-component";
import LoggedTemplate from "../components/LoggedTemplate";
import { AuthData, IsCordova } from "../contexts/AuthContextProvider";
import styles from "../components/LoggedTemplate.module.css";
import { stat } from "fs";
import { Reports } from "./Reports";
import { MobileHeaderNav } from "../components/MobileHeaders";
import { ReactComponent as ReportIcon } from './../images/report.svg';
import FileSaver from 'file-saver';
import { slugify } from "transliteration";
import { formatter, tableStyles } from "../components/Table";
var mime = require('mime-to-extensions')

moment.locale('ru');

interface IProps {
    context: AuthData;
}

const periods = ['Последний месяц', 'Последние 3 месяца', 'Последние 12 месяцев', 'Все время'];

var fetchPays = async (authContext: AuthData, setNewDocs: (docs: PaymentDocuments[]) => void) => {
    if (authContext.user == null) return;
    const { paymentDocumentsApi } = erpUtilitiesUmcService();
    authContext.setLoading(true);
    try {
        console.log('fetch paymentDocumentsApi');

        var set = await paymentDocumentsApi
            .requestBuilder()
            .getAll()
            .setBasePath(`${PaymentDocuments._defaultBasePath}/Accounts('${authContext.user.Account.accountId}')`)
            .addCustomQueryParameters(authContext.getOdataExtraFields())
            .execute(
                { url: authContext.host }
            );

        console.log('paymentDocumentsApi set loaded', set);
        setNewDocs(set);
    } catch (e) {
        console.error('odata err', e);
    }
    authContext.setLoading(false);
}




const download = async (authContext: AuthData, doc: PaymentDocuments) => {
    console.log(`download check pdf`, doc);
    authContext.setLoading(true);

    const { opbelPdfsApi } = erpUtilitiesUmcService();
    try {
        console.log('fetch docPdfSetApi');
        var builder = await opbelPdfsApi.requestBuilder().getByKey('', 'D', doc.paymentDocumentId)
            .addCustomQueryParameters(authContext.getOdataExtraFields());
        //.appendPath("/InvoicePDF/$value");
        var checkUrl = await builder.url({ url: authContext.host });
        try {
            var checkFetchResult = await fetch(checkUrl);
            console.log('checkFetchResult', checkFetchResult);
            var checkFetchText = await checkFetchResult.text();
            console.log('fetching pre req ok, url ', checkUrl, 'text', checkFetchText);
            if (checkFetchResult.status == 404 && checkFetchText.indexOf('Resource not found') !== -1) {
                authContext.showMessage("Чек недоступен", true);
                return;
            }
        } catch (e) {
            console.error('fetching pre req failed, url ', checkUrl, 'err', e);
            authContext.showMessage(e.message, true);
            return;
        }

        var builder2 = builder.appendPath("/$value");

        var url = await builder2.url({ url: authContext.host });
        console.log('docPdfSetApi url generated', url);
        var res = await fetch(url);
        var ct = res.headers.get("Content-Type");
        console.log('file resp ', res.statusText, 'ct', ct);
        var array = await res.arrayBuffer()
        var ext = mime.extension(ct);
        console.log('ext', ext);
        if (IsCordova() && window.parent) {
            window.parent.postMessage(JSON.stringify({
                type: 'file',
                base64: Buffer.from(array).toString('base64'),
                mimeType: ct,
                fileName: slugify(`${doc.paymentDocumentId}.${ext}`)
            }), '*');
        } else {
            var blob = new Blob([array], { type: ct });
            FileSaver.saveAs(blob, `${doc.paymentDocumentId}.${ext}`);
        }
    } catch (e) {
        console.error('odata err', e);
        authContext.showMessage(e.rootCause?.response?.data?.error?.message?.value ?? e.message, true);
    }
    finally {
        authContext.setLoading(false);
    }
}


export function Pays(props: IProps) {
    const [state, setState] = useState({
        docs: [] as PaymentDocuments[],
        alldocs: [] as PaymentDocuments[],
        periodType: 0
    });

    useEffect(() => {
        fetchPays(props.context, (docs: PaymentDocuments[]) => setState((s) => ({ ...s, alldocs: docs })));
    }, []);

    useEffect(() => {
        var datumEnd = moment(), datumStart = moment();
        switch (state.periodType) {
            case 0: //Последний месяц
                datumStart.add(-1, 'months')
                break;
            case 1: //Последние 3 месяца
                datumStart.add(-3, 'months')
                break;
            case 2: //Последние 12 месяцев
                datumStart.add(-12, 'months')
                break;
            case 3: //Все время
                datumStart.add(-250, 'years')
                break;
            case 4: //Период
                break;
        }
        var docs = state.alldocs.filter(d => d.executionDate >= datumStart && d.executionDate <= datumEnd);
        setState((s) => ({ ...s, docs: docs }));
    }, [state.alldocs, state.periodType]); // Only re-subscribe if this changes


    if (props.context.isMobile)
        return (<LoggedTemplate>
            <div>
                <MobileHeaderNav context={props.context} title="История платежей" backUrl={"/"} ></MobileHeaderNav>
                {/* <Reports context={props.context}></Reports> */}

                <h5 className="mt-4 mb-3">Период</h5>
                <div className="mb-4">
                    <Dropdown onSelect={(y) => { setState({ ...state, periodType: parseInt(y) }); }}>
                        <Dropdown.Toggle variant="link" className={`w-100 px-3 text-start ${styles.whiteSelector}`}>
                            {periods[state.periodType]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ "maxHeight": "80vh", "overflowY": "auto", "width": "100%" }}>
                            {periods.map((title, index) => {
                                return (
                                    <Dropdown.Item as="button" eventKey={index} key={index} className={`py-2 ${styles.vkonaSelectItem}`}>
                                        <div>{title}</div>
                                    </Dropdown.Item>
                                )
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                </div>


                <div className="px-3 mb-3 white-card">

                    {state.docs.map((doc, i) =>
                        <>
                            {i > 0 && <div style={{ borderBottom: "0.5px solid #CACDD7" }}></div>}
                            <div className="d-flex py-4" style={{ justifyContent: "space-between" }}>
                                <div>
                                    <div style={{ "fontSize": "1.125rem" }}>{doc.executionDate.format("DD.MM.YYYY")}</div>
                                    <div className="small-gray pt-2">{doc.paymentMethodDescription}</div>
                                    <div className="small-gray pt-1">{doc.vkona}</div>
                                </div>
                                <div className="d-flex flex-column justify-content-between text-end">
                                    <div style={{ "fontSize": "1.125rem" }}>{formatter.format(doc.amount.toNumber())}</div>
                                    {doc.zcheck === 'X' && <div>
                                        <ReportIcon onClick={() => download(props.context, doc)} />
                                    </div>}
                                </div>
                            </div></>
                    )}
                </div>
            </div>


        </LoggedTemplate>);



    const columnsPays: TableColumn<PaymentDocuments>[] = [

        {
            name: 'Лицевой счет',
            selector: row => row.vkona,
            sortable: true,
            // width: "14rem",
        },
        {
            name: 'Дата',
            selector: row => row.executionDate.unix(),
            sortable: true,
            // width: "10rem",
            format: row => row.executionDate.format("DD.MM.YYYY"),
        },
        {
            name: 'Способы платежа',
            selector: row => row.paymentMethodDescription, sortable: true
        },
        {
            name: 'Сумма платежа',
            selector: row => row.amount.toNumber(),
            sortable: true,
            // width: "8rem",
            format: r => formatter.format(r.amount.toNumber())
        },
        {
            name: 'Счет, pdf',
            button: true,
            cell: row =>
            (row.zcheck === 'X' ?
                <Button variant="link" className="btn-sm" style={{ color: "#284695", fontWeight: "normal" }} onClick={() => download(props.context, row)}>
                    Скачать</Button> : <></>)
        }
    ];
    return (
        <LoggedTemplate>
            <Reports context={props.context}></Reports>

            <div className="my-4 white-card">
                <h5 className="pb-2 mb-3">История платежей</h5>


                <Row className="mb-2 py-2 align-items-center">
                    <Col style={{ fontSize: '16px', maxWidth: '190px', color: '#111A39', fontWeight: '600' }}>
                        Временной период:
                    </Col>
                    <Col style={{ maxWidth: '230px' }}>
                        <Dropdown onSelect={(y) => { setState({ ...state, periodType: parseInt(y) }); }}>
                            <Dropdown.Toggle variant="light" className={`w-100 px-3 text-start ${styles.graySelector}`}>
                                {periods[state.periodType]}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ "maxHeight": "80vh", "overflowY": "auto", "width": "100%" }}>
                                {periods.map((title, index) => {
                                    return (
                                        <Dropdown.Item as="button" eventKey={index} key={index} className={`py-2 ${styles.vkonaSelectItem}`}>
                                            <div>{title}</div>
                                        </Dropdown.Item>
                                    )
                                })}

                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>

                    <Col></Col>
                </Row>
                <Row className="my-2 py-2">
                    {/* https://react-data-table-component.netlify.app/?path=/docs/api-props--page */}
                    <DataTable
                        columns={columnsPays}
                        data={state.docs as PaymentDocuments[]}
                        customStyles={tableStyles}
                        noDataComponent={<div style={{ padding: '24px' }}>Нет данных для отображения</div>}
                    />
                </Row>
            </div>


        </LoggedTemplate>
    );
}
