import DataTable, { ExpanderComponentProps, TableColumn, TableStyles } from "react-data-table-component";
import FileSaver from 'file-saver';
import { DateTimePicker, TreeView } from "@mui/lab";
import { Invoices, GetPdfFoldersSet, erpUtilitiesUmcService } from "erp-utilities-umc-service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AuthData, IsCordova } from "../contexts/AuthContextProvider";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import styles from "../components/LoggedTemplate.module.css";
import { ReactComponent as ReportIcon } from './../images/report.svg';
import { slugify } from "transliteration";
import { formatter, tableStyles } from "../components/Table";

var mime = require('mime-to-extensions')

moment.locale('ru');

interface IProps {
    context: AuthData;
}
interface IState {
    year: number,
    mounth: number,
    docs: Invoices[],
    alldocs: Invoices[],
    selected: Invoices
}

var getMounthName = (num) => {
    if (num == 0) return "Все месяцы";
    var str = moment().month(num - 1).format("MMMM");
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const years = Array.from(Array(new Date().getFullYear() - 2020), (_, index) => index + 2021),
    mounth = Array.from(Array(13), (_, index) => index);

var fetchInvoices = async (authContext: AuthData, setNewDocs: (docs: Invoices[]) => void) => {
    if (authContext.user == null) return;
    const { invoicesApi } = erpUtilitiesUmcService();
    authContext.setLoading(true);
    try {
        console.log('fetchInvoices');

        var set = await invoicesApi
            .requestBuilder()
            .getAll()
            .setBasePath(`${Invoices._defaultBasePath}/Accounts('${authContext.user.Account.accountId}')`)
            .addCustomQueryParameters({
                ...authContext.getOdataExtraFields(),
                "$expand": "ContractAccount",
            }).execute(
                { url: authContext.host }
            );
        console.log('invoices set loaded', set);
        setNewDocs(set);
    } catch (e) {
        console.error('odata err', e);
    }
    authContext.setLoading(false);
}



export function BillsInvoicesList(props: IProps) {
    const [state, setState] = useState<IState>({
        year: new Date().getFullYear(),
        mounth: new Date().getMonth(),
        docs: [] as Invoices[],
        alldocs: [] as Invoices[],
        selected: null
    });

    // https://reactjs.org/docs/hooks-effect.html
    useEffect(() => {
        fetchInvoices(props.context, (docs: Invoices[]) => setState((s) => ({ ...s, alldocs: docs })));
    }, []);

    useEffect(() => {
        var startMount = state.mounth == 0 ? 1 : state.mounth;
        var datestr = `01.${startMount}.${state.year}`,
            datumA = moment(datestr, 'DD.MM.YYYY'),
            datumB = moment(datestr, 'DD.MM.YYYY');
        datumB.add(state.mounth == 0 ? 12 : 1, 'months');

        var docs = state.alldocs.filter(d => d.invoiceDate >= datumA && d.invoiceDate <= datumB);
        setState((s) => ({ ...s, docs: docs }));

    }, [state.alldocs, state.year, state.mounth]); // Only re-subscribe if this changes

    if (props.context.isMobile)
        return (<>
            <h5 className="mt-4 mb-3">Счета</h5>
            <div className="mb-2">
                <Row className="my-3 align-items-center">
                    <Col style={{ minWidth: '7rem' }}>
                        <Dropdown onSelect={(y) => { setState({ ...state, year: parseInt(y) }); }}>
                            <Dropdown.Toggle variant="light" id="dropdown-basic" className={`w-100 px-3 text-start ${styles.whiteSelector}`}>
                                {state.year}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ "maxHeight": "80vh", "overflowY": "auto", "width": "100%" }}>
                                {years.map((y, index) => {
                                    return (
                                        <Dropdown.Item as="button" eventKey={y} key={y} className={`py-2 ${styles.vkonaSelectItem}`}>
                                            <div>{y}</div>
                                            {/* <div className={`text-wrap mt-1 ${styles.vkonaSelectItemDescr}`}>{contract.ttypbez}</div> */}
                                            {/* <div className={`text-wrap mt-1 ${styles.vkonaSelectItemDescr}`}>Договор</div> */}
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col style={{ minWidth: '10rem' }}>
                        <Dropdown onSelect={(m) => { setState({ ...state, mounth: parseInt(m) }); }}>
                            <Dropdown.Toggle variant="light" id="dropdown-basic" className={`w-100 px-3 text-start ${styles.whiteSelector}`}>
                                {getMounthName(state.mounth)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ "maxHeight": "80vh", "overflowY": "auto", "width": "100%" }}>
                                {mounth.map((m, index) => {
                                    return (
                                        <Dropdown.Item as="button" eventKey={m} key={m} className={`py-2 ${styles.vkonaSelectItem}`}>
                                            <div>{getMounthName(m)}</div>
                                            {/* <div className={`text-wrap mt-1 ${styles.vkonaSelectItemDescr}`}>{contract.ttypbez}</div> */}
                                            {/* <div className={`text-wrap mt-1 ${styles.vkonaSelectItemDescr}`}>Договор</div> */}
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col></Col>
                </Row>
            </div>


            <div className="px-3 mb-3 white-card">

                {state.docs.length == 0 &&
                    <div style={{ padding: '24px' }}>Нет данных для отображения</div>}
                {state.docs.map((row, i) =>
                    <>
                        {i > 0 && <div style={{ borderBottom: "0.5px solid #CACDD7" }}></div>}
                        <div className="d-flex py-4" style={{ justifyContent: "space-between" }}>
                            <div>
                                <div style={{ "fontSize": "1.125rem" }}>{row.invoiceDate.format("DD.MM.YYYY")}</div>
                                <div className="small-gray pt-2">{row.contractAccount.description}</div>
                                <div className="small-gray pt-1">Срок: {row.dueDate.format("DD.MM.YYYY")}</div>
                            </div>
                            <div className="d-flex flex-column justify-content-between text-end">
                                <div style={{ "fontSize": "1.125rem" }}>{formatter.format(row.amountDue.toNumber())}</div>
                                <div>
                                    <ReportIcon onClick={() => download(props.context, row)} />
                                    {/* <Button variant="link" className="btn-sm p-0" style={{ fontWeight: "normal" }} onClick={() => download(props.context, row)}>
                                        Скачать
                                    </Button> */}
                                </div>
                            </div>
                        </div></>
                )}
            </div></>)


    const columnsActs: TableColumn<Invoices>[] = [
        {
            name: 'Лицевой счет',
            selector: row => row.vkona,
            sortable: true
        },
        {
            name: 'Дата счета',
            selector: row => row.invoiceDate.format("DD.MM.YYYY"),
            sortable: true, width: "10rem"
        },
        {
            name: 'Оплатить до',
            selector: row => row.dueDate.format("DD.MM.YYYY"),
            sortable: true, width: "10rem"
        },
        {
            name: 'Сумма к оплате',
            selector: row => row.amountDue.toNumber(),
            sortable: true,
            width: "8rem",
            format: r => formatter.format(r.amountDue.toNumber())
        },
        {
            name: 'Оплаченная сумма',
            selector: row => row.amountPaid.toNumber(),
            sortable: true,
            width: "8rem",
            format: r => formatter.format(r.amountPaid.toNumber())
        },
        {
            name: 'Оставшаяся сумма',
            selector: row => row.amountRemaining.toNumber(),
            sortable: true,
            width: "8rem",
            format: r => formatter.format(r.amountRemaining.toNumber())
        }, {
            name: 'Счет, pdf',
            button: true,
            cell: row => <Button variant="link" className="btn-sm" style={{ color: "#284695", fontWeight: "normal" }} onClick={() => download(props.context, row)}>Скачать</Button>
        }
    ];


    return (

        <Container className="my-2 white-card" >
            <Row className="align-items-center">
                <h5>Счета</h5>
                <div className="small-gray">В таблице ниже отражены счета, выставленные по договору (договорам) за указанный период</div>
            </Row>
            <Row className="my-3 align-items-center">
                <Col style={{ maxWidth: '180px', color: '#111A39', fontWeight: '600' }}>
                    Выберите период:
                </Col>
                <Col style={{ maxWidth: '150px' }}>
                    <Dropdown onSelect={(y) => { setState({ ...state, year: parseInt(y) }); }}>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className={`w-100 px-3 text-start ${styles.graySelector}`}>
                            {state.year}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ "maxHeight": "80vh", "overflowY": "auto", "width": "100%" }}>
                            {years.map((y, index) => {
                                return (
                                    <Dropdown.Item as="button" eventKey={y} key={y} className={`py-2 ${styles.vkonaSelectItem}`}>
                                        <div>{y}</div>
                                        {/* <div className={`text-wrap mt-1 ${styles.vkonaSelectItemDescr}`}>{contract.ttypbez}</div> */}
                                        {/* <div className={`text-wrap mt-1 ${styles.vkonaSelectItemDescr}`}>Договор</div> */}
                                    </Dropdown.Item>
                                )
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col style={{ maxWidth: '200px' }}>
                    <Dropdown onSelect={(m) => { setState({ ...state, mounth: parseInt(m) }); }}>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className={`w-100 px-3 text-start ${styles.graySelector}`}>
                            {getMounthName(state.mounth)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ "maxHeight": "80vh", "overflowY": "auto", "width": "100%" }}>
                            {mounth.map((m, index) => {
                                return (
                                    <Dropdown.Item as="button" eventKey={m} key={m} className={`py-2 ${styles.vkonaSelectItem}`}>
                                        <div>{getMounthName(m)}</div>
                                        {/* <div className={`text-wrap mt-1 ${styles.vkonaSelectItemDescr}`}>{contract.ttypbez}</div> */}
                                        {/* <div className={`text-wrap mt-1 ${styles.vkonaSelectItemDescr}`}>Договор</div> */}
                                    </Dropdown.Item>
                                )
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col></Col>
            </Row>
            <Row className="my-3">
                {/* https://react-data-table-component.netlify.app/?path=/docs/api-props--page */}
                <DataTable
                    columns={columnsActs}
                    data={state.docs as Invoices[]}
                    customStyles={tableStyles}
                    noDataComponent={<div style={{ padding: '24px' }}>Нет данных для отображения</div>}
                />
            </Row>
        </Container>


    );
}


const download = async (authContext: AuthData, invoice: Invoices) => {
    console.log(`download invoice pdf`, invoice);
    authContext.setLoading(true);

    const { invoicesApi } = erpUtilitiesUmcService();
    try {
        console.log('fetch docPdfSetApi');
        var builder = await invoicesApi.requestBuilder().getByKey(invoice.invoiceId)
            .addCustomQueryParameters(authContext.getOdataExtraFields())
            .appendPath("/InvoicePDF/$value");

        var url = await builder.url({ url: authContext.host });
        console.log('docPdfSetApi url generated', url);
        var res = await fetch(url);
        var ct = res.headers.get("Content-Type");
        console.log('file resp ', res.statusText, 'ct', ct);
        var array = await res.arrayBuffer()
        var ext = mime.extension(ct);
        console.log('ext', ext);
        if (IsCordova() && window.parent) {
            window.parent.postMessage(JSON.stringify({
                type: 'file',
                base64: Buffer.from(array).toString('base64'),
                mimeType: ct,
                fileName: slugify(`${invoice.invoiceId}.${ext}`)
            }), '*');
        } else {
            var blob = new Blob([array], { type: ct });
            FileSaver.saveAs(blob, `${invoice.invoiceId}.${ext}`);
        }
    } catch (e) {
        console.error('odata err', e);
    }
    authContext.setLoading(false);
}

// interface ExpProps extends ExpanderComponentProps<Invoices> {
//     // currently, props that extend ExpanderComponentProps must be set to optional.
//     authContext: AuthData;
// }

// const ExpandedComponent: React.FC<ExpProps> = ({ data, authContext }) => {
//     var isTotal = (data.mengeEOb.toNumber() != 0 || data.betrwEOb.toNumber() != 0);
//     if (isTotal) {
//         return <div className="my-2">
//             <Button onClick={() => download(authContext, data.opbel, data.budat, data.gsber, 'ZSAPCEIURU_BILL_INVOICE1', 'Акт ППМ и ЭЭ')} className="me-2 p-2" variant="outline-primary" style={{ "minWidth": "13rem", fontSize: "14px" }}>Акт ППМ и ЭЭ</Button>
//             <Button onClick={() => download(authContext, data.opbel, data.budat, data.gsber, 'ZSAPCEIURU_BILL_INVOICE3', 'Счёт потребителя')} className="me-2 p-2" variant="outline-primary" style={{ "minWidth": "13rem", fontSize: "14px" }}>Счёт потребителя</Button>
//             <Button onClick={() => download(authContext, data.opbel, data.budat, data.gsber, 'ZSAPCEIURU_BILL_INVOICE2', 'Счёт фактура')} className="me-2 p-2" variant="outline-primary" style={{ "minWidth": "13rem", fontSize: "14px" }}>Счёт фактура</Button>
//         </div>
//     } else {
//         return <div className="my-2">
//             <Button onClick={() => download(authContext, data.opbel, data.budat, data.gsber, 'ZIS_U_SLDOCUMENT_BILL2', 'Счёт')} className="me-2 p-2" variant="outline-primary" style={{ "minWidth": "13rem", fontSize: "14px" }}>Счёт</Button>
//             {/* <Button onClick={() => download(authContext, data.opbel, data.budat, data.gsber, 'ZIS_U_SLDOCUMENT_BILL3', 'Платёжное требование')} className="me-2 p-2" variant="outline-primary" style={{ "minWidth": "13rem", fontSize: "14px" }}>Платёжное требование</Button> */}
//         </div>
//     }
// };