import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { AuthContextProvider } from "./contexts/AuthContextProvider";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // почему componentDidMount вызывается несколько раз 
  // https://stackoverflow.com/questions/63383473/why-componentdidmount-called-two-times
  // <React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  // </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();


/* !!!!!!!!!!!!!!!!!!!!!!
Multiple componentDidMount calls may be caused by using <React.StrictMode>
 around your component. After removing it double calls are gone.

This is intended behavior to help detect unexpected side effects. You can read more about it in the docs.
 It happens only in development environment, while in production componentDidMount is called only once even with <React.StrictMode>.
*/