import { useContext, useState } from "react";
import { AuthContext, User } from "../contexts/AuthContextProvider";
import styles from "../components/LoginTemplate.module.css";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import { Button, FloatingLabel, Form, Nav } from "react-bootstrap";
import LoginTemplate from "../components/LoginTemplate";
import { erpUtilitiesUmcService } from "erp-utilities-umc-service";
import { erpUtilitiesUmcUrmService } from "erp-utilities-umc-urm-service";
import { ReactComponent as IconAlert } from './../images/alert_orange.svg';
import { ReactComponent as IconArrow } from './../images/arrow_left.svg';
import { MobileHeaderNav } from "../components/MobileHeaders";
import InputMask from "react-input-mask";



export function RecoverPassword() {
  const [state, setState] = useState({ invalid: false, btnDisabled: false, done: false, login: '' });
  let context = useContext(AuthContext);
  let navigate = useNavigate();
  console.log('RecoverPassword page load, user =', context.user);
  if (context.user?.Account) return <Navigate to="/"></Navigate>;

  const formSubmit = async (e) => {
    e.preventDefault();
    console.log('RecoverPassword submit');
    context.setLoading(true);
    setState({ ...state, invalid: false, btnDisabled: true, done: false });

    const { operations: { resetUserCredential } } = erpUtilitiesUmcUrmService();
    try {

      var r = await resetUserCredential({ userName: state.login }).execute({
        url: context.host,
        headers: { "X-REQUESTED-WITH": "XMLHttpRequest" }
      });
      console.log('resetUserCredential result', r);
      if (r.resetStatus == true)
        setState({ ...state, invalid: false, btnDisabled: false, done: true });
    } catch (err) {
      console.error('odata err', err);
      //alert('Регистрация не удалась, проверьте логин и пароль.');
      context.showMessage(err.rootCause?.request?.status == 401 ? 'Восстановление не удалось.' :
        (err.rootCause?.response?.data?.error?.message?.value ?? err.message), true);
      setState({
        ...state,
        invalid: true,
        btnDisabled: false,
        done: false
      });
      context.setLoading(false);
      return;
    }

    context.setLoading(false);

    // navigate("/", { replace: true });
  };

  const inputs = () =>
    <FloatingLabel label="Номер лицевого счета" className="mb-3 has-validation">
      <InputMask mask="999999999999" value={state.login} onChange={e => setState(s => ({ ...s, login: e.target.value, invalid: false }))}>
        <Form.Control type="text" placeholder="Номер лицевого счета" required className={state.invalid ? 'is-invalid' : ''} />
      </InputMask>
    </FloatingLabel>
    ;

  if (context.isMobile)
    return (
      <LoginTemplate>
 
        {state.done && <>
          <div className="mobile-header text-center py-4">
            <h1 className="py-4">Пароль сброшен!</h1>
            <p>
              Вам отправлено SMS с паролем на телефон, указанный при регистрации.</p>
            <p>
              В случае возникновения проблем при регистрации свяжитесь с нами по телефону 8-800-1000-763.
            </p>
            <p>Теперь можете войти в свой личный кабинет.</p>
          </div>

          <Button type="submit" variant="primary" className="mb-3 w-100" onClick={() => navigate('/login')}>Войти в личный кабинет</Button>
        </>}

        {!state.done && <>
          <MobileHeaderNav context={context} title="Сброс пароля" backUrl={"/login"} ></MobileHeaderNav>
          <form onSubmit={formSubmit} className="d-flex flex-column justify-content-between" style={{ flexGrow: "1" }}>

            <div style={{ fontSize: "1rem", lineHeight: "140%" }} className="mb-3 pb-2">Введите номер лицевого счета,<br />чтобы сбросить пароль</div>

            <div className="small-gray mb-4" style={{ fontStyle: "italic", lineHeight: "140%" }}>
              <div className="d-flex" style={{ alignItems: "center" }}>
                <div className="me-2"><IconAlert /></div>
                <div> На телефон указанный при регистрации придет новый временный пароль</div>
              </div>
            </div>

            {inputs()}

            <div className="small-gray mb-4" style={{ lineHeight: "140%" }}>
              Если ваш номер телефона изменился, обратитесь в контакт-центр 8 (800) 1000 763
            </div>

            <div className="mt-auto mb-3">
              <Button type="submit" variant="primary" className="w-100" disabled={state.btnDisabled || state.login == ''}>Сбросить пароль</Button></div>
          </form>
        </>}
      </LoginTemplate>
    );

  return (
    <LoginTemplate>

      {state.done && <>
        <h4 className={`mb-3 ${styles.loginHeader}`}>Пароль сброшен!</h4>
        <p>
          Вам отправлено SMS с паролем на телефон, указанный при регистрации.</p>
        <p>
          В случае возникновения проблем при регистрации свяжитесь с нами по телефону 8-800-1000-763.
        </p>
        <p>Теперь можете войти в свой личный кабинет.</p>

        <Button type="submit" variant="primary" className="mt-3 w-100" onClick={() => navigate('/login')}>Войти в личный кабинет</Button>
      </>}

      {!state.done && <>
        <h4 className={`mb-3 ${styles.loginHeader}`}>Сброс пароля</h4>
        <div style={{ fontSize: "1rem", lineHeight: "140%" }} className="text-center mb-4 pb-2">Введите номер лицевого счета,<br />чтобы сбросить пароль</div>

        <form onSubmit={formSubmit}>

          {inputs()}

          <div className="small-gray" style={{ fontStyle: "italic", lineHeight: "140%" }}>
            <div className="d-flex" style={{ alignItems: "center" }}>
              <div className="me-2"><IconAlert /></div>
              <div> На телефон указанный при регистрации придет новый временный пароль</div>
            </div>
          </div>

          <div className="mt-4 pt-2">
            <Button type="submit" variant="primary" className="w-100" disabled={state.btnDisabled}>Сбросить пароль</Button></div>
          <div className="text-center mt-2"><Button onClick={() => navigate('/')} variant="link" className="pe-4">
            <IconArrow className="me-2" />
            Вернуться назад</Button></div>
        </form>
      </>}
    </LoginTemplate>
  );

}