import { useState, useEffect } from "react";
import { Container, Row, Col, Dropdown, Badge } from "react-bootstrap";
import { AuthData } from "../contexts/AuthContextProvider";
import { ContractAccounts, Contracts, Devices, RegistersToRead, erpUtilitiesUmcService } from "erp-utilities-umc-service";
import { ReactComponent as IconAlert } from './../images/alert_orange.svg';
import styles from "../components/LoggedTemplate.module.css";
import { join } from "path";
import { useNavigate } from "react-router-dom";

export interface Meter {
    regs: RegistersToRead[];
    contract: Contracts;
    dev: Devices;
    ca: ContractAccounts;
    title: string;
    addr: string;
    zones: string;
}

export async function FetchMeters(auth: AuthData, onerr: (e: any) => void): Promise<Meter[]> {
    try {
        console.log('[FetchMeters]');
        const { contractAccountsApi, contractsApi, devicesApi, registersToReadApi } = erpUtilitiesUmcService();
        var set = await contractAccountsApi
            .requestBuilder()
            .getAll()
            .setBasePath(`${ContractAccounts._defaultBasePath}/Accounts('${auth.user.Account.accountId}')`)
            .addCustomQueryParameters({
                ...auth.getOdataExtraFields(),
                "$expand":
                    ['Contracts/Premise',
                        'Contracts/Division',
                        'Contracts/Devices',
                        'Contracts/Devices/RegistersToRead',
                        'Contracts/Devices/RegistersToRead/RegisterType',
                        'Contracts/Devices/RegistersToRead/MeterReadingReason',
                        'Contracts/Devices/RegistersToRead/MeterReadingCategory'
                    ].join(',')
            })
            .execute(
                { url: auth.host }
            );
        console.log('ContractAccounts set loaded', set);

        var meters: Meter[] = [];
        set.forEach(ca => {
            ca.contracts.forEach(c => {
                c.devices.forEach(dev => {
                    // var title = `${dev.sernr} - ЛС: ${c.premise.addressInfo.shortForm}`;
                    var title = `${c.premise.addressInfo.shortForm}`;
                    title = title
                        .replaceAll(',', ', ')
                        .replaceAll('.', '. ')
                        .replaceAll('  ', ' ')
                        .replaceAll(', ,', ', ');
                    var parts = title.split(' - ', 2)
                    var zones = dev.registersToRead.map(r => r.zwarttxt).join('/');
                    meters.push({ ca: ca, contract: c, dev: dev, regs: dev.registersToRead, title: parts[0], addr: parts[1], zones: zones });
                });
            });
        });
        console.log('meters set loaded', meters);
        return meters;
    } catch (e) {
        console.error('odata err', e);
        onerr(e);
    }
}


export type SelectMeterCallback = (meter: Meter) => void;

interface IProps {
    context: AuthData;
    onSelect?: SelectMeterCallback;
    defaultSelectedVkona?: string | undefined
}

export function MeterList(props: IProps) {
    const navigate = useNavigate();
    const [state, setState] = useState({ meters: [] as Meter[], selectedMeter: null as Meter });

    useEffect(() => {
        props.context.setLoading(true);
        (async () => {
            const meters = await FetchMeters(props.context, (e) => {
                if (e.rootCause?.request?.status == 401)
                    navigate("/logout");
            });

            if (meters?.length > 0)
                setState((s) => ({ ...s, meters: meters, selectedMeter: meters.find(m => m.ca.vkona == props.defaultSelectedVkona) || meters[0] }));
            props.context.setLoading(false);
        })();
    }, []); // Only re-subscribe if this changes

    useEffect(() => {
        props.onSelect(state.selectedMeter);
    }, [state.selectedMeter]); // Only re-subscribe if this changes

    if (!state.selectedMeter) return <></>;
    if (state.meters.length <= 1) return <></>;


    if (props.context.isMobile)
        return (
            <div>
                <h5 className="mt-4 mb-3">Выберите счёт</h5>
                <div className="">
                    <Dropdown onSelect={index => { setState((s) => ({ ...s, selectedMeter: state.meters[parseInt(index)] })); }}>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className={`w-100 ps-3 text-start ${styles.whiteSelector}`}>
                            Л/С {state.selectedMeter.title} - {state.selectedMeter.addr}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ "maxHeight": "80vh", "overflowY": "auto", "width": "100%" }}>
                            {state.meters.map((m, i) => {
                                return (
                                    <Dropdown.Item as="button" eventKey={i} key={i} className={`${styles.vkonaSelectItem}`}>
                                        {i > 0 && <div style={{ borderBottom: "0.5px solid #CACDD7" }}></div>}
                                        <div className="py-3">
                                            <div style={{ fontWeight: 600 }}>Л/С {m.title}</div>
                                            <div>{m.addr}</div>
                                            <div className="meter-badges mt-2">
                                                <Badge className="ms-1" bg="" style={{ background: "#EAEDF2" }} text="dark">{m.dev.serialNumber}</Badge>
                                                <Badge className="ms-1" bg="" style={{ background: "#EAEDF2" }} text="dark">{m.zones}</Badge>
                                            </div>
                                        </div>
                                    </Dropdown.Item>
                                )
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        );

    return (<Container>
        <Row>
            <Col className="mx-2 my-4 white-card" >

                <h5 className="mb-4">Выберите лицевой счёт</h5>

                <Row className="mb-2 align-items-center">
                    <Col style={{ maxWidth: '70px', color: '#111A39', fontWeight: '600' }}>
                        Счёт:
                    </Col>
                    <Col>
                        <Dropdown onSelect={index => { setState((s) => ({ ...s, selectedMeter: state.meters[parseInt(index)] })); }}>
                            <Dropdown.Toggle variant="light" id="dropdown-basic" className={`w-100 ps-3 text-start ${styles.graySelector}`}>
                                Л/С {state.selectedMeter.title} - {state.selectedMeter.addr}
                                <span className="meter-badges">
                                    <Badge className="ms-1" bg="" style={{ background: "#F9F9FC" }} text="dark">{state.selectedMeter.dev.serialNumber}</Badge>
                                    <Badge className="ms-1" bg="" style={{ background: "#F9F9FC" }} text="dark">{state.selectedMeter.zones}</Badge>
                                </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ "maxHeight": "80vh", "overflowY": "auto", "width": "100%" }}>
                                {state.meters.map((m, index) => {
                                    return (
                                        <Dropdown.Item as="button" eventKey={index} key={index} className={`py-2 ${styles.vkonaSelectItem}`}>
                                            <div>
                                                Л/с {m.title} - {m.addr}
                                                <span className="meter-badges">
                                                    <Badge className="ms-1" bg="" style={{ background: "#EAEDF2" }} text="dark">{m.dev.serialNumber}</Badge>
                                                    <Badge className="ms-1" bg="" style={{ background: "#EAEDF2" }} text="dark">{m.zones}</Badge>
                                                </span>
                                            </div>
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>

                    <Col style={{ maxWidth: '240px', fontStyle: "italic" }}>
                        <div className="d-flex" style={{ alignItems: "center" }}>
                            <div className="me-2"><IconAlert /></div>
                            <div>Передавайте показания ежемесячно с 20 по 25 число</div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>);

}