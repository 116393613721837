import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import LoggedTemplate from "../components/LoggedTemplate";
import { AuthData } from "../contexts/AuthContextProvider";
import styles from "../components/LoggedTemplate.module.css";
import { stat } from "fs";
import { BillsInvoicesList } from "./BillsInvoicesList";
import { BillsInvoicesPay } from "./BillsInvoicesPay";

interface IProps {
    context: AuthData;
}

export function Bills(props: IProps) {


    return (
        <LoggedTemplate>
            <div>
                <BillsInvoicesPay context={props.context} />
                <BillsInvoicesList context={props.context} />
            </div>
        </LoggedTemplate>
    )
}

