import DataTable, { TableColumn, TableStyles } from "react-data-table-component";
import { erpUtilitiesUmcService, ContractAccounts } from "erp-utilities-umc-service";
import { useEffect, useState } from "react";
import { AuthData } from "../contexts/AuthContextProvider";
import { Button, Col, FloatingLabel, Form, ListGroup, Row } from "react-bootstrap";
import styles from "../components/LoggedTemplate.module.css";
import { PaymentIframe } from "../components/Payment";
import { wait } from "@testing-library/user-event/dist/utils";
import { MobileHeaderNav } from "../components/MobileHeaders";
import { formatter, tableStyles } from "../components/Table";

interface IProps {
    context: AuthData;
}

interface ToPayRow {
    title: string,
    addr: string,
    sum: number,
    vkona: string,
    ca: ContractAccounts
}


export function BillsInvoicesPay(props: IProps) {
    const [state, setState] = useState({
        docs: [] as ToPayRow[],
        selected: null as ToPayRow,
        btnDisabled: true,
        error: '',
        invalid: false,
        toPay: 0
    });

    // https://reactjs.org/docs/hooks-effect.html
    useEffect(() => {
        if (props.context.user == null) return;

        (async () => {
            const { contractAccountsApi, contractsApi, premisesApi } = erpUtilitiesUmcService();
            props.context.setLoading(true);
            try {
                console.log('fetchContractAccounts');
                var set = await contractAccountsApi
                    .requestBuilder()
                    .getAll()
                    .setBasePath(`${ContractAccounts._defaultBasePath}/Accounts('${props.context.user.Account.accountId}')`)
                    .addCustomQueryParameters({
                        ...props.context.getOdataExtraFields(),
                        "$expand": "ContractAccountBalance,Contracts/Premise"
                    })
                    .execute(
                        { url: props.context.host }
                    );
                console.log('ContractAccounts set loaded', set);
                var toPay: ToPayRow[] = [];
                set.forEach(row => {
                    var adr = row.contracts[0]?.premise?.addressInfo.shortForm;
                    if (adr == null || adr.trim() == '') 
                        adr = `${row.vkona} - ${row.description}`;
                    var title = `Л/с №${adr}`;
                    title = title
                        .replaceAll(',', ', ')
                        .replaceAll('.', '. ')
                        .replaceAll('  ', ' ')
                        .replaceAll(', ,', ', ')
                        .trim();

                    var parts = title.split(' - ', 2)
                    console.log(parts);
                    toPay.push({
                        title: parts[0],
                        addr: parts[1],
                        sum: row.contractAccountBalance.currentBalance.toNumber(),
                        vkona: row.vkona,
                        ca: row
                    });
                });
                if (toPay.length > 0) {
                    var more0 = toPay.filter(t => t.sum > 0);
                    var selected = more0.length > 0 ? more0[0] : toPay[0];
                    setState((s) => ({ ...s, docs: toPay, selected, toPay: selected.sum, btnDisabled: !(selected.sum > 0) }))
                }
            } catch (e) {
                console.error('odata err', e);
            }
            props.context.setLoading(false);
        })();
    }, []);


    const columnsActs: TableColumn<ToPayRow>[] = [
        {
            name: 'Договор',
            selector: row => row.title,
            sortable: true,
            format: r => `${r.title} - ${r.addr}`
        },
        {
            name: 'Сумма счёта, ₽',
            selector: row => row.sum,
            sortable: true,
            width: "8rem",
            format: r => formatter.format(r.sum)
        }
    ];
    const [startPayment, setStartPayment] = useState(false);

    var formSubmit = async (e) => {
        e.preventDefault();
        console.log('BillsInvoicesPay form submit');
        setStartPayment((s) => {
            wait(2000).then(() => setStartPayment(false));
            return true;
        });
    };

    var toPay = state.selected?.sum ?? 0;
    const defaultSelectedRows = (row: ToPayRow) => state.selected?.vkona == row.vkona;

    if (props.context.isMobile)
        return (
            <>
                <MobileHeaderNav context={props.context} title="Счета" backUrl={"/"} ></MobileHeaderNav>

                {state.docs.length > 1 &&
                    <h5 className="mt-4 mb-3">Выберите лицевой счёт</h5>
                }

                <div className="mb-4 white-card">
                    <ListGroup defaultActiveKey={`#${state.selected?.vkona}`}>
                        {state.docs.map((row, i) =>
                            <ListGroup.Item className={`py-3 ${row.sum <= 0 ? 'green' : 'red'} ${row.vkona == state.selected.vkona ? 'active' : ''}`} href={`#${row.vkona}`} onClick={(e) => {
                                setState({ ...state, selected: row, toPay: row.sum, btnDisabled: row.sum <= 0 })
                            }} key={row.vkona}
                            >
                                <h5 className="mb-3 mt-2">
                                    {row.sum == 0 ?
                                        <span style={{ fontSize: "1.125rem" }}>Нет задолженности</span>
                                        :
                                        <span style={{ fontSize: "1.525rem" }}>{formatter.format(row.sum * -1)}</span>}
                                </h5>


                                <div className="my-1" style={{ fontSize: "1rem" }}>{row.title}</div>
                                <small className="mb-3">{row.addr}</small>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </div>

                <h5 className="mt-4 mb-3">Оплата счета</h5>
                <form onSubmit={formSubmit} className="mb-4">
                    <FloatingLabel label="Сумма платежа, ₽" className="mb-1 has-validation">
                        <Form.Control type="number" placeholder="Сумма" name="summ" required className={state.invalid ? 'is-invalid' : ''} value={state.toPay > 0 ? state.toPay : ''}
                            onChange={(e) => {
                                var parsed = Number(e.target.value);
                                if (isNaN(parsed)) parsed = 0;
                                setState((s) => ({ ...s, btnDisabled: !(parsed > 0), toPay: parsed }))
                            }} />
                        <div className={`invalid-feedback loginForm_invalid_feedback`}>
                            {state.error}
                        </div>
                    </FloatingLabel>
                    <Button type="submit" variant="primary" className="w-100 mt-2" disabled={state.btnDisabled}>Оплатить</Button>
                </form>

                {
                    state.selected &&
                    <>
                        <h5 className="mt-4 mb-3">Подробно</h5>
                        <div className="px-3 py-4 mb-3 white-card">
                            <Row className="mb-3">
                                <Col className="xs-6">Абонент:</Col>
                                <Col className="xs-6" style={{ fontWeight: 600 }}>{state.selected.ca.description}</Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="xs-6">Номер счета:</Col>
                                <Col className="xs-6" style={{ fontWeight: 600 }}>{state.selected.vkona}</Col>
                            </Row>
                            <Row className="mb-0">
                                <Col className="xs-6">Оставш. сумма:</Col>
                                <Col className="xs-6" style={{ fontWeight: 600 }}>{formatter.format(toPay)}
                                    {toPay < 0 && <>(переплата)</>}</Col>
                            </Row>
                        </div>
                    </>
                }

                {state.selected && <PaymentIframe startPayment={startPayment} toPay={state.toPay} context={props.context} vkona={state.selected.vkona} />}

            </>
        );

    return (
        <>
            <Row className="mb-4" >
                <Col lg="8">
                    <div className="white-card me-2" style={{ height: "100%" }}>
                        <h5>Счёт для оплаты</h5>
                        <div className="small-gray mb-4">Если необходимо, введите свое значение справа в поле Сумма</div>
                        {/* https://react-data-table-component.netlify.app/?path=/docs/api-props--page */}
                        <DataTable
                            columns={columnsActs}
                            data={state.docs as ToPayRow[]}
                            customStyles={tableStyles}
                            onSelectedRowsChange={st => {
                                if (st.selectedRows.length == 0) {
                                    if (state.docs.length > 0) setState({ ...state });
                                    return;
                                }
                                var toPay = st.selectedRows.map(s => s.sum).reduce((a, b) => a + b, 0);
                                if (st.selectedRows[0].vkona !== state.selected?.vkona) {
                                    console.log('new selected', st.selectedRows[0].vkona, ' old selected', state.selected)
                                    setState({ ...state, selected: st.selectedRows[0], toPay: toPay, btnDisabled: toPay <= 0 });
                                }
                            }
                            }
                            selectableRowSelected={defaultSelectedRows}
                            selectableRows
                            selectableRowsSingle
                            noDataComponent={<div style={{ padding: '24px' }}>Нет данных для отображения</div>}
                        />
                    </div>
                </Col>
                <Col lg="4">
                    <div className="white-card" style={{ height: "100%" }}>
                        <h5>К оплате</h5>
                        <Row className="mb-3">
                            <Col>Задолженность</Col>
                            <Col className="col-auto">{formatter.format(toPay)}</Col>
                        </Row>

                        <form onSubmit={formSubmit}>
                            <FloatingLabel label="Сумма" className="mb-1 has-validation">
                                <Form.Control type="number" placeholder="Сумма" name="summ" required className={state.invalid ? 'is-invalid' : ''} value={state.toPay > 0 ? state.toPay : ''}
                                    onChange={(e) => {
                                        var parsed = Number(e.target.value);
                                        if (isNaN(parsed)) parsed = 0;
                                        setState((s) => ({ ...s, btnDisabled: !(parsed > 0), toPay: parsed }))
                                    }} />
                                <div className={`invalid-feedback loginForm_invalid_feedback`}>
                                    {state.error}
                                </div>
                            </FloatingLabel>
                            <Button type="submit" variant="primary" className="w-100 my-2" disabled={state.btnDisabled}>Оплатить</Button>
                            <div className="text-center">Через службу онлайн-оплаты Альфабанк</div>
                        </form>
                    </div>
                </Col>
            </Row>

            {state.selected && <PaymentIframe startPayment={startPayment} toPay={state.toPay} context={props.context} vkona={state.selected.vkona} />}

        </>
    );
}

