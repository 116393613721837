import DataTable, { ExpanderComponentProps, TableColumn, TableStyles } from "react-data-table-component";
import FileSaver from 'file-saver';
import { DateTimePicker, TreeView } from "@mui/lab";
import { erpUtilitiesUmcService, ContractAccounts, ContractAccountBalances } from "erp-utilities-umc-service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AuthData } from "../contexts/AuthContextProvider";
import { Button, Card, Col, Container, Dropdown, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import styles from "../components/LoggedTemplate.module.css";
import { number } from "echarts";
import { zbankdataresponseSrv01 } from "zbankdataresponse_srv_01";
import homeStyles from "./Home.module.css";
import { PaymentIframe } from "../components/Payment";
import { Meter } from "../components/MeterList";
import { wait } from "@testing-library/user-event/dist/utils/misc/wait";
import { formatter } from "../components/Table";


const formatDate = (dt: moment.Moment) => {
    if (dt?.year() == 9999) return "-";
    return dt?.locale('ru').format('DD.MM.yyyy') ?? '-';
}


interface IProps {
    context: AuthData;
    billAmount: number,
    billDueDate: moment.Moment,
    selectedMeter: Meter;
}

export function HomePay(props: IProps) {
    const [state, setState] = useState({
        error: '',
        invalid: false,
        toPay: props.billAmount,
    });
    const [startPayment, setStartPayment] = useState(false);


    // https://reactjs.org/docs/hooks-effect.html
    useEffect(() => {
        setState(((s) => ({
            ...s,
            toPay: props.billAmount,
        })));
    }, [props.billAmount]);

    var formSubmit = async (e) => {
        e.preventDefault();
        console.log('BillsInvoicesPay form submit');
        setStartPayment((s) => {
            wait(2000).then(() => setStartPayment(false));
            return true;
        });
    };

    return (
        <>
            <Card bg="Light" className="p-3" style={{ backgroundColor: "white" }}>
                <Card.Body className={homeStyles.home_plitka_body}>
                    <Card.Title>Оплата электроэнергии</Card.Title>
                    <Card.Text className={homeStyles.home_plitka_body_text}>
                        <div className="big-lgbt" style={{color: props.billAmount > 0 ? '' : '#08b233'}}>
                            {formatter.format(props.billAmount)}
                        </div>
                        {props.billAmount > 0 &&
                            <div className="small-gray py-2 my-1">
                                Задолженность оплатить до {formatDate(props.billDueDate)}
                            </div>}
                        <form onSubmit={formSubmit}>
                            <Row className="mt-3">
                                <Col>
                                    <FloatingLabel label="Сумма, ₽" className="has-validation">
                                        <Form.Control type="number" placeholder="Сумма к оплате, ₽" name="summ" required className={state.invalid ? 'is-invalid' : ''} value={state.toPay > 0 ? state.toPay : ''}
                                            onChange={(e) => {
                                                var parsed = Number(e.target.value);
                                                if (!isNaN(parsed))
                                                    setState((s) => ({ ...s, toPay: parsed }))
                                            }} />
                                        <div className={`invalid-feedback loginForm_invalid_feedback`}>
                                            {state.error}
                                        </div>
                                    </FloatingLabel></Col>
                                <Col>
                                    <Button type="submit" variant="primary" className={`${homeStyles.home_plitka_control} w-100`} disabled={!(state.toPay > 1)}>
                                        Оплатить</Button>
                                </Col>
                            </Row>
                        </form>
                    </Card.Text>
                </Card.Body>
            </Card>


            <PaymentIframe startPayment={startPayment} toPay={state.toPay} context={props.context} vkona={props.selectedMeter?.ca.vkona} />
        </>
    );
}

