import DataTable, { TableColumn, TableStyles } from "react-data-table-component";
import { Invoices, erpUtilitiesUmcService, MeterReadingResults } from "erp-utilities-umc-service";
import moment from "moment";
import { useEffect, useState } from "react";
import { AuthData } from "../contexts/AuthContextProvider";
import { Badge, Table } from "react-bootstrap";
import { Meter } from "../components/MeterList";
import { tableStyles } from "../components/Table";

var mime = require('mime-to-extensions')

moment.locale('ru');

const formatterKvt = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


interface IProps {
    context: AuthData;
    selectedMeter: Meter;
    refreshConsList: boolean;
}


export function ReadingsList(props: IProps) {
    const [state, setState] = useState({
        docs: [] as MeterReadingResults[]
    });

    const fetchData = (async () => {
        const { meterReadingResultsApi } = erpUtilitiesUmcService();
        props.context.setLoading(true);
        try {
            console.log('meterReadingResultsApi');

            var docs = await meterReadingResultsApi
                .requestBuilder()
                .getAll()
                .setBasePath(`${Invoices._defaultBasePath}/Devices('${props.selectedMeter.dev.deviceId}')`)
                .addCustomQueryParameters(props.context.getOdataExtraFields())
                .execute(
                    { url: props.context.host }
                );
            console.log('meterReadingResultsApi set loaded', docs);
            setState((s) => ({ ...s, docs: docs }));
        } catch (e) {
            console.error('odata err', e);
        }
        props.context.setLoading(false);
    });
    useEffect(() => { fetchData(); }, [props.selectedMeter]);
    useEffect(() => { if (props.refreshConsList) fetchData(); }, [props.refreshConsList]);




    if (props.selectedMeter?.regs == null || props.selectedMeter.regs.length === 0) return <></>;

    if (props.context.isMobile) {
        const columnsActs: TableColumn<MeterReadingResults>[] = [
            {
                name: 'Дата',
                selector: row => row.readingDateTime.unix(),
                format: row => row.readingDateTime.format("DD.MM.YYYY"),
                sortable: true,
                width: "8rem"
            },
            {
                name: 'Зона',
                selector: row => row.zwarttxt,
                sortable: true,
                width: "9rem"
            },
            {
                name: 'Источник',
                selector: row => row.text40,
                sortable: true,
                width: "10rem"
            },
            {
                name: 'Показания, кВт.ч.',
                selector: row => row.readingResult.toNumber(),
                sortable: true,
                width: "10rem",
                format: r => `${formatterKvt.format(r.readingResult.toNumber())} кВт.ч.`
            },
            {
                name: 'Примечание',
                selector: row => row.prkrasch,
                sortable: true
            }
        ];
        return (<>
            <DataTable
                columns={columnsActs}
                data={state.docs as MeterReadingResults[]}
                customStyles={tableStyles}
                noDataComponent={<div style={{ padding: '24px' }}>Нет данных для отображения</div>}
                defaultSortFieldId={1}
                defaultSortAsc={false}
                pagination
                style={{"fontSize": "0.75rem"}}
            />
        </>);
    }
    const columnsActs: TableColumn<MeterReadingResults>[] = [
        {
            name: 'Дата',
            selector: row => row.readingDateTime.unix(),
            format: row => row.readingDateTime.format("DD.MM.YYYY"),
            sortable: true,
            width: "10rem"
        },
        {
            name: 'Зона',
            selector: row => row.zwarttxt,
            sortable: true
        },
        {
            name: 'Источник показаний',
            selector: row => row.text40,
            sortable: true
        },
        {
            name: 'Показания счетчика, кВт.ч.',
            selector: row => row.readingResult.toNumber(),
            sortable: true,
            width: "8rem",
            format: r => `${formatterKvt.format(r.readingResult.toNumber())} кВт.ч.`
        },
        {
            name: 'Примечание',
            selector: row => row.prkrasch,
            sortable: true
        }
    ];

    return (
        <>
            <div className="white-card mx-2 mb-4">
                <h5 className="pb-3">История показаний
                    <Badge className="ms-4" bg="" style={{ background: "#F9F9FC" }} text="dark">{props.selectedMeter.regs[0].serialNumber}</Badge>
                    <Badge className="ms-1" bg="" style={{ background: "#F9F9FC" }} text="dark">{props.selectedMeter.zones}</Badge>
                </h5>

                {/* https://react-data-table-component.netlify.app/?path=/docs/api-props--page */}
                <DataTable
                    columns={columnsActs}
                    data={state.docs as MeterReadingResults[]}
                    customStyles={tableStyles}
                    noDataComponent={<div style={{ padding: '24px' }}>Нет данных для отображения</div>}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    pagination
                />
            </div>

            <div className="white-card mx-2 mb-4">

                <h5 className="pb-3">Расширенная информация по прибору
                    <Badge className="ms-4" bg="" style={{ background: "#F9F9FC" }} text="dark">{props.selectedMeter.regs[0].serialNumber}</Badge>
                    <Badge className="ms-1" bg="" style={{ background: "#F9F9FC" }} text="dark">{props.selectedMeter.zones}</Badge>
                </h5>

                <Table style={{ lineHeight: "180%" }}>
                    <tr><th>Серийный номер:</th><td>{props.selectedMeter.dev.serialNumber}</td></tr>
                    <tr><th>Тип прибора учета:</th><td>{props.selectedMeter.dev.bauform}</td></tr>
                    {props.selectedMeter.dev.pliers != '' && <tr><th>Характеристики пломбы:</th><td>{props.selectedMeter.dev.pliers}</td></tr>}
                    {props.selectedMeter.dev.trMs1 != '' && <tr><th>Трансформаторы тока: тип и номер 1:</th><td>{props.selectedMeter.dev.trMs1}</td></tr>}
                    {props.selectedMeter.dev.trMs2 != '' && <tr><th>Трансформаторы тока: тип и номер 2:</th><td>{props.selectedMeter.dev.trMs2}</td></tr>}
                    {props.selectedMeter.dev.trMs3 != '' && <tr><th>Трансформаторы тока: тип и номер 3:</th><td>{props.selectedMeter.dev.trMs3}</td></tr>}
                    <tr><th>Место установки:</th><td>{props.selectedMeter.dev.lineAdr}</td></tr>
                    <tr><th>Тип дома:</th><td>{props.selectedMeter.dev.vbsarttext}</td></tr>
                    <tr><th>Наименование сетевой организации:</th><td>{props.selectedMeter.dev.gridName}</td></tr>
                    <tr><th>Межповерочный интервал:</th><td>{props.selectedMeter.dev.vlzeitt}</td></tr>
                    <tr><th>Год предыдущей поверки:</th><td>{props.selectedMeter.dev.bgljahr}</td></tr>
                    <tr><th>Год очередной поверки:</th><td>{props.selectedMeter.dev.lvProv}</td></tr>
                    <tr><th>Знаки до запятой:</th><td>{props.selectedMeter.dev.stanzvor}</td></tr>
                    <tr><th>Знаки после запятой:</th><td>{props.selectedMeter.dev.stanznac}</td></tr>
                </Table>

            </div>
        </>
    );
}
