import { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext, User } from "../contexts/AuthContextProvider";
import LoginTemplate from "../components/LoginTemplate";
import styles from "../components/LoginTemplate.module.css";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { erpUtilitiesUmcService } from "erp-utilities-umc-service";
import { LoginProcess } from "./Login";

export function ResetPassword() {
    const [state, setState] = useState({ btnDisabled: false, cerr: '', nerr: '', serr: '' });
    let navigate = useNavigate();
    let context = useContext(AuthContext);
    if (!context.user)
        return <Navigate to={"/"} />;

    const formSubmit = async (e) => {
        e.preventDefault();
        console.log('Reset submit');
        setState({ btnDisabled: true, cerr: '', nerr: '', serr: '' });

        let data = new FormData(e.target);
        var cpass = data.get("cpass").toString();
        var pass1 = data.get("pass1").toString();
        var pass2 = data.get("pass2").toString();
        if (cpass === '') {
            setState({ btnDisabled: false, cerr: 'Укажите временный пароль', nerr: '', serr: '' });
            return;
        }
        if (pass1 === '') {
            setState({ btnDisabled: false, cerr: '', nerr: 'Укажите новый пароль', serr: '' });
            return;
        }
        if (pass1 !== pass2) {
            setState({ btnDisabled: false, cerr: '', nerr: 'Новый пароль и подтверждение не совпадают', serr: '' });
            return;
        }

        context.setLoading(true);
        const { credentialApi } = erpUtilitiesUmcService();

        try {
            var credential = await credentialApi.requestBuilder().getByKey(context.user.login, pass1, cpass)
                .addCustomQueryParameters(
                    {
                        "sap-language": "RU",
                        "sap-user": context.user.login,
                        "sap-password": context.user.password
                    }
                )
                .execute({ url: context.host });


            context.setLoading(false);
            if (credential != null) {
                console.log('credential', credential);
                //navigate("/logout");
                await LoginProcess(context.user.login, pass1, context);
                navigate("/");
            }
        } catch (err) {
            context.setLoading(false);
            console.error('odata err', e);
            setState({
                btnDisabled: false, cerr: '', nerr: '', serr:
                    err.rootCause?.response?.data?.error?.message?.value ?? err.message
            });
            return;
        }
    };

    return (<LoginTemplate>
        <h4 className={`my-4 ${styles.loginHeader}`}>Восстановление пароля</h4>
        <form onSubmit={formSubmit}>
            <FloatingLabel label="Временный пароль" className="mb-3">
                <Form.Control type="password" placeholder="Временный пароль" name="cpass" required className={state.cerr !== '' ? 'is-invalid' : ''} />
                <div className={`invalid-feedback loginForm_invalid_feedback`}>{state.cerr}</div>
            </FloatingLabel>
            <FloatingLabel label="Придумайте новый пароль" className="mb-3">
                <Form.Control type="password" placeholder="Придумайте новый пароль" name="pass1" required className={state.nerr !== '' ? 'is-invalid' : ''} />
                <div className={`invalid-feedback loginForm_invalid_feedback`}>{state.nerr}</div>
            </FloatingLabel>
            <FloatingLabel label="Подтвердите пароль" className="mb-3">
                <Form.Control type="password" placeholder="Подтвердите пароль" name="pass2" required />
            </FloatingLabel>
            {state.serr !== '' &&
                <div className={`${styles.loginSubheader} mb-2`} style={{ color: "red" }}>{state.serr}</div>
            }
            <Button type="submit" variant="primary" className="mt-3 w-100" disabled={state.btnDisabled}>Сохранить</Button>
        </form>
    </LoginTemplate>);

}
