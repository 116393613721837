import { BillingPeriod, ContractAccounts, ContractConsumptionValues, Contracts, Devices, Invoices, MeterReadingResults, PaymentDocuments, erpUtilitiesUmcService } from "erp-utilities-umc-service";
import React, { CSSProperties, useEffect, useState } from "react";
import { Button, Card, Col, Container, Dropdown, FloatingLabel, Row } from "react-bootstrap";
import LoggedTemplate from "../components/LoggedTemplate";
import { AuthData } from "../contexts/AuthContextProvider";
import 'moment/locale/ru'
import {
    and, or
} from '@sap-cloud-sdk/odata-v2';
import * as echarts from 'echarts';
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { HomePay } from "./HomePay";
import homeStyles from "./Home.module.css";
import { useNavigate } from "react-router-dom";
import { Meter, MeterList } from "../components/MeterList";
import context from "react-bootstrap/esm/AccordionContext";
import { MobileHeaderLogged } from "../components/MobileHeaders";
import { ReactComponent as IconLink } from './../images/mobile_link_arrow.svg';
import { ReactComponent as IconLink2 } from './../images/mobile_link_arrow2.svg';
import { formatter } from "../components/Table";
import { NotificationsAlerts } from "../components/NotificationsAlerts";

moment.locale('ru');

const formatDate = (dt: moment.Moment) => {
    if (dt?.year() == 9999) return "-";
    return dt?.locale('ru').format('DD.MM.yyyy') ?? '-';
}


const formatterKvt = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

interface IProps {
    context: AuthData;
}

export function Home(props: IProps) {

    const [state, setState] = useState({
        billAmount: 0,
        billDueDate: null as moment.Moment,
        lastPaymentAmount: '',
        lastPaymentDate: null as moment.Moment,
        lastPaymentAmountNumber: 0,
        lastReading: '',
        lastReadingDate: null as moment.Moment,
        lastReadingNumber: 0,
        meterReadingResults: [] as MeterReadingResults[],
        AVERAGE_MONTHLY_COST: '',
        AVERAGE_MONTHLY_CONS: 0,
        cons: [] as ContractConsumptionValues[],
        consStartDate: null as moment.Moment,
        chartOpts: {} as echarts.EChartsOption,
        selectedMeter: null as Meter
    });


    var navigate = useNavigate();

    const CalcGrafData = (cons1: ContractConsumptionValues[], consStartDate: moment.Moment) => {
        var cons = cons1.map(c => {
            return {
                v: c.consumptionValue.toNumber(),
                dt: c.startDate,
                et: c.endDate
            }
        });

        var ds1 = [], ds2 = [], labels = [], periods = [];
        var startDt = consStartDate.clone();
        for (let index = 0; index < 24; index++) {
            var candidates = cons.filter(c => c.dt.year() == startDt.year() && c.dt.month() == startDt.month());
            console.log(`[CalcGrafData] ${startDt.format('MMMM YYYY')}`, candidates);

            if (index < 12) {
                ds1.push(candidates.length > 0 ? candidates[0].v : 0);
                labels.push(startDt.format("MMMM"));
            }
            else
                ds2.push(candidates.length > 0 ? candidates[0].v : 0);
            startDt.add(1, "month");

            if (index == 0) periods.push(startDt.clone());
            if (index == 11) periods.push(startDt.clone().add(-1, 'day'));
            if (index == 12) periods.push(startDt.clone());
            if (index == 23) periods.push(startDt.clone().add(-1, 'day'));
        }

        labels = labels.map(word => word[0].toUpperCase() + word.substr(1).toLowerCase());

        console.log('[CalcGrafData] cons', cons);
        console.log('[CalcGrafData] consStartDate', consStartDate)
        console.log('[CalcGrafData] ds1', ds1);
        console.log('[CalcGrafData] ds2', ds2);
        console.log('[CalcGrafData] lables', labels);

        const option: echarts.EChartsOption = {
            xAxis: {
                type: 'category',
                data: labels
            },
            yAxis: {
                type: 'value',
                name: 'Потребление, кВт.ч.',
                nameRotate: 90,
                nameLocation: "middle",
                nameGap: 50,
                nameTextStyle: {
                    fontWeight: "bold",
                    color: "black"
                },
                axisLabel: {
                    formatter: (v) => formatterKvt.format(v)
                },
                axisPointer: {
                    label: {
                        formatter: function (a) { return 'a.value.toFixed(2)' }
                    }
                }

            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                valueFormatter: value => formatterKvt.format(value.valueOf() as number) + ' кВт.ч.',
            },
            series: [
                {
                    name: `Прошлый год`, // : ${formatDate(periods[0])} - ${formatDate(periods[1])}
                    data: ds1,
                    type: 'bar',
                    color: '#FC8800',
                    label: {
                        show: true,
                        position: 'inside',
                        fontSize: "0.5rem"
                    },
                }, {
                    name: `Текущий год`, // : ${formatDate(periods[2])} - ${formatDate(periods[3])}
                    data: ds2,
                    type: 'bar',
                    color: '#3652A3',
                    label: {
                        show: true,
                        position: 'inside',
                        fontSize: "0.5rem"
                    },
                },
            ],
            legend: {
                bottom: 0,
            }
        };
        setState((s => ({ ...s, chartOpts: option })));
    }

    useEffect(() => {
        if (props.context.user == null) return;
        if (!state.selectedMeter) return;
        var ca = state.selectedMeter.ca;
        var contract = state.selectedMeter.contract;



        (async () => {
            props.context.setLoading(true);
            const { contractAccountsApi, devicesApi, contractsApi } = erpUtilitiesUmcService();
            try {


                var dev: Devices = await devicesApi
                    .requestBuilder()
                    .getByKey(contract.devices[0].deviceId)
                    .addCustomQueryParameters(
                        {
                            ...props.context.getOdataExtraFields(),
                            "$expand": "MeterReadingResults"
                        }
                    )
                    .execute(
                        { url: props.context.host }
                    );
                console.log('dev', dev);
                var lastReading = "нет";
                var lastReadingDate: moment.Moment = null;
                var lastReadingNumber = 0;
                var oLastReading = dev.meterReadingResults[dev.meterReadingResults.length - 1];
                if (oLastReading.readingUnit && oLastReading.readingDateTime) {
                    lastReadingNumber = oLastReading.readingResult.toNumber();
                    lastReading = `${formatterKvt.format(lastReadingNumber)} кВт.ч.`; //  ${oLastReading.readingUnit}
                    lastReadingDate = oLastReading.readingDateTime;
                    console.log('lastReading', lastReading);
                    console.log('lastReadingDate', lastReadingDate);
                }
                setState(((s) => ({ ...s, lastReadingNumber, lastReading, lastReadingDate, meterReadingResults: dev.meterReadingResults })));


                // история потребления
                const { operations: { getCurrentBillingPeriod }, contractConsumptionValuesApi } = erpUtilitiesUmcService();
                var billPer = await getCurrentBillingPeriod({ contractId: contract.contractId }).addCustomQueryParameters(props.context.getOdataExtraFields())
                    .execute(
                        { url: props.context.host },
                        data => { console.log('ret func data', data); return data.d.GetCurrentBillingPeriod }
                    );

                console.log('getCurrentBillingPeriod', billPer);


                var consStartDate = billPer.startDate.clone();
                consStartDate.add(-24, "month");
                console.log('cons ge', consStartDate.toISOString())

                var cons = await contractConsumptionValuesApi.requestBuilder()
                    .getAll()
                    .setBasePath(`${ContractConsumptionValues._defaultBasePath}/Contracts('${contract.contractId}')`)
                    .filter(
                        and(contractConsumptionValuesApi.schema.CONSUMPTION_PERIOD_TYPE_ID.equals('BC'),
                            contractConsumptionValuesApi.schema.START_DATE.greaterOrEqual(consStartDate),
                            //contractConsumptionValuesApi.schema.START_DATE.lessOrEqual(billPer.startDate),
                        )
                    )
                    .addCustomQueryParameters(
                        {
                            ...props.context.getOdataExtraFields(),
                            "$expand": "MeterReadingCategory"
                        }
                    )
                    .execute(
                        { url: props.context.host }
                    );
                console.log('cons', cons);
                cons = cons.sort((a, b) => a.startDate.unix() - b.startDate.unix());
                setState(((s) => ({
                    ...s,
                    cons: cons,
                    consStartDate: consStartDate
                })));



                // Среднемесячное потребление 
                // Среднемесячные затраты
                if (cons.length > 1) {
                    var consSumm = cons.map(c => c.consumptionValue.toNumber()).reduce((a, b) => a + b, 0);
                    var AVERAGE_MONTHLY_CONS = Math.round(consSumm / cons.length) || 0;
                    console.log(`AVERAGE_MONTHLY_CONS for dev ${dev.deviceId}`, AVERAGE_MONTHLY_CONS);
                    setState(((s) => ({ ...s, AVERAGE_MONTHLY_CONS: AVERAGE_MONTHLY_CONS })));

                    var paySum = cons.map(c => c.billedAmount.toNumber()).reduce((a, b) => a + b, 0);
                    var AVERAGE_MONTHLY_COST = Math.round(paySum / cons.length) || 0;
                    console.log(`AVERAGE_MONTHLY_COST for dev ${dev.deviceId}`, AVERAGE_MONTHLY_COST);
                    setState(((s) => ({ ...s, AVERAGE_MONTHLY_COST: formatterKvt.format(AVERAGE_MONTHLY_COST) + ' ₽' })));
                }


                // для графика расчет 
                CalcGrafData(cons, consStartDate);

            } catch (e) {
                console.error('odata err', e);
                props.context.showMessage(e.rootCause?.response?.data?.error?.message?.value ?? e.message, true);
            }
            props.context.setLoading(false);
        })();

        // invoices
        (async () => {

            // inv
            const { invoicesApi } = erpUtilitiesUmcService();
            const invoices = await invoicesApi
                .requestBuilder()
                .getAll()
                .setBasePath(`${Invoices._defaultBasePath}/ContractAccounts('${ca.contractAccountId}')`)
                .addCustomQueryParameters({
                    ...props.context.getOdataExtraFields()
                })
                .execute(
                    { url: props.context.host }
                );

            /*var nopayed = invoices.filter(i => i.amountRemaining.toNumber() > 0);

            if (nopayed.length > 0) {
                console.log('nopayed bills', nopayed);
                
                const sum = nopayed.map(r=>r.amountRemaining.toNumber()).reduce((a, b) => a + b, 0);
                setState(((s) => ({
                    ...s,
                    billAmount: Math.round(sum),
                    billDueDate: nopayed[0].dueDate,
                })));
            }*/

            var nopayed = invoices.filter(i => i.invoiceStatusId !== '9');

            if (nopayed.length > 0) {
                console.log('nopayed bills', nopayed);

                setState(((s) => ({
                    ...s,
                    billAmount: Math.round(nopayed.map(n => n.amountRemaining.toNumber()).reduce((a, b) => a + b, 0) * 100) / 100,
                    billDueDate: nopayed[0].dueDate,
                })));
            }

        })();


        // pds

        (async () => {

            const { paymentDocumentsApi } = erpUtilitiesUmcService();
            // lastPayments
            var pds = await paymentDocumentsApi
                .requestBuilder()
                .getAll()
                .addCustomQueryParameters(
                    {
                        ...props.context.getOdataExtraFields(),
                        //"$expand": "PaymentDocumentItems"
                    }
                )
                .execute(
                    { url: props.context.host }
                );
            console.log('loaded pds', pds);

            var lastPayment: PaymentDocuments = null;
            for (var i = 0; i < pds.length; i++) {
                if (pds[i].paymentStatusId === "9") {
                    lastPayment = pds[i];
                    break;
                }
            }
            console.log('lastPayment', lastPayment);
            setState(((s) => ({
                ...s,
                lastPaymentAmountNumber: lastPayment?.amount.toNumber(),
                lastPaymentAmount: lastPayment?.amount.toNumber() > 0 ? formatter.format(lastPayment.amount.toNumber()) : 'нет', // ${lastPayment.currency}
                lastPaymentDate: lastPayment?.executionDate,
            })));


        })();
    }, [state.selectedMeter]);


    if (state == null) return <></>;

    if (props.context.isMobile) {
        const iconLink2 = { position: "absolute", right: "1rem", top: "1.25rem" } as CSSProperties;
        return (
            <LoggedTemplate>
                <MobileHeaderLogged context={props.context} />
                <h5 className="mt-3 mb-1">
                    {props.context.user.Account.firstName} {props.context.user.Account.middleName}
                </h5>
                {state.selectedMeter &&
                    <div style={{ fontSize: "0.95rem" }}>{state.selectedMeter.addr}</div>
                }

                <div className="mt-3" style={{ margin: '0 -0.5rem' }}>
                    <NotificationsAlerts context={props.context} />
                </div>

                <MeterList context={props.context} onSelect={(m) => { setState((s) => ({ ...s, selectedMeter: m })) }}></MeterList>
                <Card bg={state.billAmount > 0 ? 'danger' : 'dark'} className="pb-2 pt-3 my-3" style={{ "height": "45vw", maxHeight: "10rem" }} onClick={() => navigate('/bills')}>
                    <Card.Body className='home_plitka_body_mobile'>
                        <Card.Title><IconLink style={iconLink2} />Счета и оплата</Card.Title>
                        <Card.Text className="home_plitka_body_text_mobile">
                            <div>
                                <div className="big-lgbt" style={{ color: 'white', fontSize: '2rem' }}>
                                    {state.billAmount != 0 ? formatter.format(state.billAmount * -1) : formatter.format(0)}
                                </div>

                                {state.billAmount > 0 &&
                                    <div className="small-gray mt-2" style={{ color: 'white' }}>
                                        Задолженность оплатить до {formatDate(state.billDueDate)}
                                    </div>}
                                {state.billAmount <= 0 &&
                                    <div className="small-gray mt-2" style={{ color: 'white' }}>Задолженность отсутствует</div>}
                            </div>
                        </Card.Text>
                    </Card.Body>
                </Card>

                <div className="mb-3" style={{
                    "display": "grid", "gridTemplateColumns": "repeat(2, 1fr)", "gridAutoRows": "1fr",
                    "gap": "1rem", "gridTemplateRows": "max-content"
                }}>
                    <Card bg="Light" className="pb-2 pt-3 px-1" style={{ "height": "45vw", maxHeight: "10rem" }}>
                        <Card.Body className='home_plitka_body_mobile' onClick={() => navigate('/pays')}>
                            <Card.Title><IconLink2 style={iconLink2} />Платежи</Card.Title>
                            <Card.Text className="home_plitka_body_text_mobile">
                                <div>
                                    <div className="big-lgbt">{formatterKvt.format(state.lastPaymentAmountNumber)}
                                        <span style={{ "fontSize": "1.125rem" }}> ₽</span></div>
                                    <div className="small-gray mt-2">
                                        от {formatDate(state.lastPaymentDate)}</div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>


                    <Card bg="light" className="pb-2 pt-3 px-1" style={{ "height": "45vw", maxHeight: "10rem" }}>
                        <Card.Body className='home_plitka_body_mobile' onClick={() => navigate('/readings')}>
                            <Card.Title><IconLink2 style={iconLink2} />Показания</Card.Title>
                            <Card.Text className="home_plitka_body_text_mobile">
                                <div>
                                    <div className="big-lgbt">
                                        {formatterKvt.format(state.lastReadingNumber)}
                                        <span style={{ "fontSize": "1.125rem" }}> кВт.ч.</span>
                                    </div>
                                    <div className="small-gray mt-2">
                                        от {formatDate(state.lastReadingDate)}
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <div className="pb-2 pt-3 px-3 mb-3 white-card" >
                    <div>

                        <div className="mt-2" style={{
                            "display": "grid", "gridTemplateColumns": "repeat(2, 1fr)", "gridAutoRows": "1fr",
                            "gap": "1rem", "gridTemplateRows": "max-content"
                        }}>
                            <h5 className="pb-2  ms-1">Потребление</h5>
                            <div>
                                <div className="big-lgbt">
                                    {formatterKvt.format(state.AVERAGE_MONTHLY_CONS)}
                                    <span style={{ "fontSize": "1.125rem" }}> кВт.ч.</span>
                                </div>
                                <div className="small-gray mt-2">Ср. месячное потребление</div>
                            </div>
                        </div>

                        {state.chartOpts && <ReactEcharts option={{ ...state.chartOpts, tooltip: null }} style={{ height: "25rem" }} />}

                    </div>
                </div>

                <Card bg='Light' className="pb-3 pt-3" style={{}} onClick={() => navigate('/mobilereports')}>
                    <Card.Body className='home_plitka_body_mobile'>
                        <Card.Title className="p-0 m-0" style={{ lineHeight: "140%" }}><IconLink2 style={iconLink2} />Отчёты и договорная<br />документация</Card.Title>

                    </Card.Body>
                </Card>
            </LoggedTemplate>
        );
    }



    return (
        <LoggedTemplate>
            <MeterList context={props.context} onSelect={(m) => { setState((s) => ({ ...s, selectedMeter: m })) }}></MeterList>

            <Container>
                <Row>
                    <Col className="pb-3 px-2" style={{
                        "display": "grid", "gridTemplateColumns": "repeat(3, 1fr)", "gridAutoRows": "1fr",
                        "gap": "1.5rem", "gridTemplateRows": "max-content"
                    }}>
                        <HomePay context={props.context} billAmount={state.billAmount} billDueDate={state.billDueDate} selectedMeter={state.selectedMeter} />

                        <Card bg="Light" className="p-3" style={{ backgroundColor: "white" }}>
                            <Card.Body className={homeStyles.home_plitka_body}>
                                <Card.Title>Показания счетчика</Card.Title>
                                <Card.Text className={homeStyles.home_plitka_body_text}>
                                    <div className="big-lgbt" style={{ color: "#FC8800" }}>{state.lastReading}</div>
                                    <div className="small-gray py-2 my-1">
                                        Последние показания от {formatDate(state.lastReadingDate)}
                                    </div>
                                    <Row className="mt-3">
                                        <Col>
                                            <Button variant="outline-lite" className={`${homeStyles.home_plitka_control} w-100`} onClick={() => navigate('/readings')}>
                                                Передача показаний</Button>
                                        </Col>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card bg="Light" className="p-3" style={{ backgroundColor: "white" }}>
                            <Card.Body className={homeStyles.home_plitka_body}>
                                <Card.Title>История платежей</Card.Title>
                                <Card.Text className={homeStyles.home_plitka_body_text}>
                                    <div className="big-lgbt" style={{ color: "#3652A3" }}>{state.lastPaymentAmount}</div>
                                    <div className="small-gray py-2 my-1">
                                        Последний платеж от {formatDate(state.lastPaymentDate)}</div>
                                    <Row className="mt-3">
                                        <Col>
                                            <Button type="submit" variant="outline-lite" className={`${homeStyles.home_plitka_control} w-100`}
                                                onClick={() => navigate('/pays')}
                                            >
                                                Все платежи</Button>
                                        </Col>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Container className="my-2 white-card" >
                    <div>
                        <h5 className="pb-2 mb-3">История потребления</h5>

                        <div className="d-flex">
                            <div>
                                <div className="small-gray mb-1 pb-2">Среднемесячное потребление</div>
                                <div className={homeStyles.home_graf_label}>{formatterKvt.format(state.AVERAGE_MONTHLY_CONS)} кВт.ч.</div>
                            </div>
                            <div className="ms-4">
                                <div className="small-gray mb-1 pb-2">Среднемесячные затраты</div>
                                <div className={homeStyles.home_graf_label}>{state.AVERAGE_MONTHLY_COST}</div>
                            </div>
                        </div>
                    </div>


                    <Row className="mb-4 py-1">
                        {state.chartOpts && <ReactEcharts option={state.chartOpts} style={{ height: "25rem" }} />}
                    </Row>
                </Container>
            </Container>
        </LoggedTemplate>
    );
}