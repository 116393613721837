import { useContext, useEffect, useState } from "react";
import { AuthContext, AuthData, IsCordova, User, localStorageUserKey } from "../contexts/AuthContextProvider";
import styles from "../components/LoginTemplate.module.css";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import { Button, FloatingLabel, Form, Nav } from "react-bootstrap";
import LoginTemplate from "../components/LoginTemplate";
import { PasswordStatSet, erpUtilitiesUmcService } from "erp-utilities-umc-service";
import { Axios } from "axios"
import { LoginNav } from "../components/LoginNav";
import InputMask from "react-input-mask";
import { Logo, LogoW } from "../components/SvgImages";
import React from "react";

export async function LoginProcess(login: string, password: string, context: AuthData): Promise<User> {
  var user = { login: login, password: password } as User;

  const { accountsApi, 
    accountAddressesApi,
    contractAccountsApi,
    contractsApi,
    devicesApi,
    premisesApi,
  } = erpUtilitiesUmcService();
  try {
    if (process.env.NODE_ENV !== 'development') {
      // проверка авторизации серверной частью сапа
      // если это не сделать то при неверном пароле вылезет бесячее окошко
      var preReq = await fetch(`${process.env.PUBLIC_URL}?sap-language=RU&sap-user=${encodeURIComponent(user.login)}&sap-password=${encodeURIComponent(user.password)}`, { redirect: "manual" });
      console.log('pre req', preReq);
      var preText = await preReq.text();
      console.log('pre req text', preText);
      if (preText != '' && preText.indexOf('Пароль начальный') === -1) throw new Error('Авторизация не удалась, проверьте логин и пароль');
    }
    var accounts = await accountsApi.requestBuilder().getAll()
      .addCustomQueryParameters(
        {
          "sap-language": "RU",
          "sap-user": user.login,
          "sap-password": encodeURIComponent(user.password),
          "$expand": "ContractAccounts,ContractAccounts/Contracts/Premise",
        }
      )
      .execute(
        { url: context.host }
      );
    user.Account = accounts[0];
    user.ContractSelectedIndex = 0;
    return user;
  } catch (err) {
    console.error('odata err', err);
    //alert('Регистрация не удалась, проверьте логин и пароль.');
    throw err;
  }
}

const MessageHandler = ({ handleMessage }) => {
  useEffect(() => {
    const handleEvent = event => {
      const { message, data, origin } = event;
      console.log('origin', origin);
      if (origin === 'app://localhost' || origin === 'app://lk.samaraenergo.ru' || origin === "https://localhost")
        handleMessage(message || data);
    };

    window.addEventListener('message', handleEvent, false);
    return function cleanup() {
      window.removeEventListener('message', handleEvent);
    };
  });

  return <React.Fragment />;
};


export function Login() {



  const [state, setState] = useState({ invalid: false, btnDisabled: false, error: '', login: '' });
  let context = useContext(AuthContext);
  let navigate = useNavigate();

  useEffect(() => {
    if (IsCordova() && window.parent) {
      window.parent.postMessage(JSON.stringify({
        type: 'get', name: localStorageUserKey
      }), '*');
    }
  }, []);

  console.log('Login page load, user =', context.user);
  if (context.user?.Account) return <Navigate to="/"></Navigate>;

  const LoginAndPassCheck = async (login: string, password: string) => {
    var user: User = { login: login, password: password, Account: null, ContractSelectedIndex: 0 };

    context.setLoading(true);
    setState({ ...state, invalid: false, btnDisabled: true, error: '' });

    if (user.login.length != 12) {
      context.setLoading(false);
      setState({
        ...state,
        invalid: true, btnDisabled: false,
        error: 'Номер лицевого счета должен состоять из 12 цифр.'
      }); return;
    }

    try {
      user = await LoginProcess(login, password, context);

    } catch (err) {
      console.error('odata err', err);
      //alert('Регистрация не удалась, проверьте логин и пароль.');
      setState({
        ...state,
        invalid: true, btnDisabled: false,
        error:
          err.rootCause?.request?.status == 401 ? 'Регистрация не удалась, проверьте логин и пароль.' :
            (err.rootCause?.response?.data?.error?.message?.value ?? err.message)
      });
      context.setLoading(false);
      return;
    }

    const { passwordStatSetApi } = erpUtilitiesUmcService();
    try {
      var passStatus = await passwordStatSetApi.requestBuilder().getByKey(user.login)
        .execute(
          {
            url: context.host, queryParameters: {
              "sap-language": "RU",
              "sap-user": user.login,
              "sap-password": user.password,
            }
          }
        );


    } catch (err) {
      console.error('passwordStatSetApi odata err', err);
      setState({ ...state, invalid: true, btnDisabled: false, error: err.rootCause?.response?.data?.error?.message?.value ?? err.message });
      context.setLoading(false);
      return;
    }

    context.setLoading(false);

    console.log('Account', user.Account);
    console.log('passStatus', passStatus);

    //passStatus.passStat = 1;
    switch (passStatus.passStat) {
      case 1:
        context.handleUserChange(user);
        navigate("/resetPassword", { replace: true });
        break;
      case 2:
        context.handleUserChange(user);
        navigate("/", { replace: true });
        break;
      default:
        setState({ ...state, invalid: true, btnDisabled: false, error: 'Учётная запись заблокирована' });
        break;
    }
  }


  const formSubmit = async (e) => {
    e.preventDefault();
    console.log('Login submit');
    let data = new FormData(e.target);
    await LoginAndPassCheck(data.get("user_login").toString(), data.get("user_password").toString());

  };


  const inputs = () => <>
    <FloatingLabel label="Номер лицевого счета*" className="mb-3 has-validation">
      <Form.Control type="text" placeholder="Номер лицевого счета" name="user_login" required className={state.invalid ? 'is-invalid' : ''}
        value={state.login} onChange={e => setState(s => ({
          ...s,
          login: e.target.value.replace(/[^0-9]/gi, '').substring(0, 12)
        }))}
      />
      <div className={`invalid-feedback loginForm_invalid_feedback`}>
        {state.error}
      </div>
    </FloatingLabel>

    <FloatingLabel label="Пароль" className="mb-3">
      <Form.Control type="password" placeholder="Пароль" name="user_password" required />
    </FloatingLabel>
    <div className="my-3 text-end">
      <Link to="/recover">Забыли пароль?</Link>
    </div>
  </>;

  if (context.isMobile)
    return (
      <LoginTemplate>
        <form onSubmit={formSubmit} className="d-flex flex-column justify-content-between" style={{ minHeight: "100vh" }}>
          <LoginNav context={context} />
          {inputs()}
          {/* <div className='mt-auto text-center fs-6 mb-2' style={{}}>
            Регистрация в личном кабинете – это возможность участия
            в <a href="https://www.samaraenergo.ru/fiz-licam/action/" target='_blank'>Акциях для клиентов</a>
          </div> */}
          <Button type="submit" variant="primary" className="mt-auto w-100 mb-2" disabled={state.btnDisabled || state.login == ''}>Войти в личный кабинет</Button>
          <a className="mb-2 w-100 text-center d-block py-3" href='https://www.samaraenergo.ru/files/Politika_v_otnoshenii_PDn.pdf' target="_blank" rel="noreferrer">Пользовательское соглашение</a>
        </form>

        {IsCordova() && <MessageHandler handleMessage={async (j) => {
          try {
            if (j == null || j == '') return;
            j = JSON.parse(j);
            if (j == null) return;
            console.log('message from parent', j);
            if (j.type === 'get_resp' && j.name === localStorageUserKey && j.value) {
              j = JSON.parse(j.value);
              if (j != null) {
                console.log('login from cordova app', j);
                await LoginAndPassCheck(j.login, j.password);
              }
            }
          } catch (e) {
            console.error('MessageHandler handleMessage error', e);
          }
        }} />}

      </LoginTemplate >
    )

  return (
    <LoginTemplate>
      <h4 className={`my-4 ${styles.loginHeader}`}>Вход в личный кабинет</h4>
      <LoginNav context={context} />
      <form onSubmit={formSubmit}>
        {inputs()}
        <Button type="submit" variant="primary" className="mt-5 w-100" disabled={state.btnDisabled}>Войти в личный кабинет</Button>
      </form>
    </LoginTemplate>
  );

}