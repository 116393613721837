import { erpUtilitiesUmcService, MeterReadingResults, RegistersToRead } from "erp-utilities-umc-service";
import moment from "moment";
import { useEffect, useState } from "react";
import { AuthData, IsAndroid, IsCordova, IsIOS } from "../contexts/AuthContextProvider";
import { Button, Card, Col, Container, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { BigNumber } from 'bignumber.js'
import { csrf } from "@sap-cloud-sdk/http-client";
import { ReactComponent as DateAlert } from './../images/date.svg';
import { ReadingsList } from "./ReadingsList";
import homeStyles from "./Home.module.css";
import { Meter } from "../components/MeterList";
import { RemoveNullFields } from "../components/RemoveNullFieldsMiddleware";
import { useNavigate } from "react-router-dom";
import { zbankdataresponse1Srv } from "zbankdataresponse1_srv";
import { wait } from "@testing-library/user-event/dist/utils";

moment.locale('ru');

const formatterKvt = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


interface IProps {
    context: AuthData;
    selectedMeter: Meter;
}

export function ReadingsNew(props: IProps) {
    const [state, setState] = useState({
        newReadings: [] as Array<{
            newVal: number, reg: RegistersToRead, previousResult: number, previousCons: number
        }>,
        allReadings: [] as MeterReadingResults[]
    });
    const [refreshConsList, setRefreshConsList] = useState(false);
    const [askueAvaiable, setAskueAvaiable] = useState(false);
    const [askueUrl, setAskueUrl] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    var fetchHistory = async () => {
        const { meterReadingResultsApi } = erpUtilitiesUmcService();
        props.context.setLoading(true);
        try {
            console.log('meterReadingResultsApi');

            var docs = await meterReadingResultsApi
                .requestBuilder()
                .getAll()
                .setBasePath(`${MeterReadingResults._defaultBasePath}/Devices('${props.selectedMeter.dev.deviceId}')`)
                .addCustomQueryParameters(props.context.getOdataExtraFields())
                .execute(
                    { url: props.context.host }
                );
            console.log('meterReadingResultsApi set loaded', docs);
            return docs;
        } catch (e) {
            console.error('odata err', e);
        } finally {
            props.context.setLoading(false);
        }
    }
    // https://reactjs.org/docs/hooks-effect.html
    useEffect(() => {
        (async () => {
            if (props.selectedMeter?.regs != null) {
                var allreadings = await fetchHistory();
                setState((s) => ({ ...s, allReadings: allreadings }));
                if (allreadings) allreadings.reverse();
                setState((s) => ({
                    ...s,
                    newReadings: props.selectedMeter.regs.map(r => {
                        var lastCons = 0;
                        if (allreadings) {
                            var zoneReadings = allreadings.filter(h => h.zwarttxt == r.zwarttxt && h.prkrasch != '').map(h => h.readingResult.toNumber());
                            console.log('set register last cons', zoneReadings);
                            var lastCons = zoneReadings.length >= 2 ? zoneReadings[0] - zoneReadings[1] : 0;
                        }
                        return {
                            newVal: 0,//r.previousMeterReadingResult.toNumber() + lastCons, 
                            reg: r, previousResult: r.previousMeterReadingResult.toNumber(), previousCons: lastCons
                        }
                    })
                }));
            }
        })();
        // кнопка аскуэ
        (async () => {
            if (!props.selectedMeter) return
            setAskueAvaiable(props.selectedMeter.dev.sernr == 'X')
        })()
    }, [props.selectedMeter]);


    var formSubmit = async (e) => {
        e.preventDefault();
        console.log('BillsInvoicesPay form submit');

        props.context.setLoading(true);
        const { meterReadingResultsApi } = erpUtilitiesUmcService();
        try {
            console.log('post meterReadingResultsApi');
            var noteId = "920"; // web
            if (IsCordova() && IsAndroid()) noteId = "921";
            if (IsCordova() && IsIOS()) noteId = "922";

            var subMrs: MeterReadingResults[] = [];
            if (state.newReadings[1])
                subMrs.push(await meterReadingResultsApi
                    .entityBuilder()
                    .dependentMeterReadingResults([])
                    .deviceId(props.selectedMeter.dev.deviceId)
                    .meterReadingNoteId(noteId)
                    .readingDateTime(moment())
                    .readingResult(BigNumber(state.newReadings[1].newVal))
                    .registerId(state.newReadings[1].reg.registerId)
                    .build());
            if (state.newReadings[2])
                subMrs.push(await meterReadingResultsApi
                    .entityBuilder()
                    .dependentMeterReadingResults([])
                    .deviceId(props.selectedMeter.dev.deviceId)
                    .meterReadingNoteId(noteId)
                    .readingDateTime(moment())
                    .readingResult(BigNumber(state.newReadings[2].newVal))
                    .registerId(state.newReadings[2].reg.registerId)
                    .build());
            var mr1 = await meterReadingResultsApi
                .entityBuilder()
                .dependentMeterReadingResults(subMrs)
                .deviceId(props.selectedMeter.dev.deviceId)
                .meterReadingNoteId(noteId)
                .readingDateTime(moment())
                .readingResult(BigNumber(state.newReadings[0].newVal))
                .registerId(state.newReadings[0].reg.registerId)
                .build();

            console.log('MeterReadingResults builded', mr1);

            var mr2 = await meterReadingResultsApi.requestBuilder()
                .create(mr1)
                .addCustomQueryParameters(props.context.getOdataExtraFields())
                .skipCsrfTokenFetching()
                .middleware([RemoveNullFields, csrf({ url: `${MeterReadingResults._defaultBasePath}?sap-user=${encodeURIComponent(props.context.user.login)}&sap-password=${encodeURIComponent(props.context.user.password)}` })])
                .execute({ url: props.context.host });

            console.log('MeterReadingResults loaded', mr2);
            /*тест*/
            //mr2.summrash = '1488';
            //mr2.commentRash = 'Ошибка лалала';
            const summrash = Math.round(Number(mr2.summrash) || 0);
            if (summrash > 0)
                navigate(`/readingsdone/${props.selectedMeter.ca.vkona}/${summrash}`);
            else {
                var msg = 'Показания счётчика успешно сохранены';
                //if (mr2.commentRash && mr2.commentRash !== '') msg += '. Произвести расчет не удалось: ' + mr2.commentRash;
                props.context.showMessage(msg, false);
                setRefreshConsList(((s) => {
                    wait(2000).then(() => setRefreshConsList(false));
                    return true;
                }));
            }

        } catch (e) {
            console.error('odata err', e);
            props.context.showMessage(e.rootCause?.response?.data?.error?.message?.value ?? e.message, true);
        }
        props.context.setLoading(false);
    };

    if (props.selectedMeter?.regs == null || props.selectedMeter.regs.length === 0) return <></>;

    const goAskue = async () => {
        props.context.setLoading(true);
        const { zbankdataSetApi } = zbankdataresponse1Srv();
        try {
            var set = await zbankdataSetApi
                .requestBuilder()
                .getByKey(props.selectedMeter.ca.vkona, 'PAY', BigNumber(Math.round(100 * 100)))
                .addCustomQueryParameters(props.context.getOdataExtraFields())
                .execute({ url: props.context.host });
            console.log('zbankdataresponse1Srv set loaded', set);
            var address = "https://askue.samaraenergo.ru:8087/?abonentCode=" + props.selectedMeter.ca.vkona;
            // window.open(address, "_blank"); // времянка пока нет https
            setAskueUrl(address)
        } catch (e) {
            console.error('zbankdataSetApi odata err', e);
            props.context.showMessage(<><div className="pb-2">Операция временно недоступна</div>
                <small>{e.rootCause?.response?.data?.error?.message?.value ?? e.message}</small></>, true);
        }
        props.context.setLoading(false);
    }

    if (props.context.isMobile)
        return (<div>
            <h5 className="mt-4 mb-3">Новые показания</h5>
            <form onSubmit={formSubmit} className="mb-4 ">
                {state.newReadings.map((r, index) =>
                    <><Row className="pb-1 mb-1 align-items-center" style={{ borderBottom: (index < state.newReadings.length - 1 ? "1px solid #E7E8EE" : "") }}>
                        <Col xs="6" className="col-auto">
                            <FloatingLabel label={`${r.reg.zwarttxt}, кВт.ч.`} className="has-validation">
                                <Form.Control type="number" placeholder={`${r.reg.zwarttxt}, кВт.ч.`} required value={r.newVal > 0 ? r.newVal : ''}
                                    style={{ fontWeight: "600" }} autoComplete="off" step={0.1}
                                    isInvalid={r.newVal == 0}
                                    onChange={(e) => {
                                        var parsed = Number(e.target.value);
                                        if (!isNaN(parsed) && parsed >= 0)
                                            setState((s) => {
                                                var n = s.newReadings.slice(0);
                                                n[index].newVal = parsed;
                                                return { ...s, newReadings: n };
                                            });
                                    }} />
                            </FloatingLabel>
                        </Col>
                        <Col xs="6">
                            {r.newVal > 0 && <>
                                <div>Потребление:</div>
                                <div style={{ color: "#284695", fontWeight: "600", fontSize: "1.5rem", lineHeight: "130%", overflow: "hidden", minWidth: "10rem" }}>
                                    {formatterKvt.format(r.newVal - r.reg.previousMeterReadingResult.toNumber())}
                                    <span style={{ fontSize: "0.875rem", marginLeft: "3px" }}>кВт.ч.</span>
                                </div>
                            </>}
                            {r.newVal == 0 && <span className="small-gray mb-4 text-danger">Введите новые показания</span>}

                        </Col>
                    </Row>
                    </>)}
                <div className="text-center pt-2">
                    <Button type="submit" variant="primary" className="w-100"
                        disabled={state.newReadings.filter(r => r.newVal == 0).length > 0}>
                        Передать показания</Button>
                </div>
            </form>

            <h5 className="mt-4 mb-3">Последние показания</h5>

            {state.newReadings.map((reg, index) =>
                <Row className="px-3 mb-2 py-4 white-card align-items-end">
                    <Col xs="6">
                        <div className="small-gray mb-2">{reg.reg.zwarttxt}:</div>
                        <div style={{ color: "#284695", fontWeight: "600", fontSize: "1.5rem", lineHeight: "130%", overflow: "hidden", minWidth: "10rem" }}>
                            {formatterKvt.format(reg.previousResult)}
                            <span style={{ fontSize: "0.875rem", marginLeft: "3px" }}>кВт.ч.</span>
                        </div>
                    </Col>

                    <Col xs="6">
                        <div className="small-gray mb-2">Потребление:</div>
                        <div style={{ color: "#284695", fontWeight: "600", fontSize: "1.5rem", lineHeight: "130%", overflow: "hidden", minWidth: "10rem" }}>
                            {formatterKvt.format(reg.previousCons)}
                            <span style={{ fontSize: "0.875rem", marginLeft: "3px" }}>кВт.ч.</span>
                        </div>

                    </Col>
                </Row>
            )}
            {askueAvaiable && <Button variant="outline-primary" className="w-100 mt-2"
                onClick={() => goAskue()}>Перейти в ЛК АСКУЭ</Button>}


            <h5 className="mt-4 mb-3">История</h5>

            <ReadingsList context={props.context} selectedMeter={props.selectedMeter} refreshConsList={refreshConsList} />

            {askueUrl && <Modal show={askueUrl != undefined} onHide={() => setAskueUrl(undefined)} centered fullscreen>
                <Modal.Header closeButton className="p-3">
                    <Modal.Title className="fs-6">Личный кабинет АСКУЭ</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ "padding": "0", "overflow": "hidden" }}>
                    <iframe src={askueUrl} style={{ border: "none", width: "100%", height: "100%" }} seamless={true}>
                    </iframe>
                </Modal.Body>
            </Modal>}
        </div>);

    return (
        <>
            <Container>
                <Row className="mb-4">
                    <Col className="px-2" style={{
                        "display": "grid", "gridTemplateColumns": "repeat(2, 1fr)", "gridAutoRows": "1fr",
                        "gap": "1.5rem", "gridTemplateRows": "max-content"
                    }}>


                        <Card bg="Light" className="px-3 py-4" style={{ backgroundColor: "white" }}>
                            <Card.Body className={homeStyles.home_plitka_body}>
                                <Card.Title className="mb-4">
                                    <Row>
                                        <Col><h5 className="m-0">Новые показания</h5></Col>
                                        <Col className="col-auto small-gray"><DateAlert className="me-2" />на {moment().format("DD.MM.YYYY")}</Col>
                                    </Row>
                                </Card.Title>
                                <Card.Text className={homeStyles.home_plitka_body_text} style={{ fontSize: "0.875rem" }}>
                                    <form onSubmit={formSubmit}>
                                        {state.newReadings.map((r, index) =>
                                            <Row className="pb-2 mb-2 align-items-center" style={{ borderBottom: (index < state.newReadings.length - 1 ? "1px solid #E7E8EE" : "") }}>
                                                <Col xs="3">{r.reg.zwarttxt}:</Col>
                                                <Col xs="5" className="col-auto">
                                                    <div>
                                                        <FloatingLabel label="Новые показания, кВт.ч." className="has-validation">
                                                            <Form.Control type="number" placeholder="Новые показания, кВт.ч." required /*className={state.invalid ? 'is-invalid' : ''}*/ value={r.newVal > 0 ? r.newVal : ''}
                                                                style={{ fontWeight: "600" }} autoComplete="off" step={0.1}
                                                                isInvalid={r.newVal == 0}
                                                                onChange={(e) => {
                                                                    var parsed = Number(e.target.value);
                                                                    if (!isNaN(parsed) && parsed >= 0)
                                                                        setState((s) => {
                                                                            var n = s.newReadings.slice(0);
                                                                            n[index].newVal = parsed;
                                                                            return { ...s, newReadings: n };
                                                                        });
                                                                }} />
                                                        </FloatingLabel>
                                                    </div>
                                                    {r.newVal == 0 && <div className="small-gray mt-2 text-danger">Введите новые показания</div>}
                                                </Col>
                                                <Col xs="4">
                                                    {r.newVal > 0 && <>
                                                        <div>Потребление:</div>
                                                        <div className="text-nowrap" style={{ color: "#284695", fontWeight: "600", fontSize: "1.75rem", lineHeight: "130%", overflow: "hidden", minWidth: "10rem" }}>
                                                            {formatterKvt.format(r.newVal - r.reg.previousMeterReadingResult.toNumber())}
                                                            <span style={{ fontSize: "1rem", marginLeft: "3px" }}>кВт.ч.</span>
                                                        </div>
                                                    </>}
                                                </Col>
                                            </Row>)}
                                        <div className="text-center">
                                            <Button type="submit" variant="primary" className="w-100"
                                                style={{ maxWidth: "14rem" }}
                                                disabled={state.newReadings.filter(r => r.newVal == 0).length > 0}>
                                                Передать показания</Button>
                                        </div>
                                    </form>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card bg="Light" className="px-3 py-4" style={{ backgroundColor: "white" }}>
                            <Card.Body className={homeStyles.home_plitka_body}>
                                <Card.Title className="mb-4">
                                    <Row>
                                        <Col><h5 className="m-0">Последние показания</h5></Col>
                                        <Col className="col-auto small-gray"><DateAlert className="me-2" />на {props.selectedMeter.regs[0].previousMeterReadingDate?.format("DD.MM.YYYY")}</Col>
                                    </Row>
                                </Card.Title>
                                <Card.Text className={homeStyles.home_plitka_body_text} style={{ fontSize: "0.875rem" }}>
                                    {state.newReadings.map((reg, index) =>
                                        <Row className="pb-2 mb-2 align-items-center" style={{
                                            borderBottom: (index < state.newReadings.length - 1 ? "1px solid #E7E8EE" : ""),
                                            minHeight: "calc(3.5rem + calc(var(--bs-border-width) * 2) + 0.625rem)"
                                        }}>
                                            <Col xs="6">
                                                <div className="align-items-center d-flex">
                                                    <div>Показания:</div>
                                                    <div className="text-nowrap ms-2" style={{ "fontSize": "1rem" }}>{formatterKvt.format(reg.previousResult)} кВт.ч.</div>
                                                </div>
                                            </Col>

                                            <Col xs="6">
                                                <div className="align-items-center d-flex">
                                                    <div>Потребление:</div>
                                                    <div className="text-nowrap ms-2" >
                                                        <span style={{ "fontSize": "1.75rem", fontWeight: 600 }}>{formatterKvt.format(reg.previousCons)}</span>
                                                        <span style={{ fontSize: "1rem", marginLeft: "3px" }}>кВт.ч.</span></div>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}

                                    <div className="text-center">
                                        {askueAvaiable && <Button variant="outline-primary" className="w-100" style={{ maxWidth: "14rem" }}
                                            onClick={() => goAskue()}>Перейти в ЛК АСКУЭ</Button>}
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>


            <ReadingsList context={props.context} selectedMeter={props.selectedMeter} refreshConsList={refreshConsList} />

            {askueUrl && <Modal show={askueUrl != undefined} onHide={() => setAskueUrl(undefined)} centered fullscreen>
                <Modal.Header closeButton className="p-3">
                    <Modal.Title>Личный кабинет АСКУЭ</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ "padding": "0", "overflow": "hidden" }}>
                    <iframe src={askueUrl} style={{ border: "none", width: "100%", height: "100%" }} seamless={true}>
                    </iframe>
                </Modal.Body>
            </Modal>}
        </>
    );
}

