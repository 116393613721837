import { Modal } from "react-bootstrap";
import { AuthData } from "../contexts/AuthContextProvider";
import { zbankdataresponseSrv01 } from "zbankdataresponse_srv_01";
import { BigNumber } from 'bignumber.js'
import { useEffect, useState } from "react";

interface IProps {
    context: AuthData;
    vkona: string;
    toPay: number;
    startPayment: boolean
}



export function PaymentIframe(props: IProps) {
    const [state, setState] = useState({
        iframeOpened: false,
        iframeUrl: ''
    });

    const StartPayment = async () => {
        console.log('zbankdataSetApi host=', props.context.host);
        props.context.setLoading(true);
        const { zbankdataSetApi } = zbankdataresponseSrv01();
        try {
            var set = await zbankdataSetApi
                .requestBuilder()
                .getByKey(props.vkona, BigNumber(Math.round(props.toPay * 100)))
                .addCustomQueryParameters(props.context.getOdataExtraFields())
                .execute({ url: props.context.host });
            console.log('zbankdataSetApi set loaded', set);
            if (set.httpurl && set.httpurl != '')
                setState((s) => ({ ...s, iframeUrl: set.httpurl, iframeOpened: true }));
            else
                props.context.showMessage('Ошибка при оплате', true);
        } catch (e) {
            console.error('zbankdataSetApi odata err', e);
            props.context.showMessage(<><div className="pb-2">Операция временно недоступна</div>
                <small>{e.rootCause?.response?.data?.error?.message?.value ?? e.message}</small></>, true);
        }
        props.context.setLoading(false);
    }

    useEffect(() => {
        if (props.startPayment) StartPayment();
    }, [props.startPayment]);

    if (state.iframeOpened && props.vkona && props.vkona != '' && state.iframeUrl != '')
        return <Modal show={state.iframeOpened} onHide={() => setState({ ...state, iframeOpened: false, iframeUrl: '' })} centered fullscreen>
            <Modal.Header closeButton>
                <Modal.Title>Оплата</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ "padding": "0", "overflow": "hidden" }}>
                <iframe src={state.iframeUrl} style={{ border: "none", width: "100%", height: "100%" }} seamless={true}>
                </iframe>
            </Modal.Body>
        </Modal>
}