import { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext, AuthData } from "../contexts/AuthContextProvider";
import styles from "../components/LoginTemplate.module.css";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { erpUtilitiesUmcService } from "erp-utilities-umc-service";
import LoggedTemplate from "../components/LoggedTemplate";
import { ReactComponent as IconOk } from './../images/clarity_success.svg';
import { MobileHeaderNav } from "../components/MobileHeaders";

interface IProps {
    context: AuthData;
}

export function ChangePassword(props: IProps) {
    const [state, setState] = useState({ ok: false });
    let navigate = useNavigate();
    let context = useContext(AuthContext);
    if (!context.user)
        return <Navigate to={"/"} />;

    const formSubmit = async (e) => {
        e.preventDefault();
        console.log('Reset submit');

        let data = new FormData(e.target);
        var cpass = data.get("cpass").toString();
        var pass1 = data.get("pass1").toString();
        var pass2 = data.get("pass2").toString();

        if (pass1 !== pass2) {
            context.showMessage('Новый пароль и подтверждение не совпадают', true);
            return;
        }

        context.setLoading(true);
        const { credentialApi } = erpUtilitiesUmcService();

        try {
            var credential = await credentialApi.requestBuilder().getByKey(context.user.login, pass1, cpass)
                .addCustomQueryParameters(
                    {
                        "sap-language": "RU",
                        "sap-user": context.user.login,
                        "sap-password": context.user.password
                    }
                )
                .execute({ url: context.host });


            context.setLoading(false);
            if (credential != null) {
                console.log('credential', credential);
                var user = { ...context.user, password: pass1 };
                context.handleUserChange(user);
                setState((s) => ({
                    ...s,
                    ok: true
                }));
            }
        } catch (err) {
            context.setLoading(false);
            console.error('odata err', e);
            context.showMessage(err.rootCause?.response?.data?.error?.message?.value ?? err.message, true);
            setState((s) => ({
                ...s,
                ok: false
            }));
        }
        context.setLoading(false);
    };

    if (props.context.isMobile)
        return (<LoggedTemplate>
            <MobileHeaderNav context={props.context} title="Изменить пароль" backUrl={"/"} ></MobileHeaderNav>

            {state.ok &&
                <div className="h-100 d-flex flex-column justify-content-between">
                    <div className="white-card text-center py-4">
                        <IconOk />
                        <h5 className="m-0 pt-3" style={{ color: "#08B233" }}>Новый пароль успешно сохранен!</h5>
                    </div>
                    <Button variant="primary" className="my-3 w-100" onClick={() => navigate('/')}>Назад</Button>
                </div>}

            {!state.ok &&
                <form onSubmit={formSubmit} className="h-100 d-flex flex-column justify-content-between">
                    <div>
                        <div className="my-3">Сначала введите текущий пароль,
                            затем придумайте новый пароль</div>
                        <FloatingLabel label="Текущий пароль" className="mb-3">
                            <Form.Control type="password" placeholder="Текущий пароль" name="cpass" required className="" />
                        </FloatingLabel>
                        <FloatingLabel label="Новый пароль" className="mb-3">
                            <Form.Control type="password" placeholder="Новый пароль" name="pass1" required />
                        </FloatingLabel>
                        <FloatingLabel label="Подтвердите пароль" className="mb-3">
                            <Form.Control type="password" placeholder="Подтвердите пароль" name="pass2" required />
                        </FloatingLabel>
                    </div>

                    <Button type="submit" variant="primary" className="my-3 w-100">Изменить</Button>
                </form>
            }
        </LoggedTemplate>);

    return (<LoggedTemplate>

        {state.ok && <div className="white-card text-center py-4" style={{ "maxWidth": "28rem", margin: "2rem auto" }}>
            <IconOk />
            <h5 className="m-0 pt-3" style={{ color: "#08B233" }}>Новый пароль успешно сохранен!</h5>
        </div>}

        <div className="white-card text-center py-4" style={{ "maxWidth": "28rem", margin: "2rem auto" }}>
            <h5 className="py-3" style={{ color: "#111A39", fontSize: "1.375rem" }}>Изменить пароль</h5>

            <form onSubmit={formSubmit} className="">
                <FloatingLabel label="Старый пароль" className="mb-3">
                    <Form.Control type="password" placeholder="Старый пароль" name="cpass" required />
                </FloatingLabel>
                <FloatingLabel label="Новый пароль" className="mb-3">
                    <Form.Control type="password" placeholder="Новый пароль" name="pass1" required />
                </FloatingLabel>
                <FloatingLabel label="Подтвердите пароль" className="mb-3">
                    <Form.Control type="password" placeholder="Подтвердите пароль" name="pass2" required />
                </FloatingLabel>

                <Button type="submit" variant="primary" className="mt-3 w-100">Сохранить</Button>
            </form>
        </div>
    </LoggedTemplate>);

}
