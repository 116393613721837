import { erpUtilitiesUmcService, PaymentDocuments } from "erp-utilities-umc-service";
import moment from "moment";
import { useEffect, useState } from "react";
import { Row, Col, Dropdown, ToggleButton, Button } from "react-bootstrap";
import { AuthData, IsCordova } from "../contexts/AuthContextProvider";
import styles from "../components/LoggedTemplate.module.css";
import { ReactComponent as ReportIcon } from './../images/report.svg';
import FileSaver from 'file-saver';
import { transliterate as tr, slugify } from 'transliteration';
var mime = require('mime-to-extensions')

moment.locale('ru');

interface IProps {
    context: AuthData;
}



const periods = ['Последний месяц', 'Последние 3 месяца', 'Последние 12 месяцев', 'Все время'];

var fetchPays = async (authContext: AuthData, setNewDocs: (docs: PaymentDocuments[]) => void) => {
    if (authContext.user == null) return;
    const { paymentDocumentsApi } = erpUtilitiesUmcService();
    authContext.setLoading(true);
    try {
        console.log('fetch paymentDocumentsApi');

        var set = await paymentDocumentsApi
            .requestBuilder()
            .getAll()
            .setBasePath(`${PaymentDocuments._defaultBasePath}/Accounts('${authContext.user.Account.accountId}')`)
            .addCustomQueryParameters(authContext.getOdataExtraFields())
            .execute(
                { url: authContext.host }
            );

        console.log('paymentDocumentsApi set loaded', set);
        setNewDocs(set);
    } catch (e) {
        console.error('odata err', e);
    }
    authContext.setLoading(false);
}

export function Reports(props: IProps) {
    const [state, setState] = useState({
        periodType: 0,
        reportType: 1
    });


    var downloadFile = async (url, fn) => {
        console.log('downloadFile, url=', url, 'fn=', fn);
        try {
            var res = await fetch(url);
            var ct = res.headers.get("Content-Type");
            console.log('file resp ', res.statusText, 'ct', ct);
            var array = await res.arrayBuffer();
            var ext = mime.extension(ct);
            console.log('ext', ext);
            if (IsCordova() && window.parent) {
                window.parent.postMessage(JSON.stringify({
                    type: 'file',
                    base64: Buffer.from(array).toString('base64'),
                    mimeType: ct,
                    fileName: slugify(`${fn}.${ext}`)
                }), '*');
            } else {
                var blob = new Blob([array], { type: ct });
                FileSaver.saveAs(blob, `${fn}.${ext}`);
            }
        } catch (e) {
            props.context.showMessage('Ошибка при формировании отчёта', true);
            console.error('Ошибка при формировании отчёта', e);
        }
    }
    var handleDownload = async () => {
        var datumEnd = moment(), datumStart = moment();
        switch (state.periodType) {
            case 0: //Последний месяц
                datumStart.add(-1, 'months')
                break;
            case 1: //Последние 3 месяца
                datumStart.add(-3, 'months')
                break;
            case 2: //Последние 12 месяцев
                datumStart.add(-12, 'months')
                break;
            case 3: //Все время
                datumStart.add(-250, 'years')
                break;
            case 4: //Период
                break;
        }
        var bd = datumStart.format('YYYYMMDD'), ed = datumEnd.format('YYYYMMDD');
        console.log(`start date ${bd}, end date ${ed}`);
        const { report_PdfsApi } = erpUtilitiesUmcService();
        var builder = await report_PdfsApi
            .requestBuilder()
            //.appendPath('/$value')
            .getByKey(state.reportType.toString(), props.context.getCurrentContract().vkona, bd, ed)
            .addCustomQueryParameters(props.context.getOdataExtraFields());

        try {
            props.context.setLoading(true);
            var set = await builder.execute({ url: props.context.host });
            console.log('report_PdfsApi set loaded', set);
        } catch (e) {
            if (e.rootCause?.response?.status == 404) {
                props.context.showMessage('Нет данных за указанный период', true);
            }
            if (e.rootCause?.response?.status == 500) {
                var url = await builder.appendPath('/$value').url({ url: props.context.host });
                console.log('download file url', url);
                await downloadFile(url, `Отчёт_${datumStart}_${datumEnd}`);
            }
        }
        props.context.setLoading(false);

    };
    var handleDownloadAll = () => { };

    if (props.context.isMobile)
        return (
            <>
                <h5 className="mt-4 mb-3">Выберите отчёт</h5>

                <div className="mb-4" style={{
                    "display": "grid", "gridTemplateColumns": "repeat(2, 1fr)", "gridAutoRows": "1fr",
                    "gap": "1rem", "gridTemplateRows": "max-content"
                }}>
                    <ToggleButton type="checkbox" variant="outline-report" className="outline-primary-btn2"
                        checked={state.reportType == 1}
                        onChange={(e) => e.currentTarget.checked && setState(s => ({ ...s, reportType: 1 }))} value={"1"} id="rt1">
                        <ReportIcon />
                        <div className="ps-3 text-start">Справка о расчете пени</div>
                    </ToggleButton>

                    <ToggleButton type="checkbox" variant="outline-report" className="outline-primary-btn2"
                        checked={state.reportType == 2}
                        onChange={(e) => e.currentTarget.checked && setState(s => ({ ...s, reportType: 2 }))} value={"1"} id="rt2">
                        <ReportIcon />
                        <div className="ps-3 text-start">Финансово-лицевой счет</div>
                    </ToggleButton>

                    <ToggleButton type="checkbox" variant="outline-report" className="outline-primary-btn2"
                        checked={state.reportType == 3}
                        onChange={(e) => e.currentTarget.checked && setState(s => ({ ...s, reportType: 3 }))} value={"1"} id="rt3">
                        <ReportIcon />
                        <div className="ps-3 text-start">Справка о задолженности</div>
                    </ToggleButton>
                    <ToggleButton type="checkbox" variant="outline-report" className="outline-primary-btn2"
                        checked={state.reportType == 4}
                        onChange={(e) => e.currentTarget.checked && setState(s => ({ ...s, reportType: 4 }))} value={"1"} id="rt4">
                        <ReportIcon />
                        <div className="ps-3 text-start">Акт сверки задолженности</div>
                    </ToggleButton>
                </div>


                <h5 className="mb-3">Период</h5>
                <div className="mb-4">
                    <Dropdown onSelect={(y) => { setState({ ...state, periodType: parseInt(y) }); }}>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className={`w-100 px-3 text-start ${styles.whiteSelector}`}>
                            {periods[state.periodType]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ "maxHeight": "80vh", "overflowY": "auto", "width": "100%" }}>
                            {periods.map((title, index) => {
                                return (
                                    <Dropdown.Item as="button" eventKey={index} key={index} className={`py-2 ${styles.vkonaSelectItem}`}>
                                        <div>{title}</div>
                                    </Dropdown.Item>
                                )
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="mb-4">
                    <Button onClick={() => handleDownload()} variant="primary" style={{ "minWidth": "10rem" }}>Скачать</Button>
                </div>

            </>
        );


    return (


        <div className="mt-2 white-card">
            <h5 className="pb-2 mb-3">Отчёты</h5>


            <Row className="mb-2 py-2 align-items-center">
                <Col style={{ fontSize: '16px', maxWidth: '190px', color: '#111A39', fontWeight: '600' }}>
                    Выберите отчёт:
                </Col>

                <Col>
                    <ToggleButton type="checkbox" variant="outline-report" className="outline-primary-btn2"
                        checked={state.reportType == 1}
                        onChange={(e) => e.currentTarget.checked && setState(s => ({ ...s, reportType: 1 }))} value={"1"} id="rt1">
                        <ReportIcon />
                        <div className="ps-2">Справка о расчете пени</div>
                    </ToggleButton>
                </Col>

                <Col>
                    <ToggleButton type="checkbox" variant="outline-report" className="outline-primary-btn2"
                        checked={state.reportType == 2}
                        onChange={(e) => e.currentTarget.checked && setState(s => ({ ...s, reportType: 2 }))} value={"1"} id="rt2">
                        <ReportIcon />
                        <div className="ps-2">Финансово-лицевой счет</div>
                    </ToggleButton>
                </Col>

                <Col>
                    <ToggleButton type="checkbox" variant="outline-report" className="outline-primary-btn2"
                        checked={state.reportType == 3}
                        onChange={(e) => e.currentTarget.checked && setState(s => ({ ...s, reportType: 3 }))} value={"1"} id="rt3">
                        <ReportIcon />
                        <div className="ps-2">Справка о задолженности</div>
                    </ToggleButton>
                </Col>

                <Col>
                    <ToggleButton type="checkbox" variant="outline-report" className="outline-primary-btn2"
                        checked={state.reportType == 4}
                        onChange={(e) => e.currentTarget.checked && setState(s => ({ ...s, reportType: 4 }))} value={"1"} id="rt4">
                        <ReportIcon />
                        <div className="ps-2">Акт сверки задолженности</div>
                    </ToggleButton>
                </Col>

            </Row>

            <Row className="mb-2 py-2 align-items-center">
                <Col style={{ fontSize: '16px', maxWidth: '190px', color: '#111A39', fontWeight: '600' }}>
                    Временной период:
                </Col>
                <Col style={{ maxWidth: '230px' }}>
                    <Dropdown onSelect={(y) => { setState({ ...state, periodType: parseInt(y) }); }}>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className={`w-100 px-3 text-start ${styles.graySelector}`}>
                            {periods[state.periodType]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ "maxHeight": "80vh", "overflowY": "auto", "width": "100%" }}>
                            {periods.map((title, index) => {
                                return (
                                    <Dropdown.Item as="button" eventKey={index} key={index} className={`py-2 ${styles.vkonaSelectItem}`}>
                                        <div>{title}</div>
                                    </Dropdown.Item>
                                )
                            })}

                        </Dropdown.Menu>
                    </Dropdown>
                </Col>

            </Row>
            <Row className="mb-2 pt-2 align-items-center">
                <Col>
                    <Button onClick={() => handleDownload()} variant="primary" style={{ "minWidth": "10rem" }}>Скачать</Button>
                    {/* <Button onClick={() => handleDownloadAll()} variant="link" style={{ "minWidth": "10rem" }}>Скачать все</Button> */}

                </Col>
            </Row>

        </div>
    );
}