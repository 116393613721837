import { Alert, Badge, Button, ButtonGroup, Col, Container, Dropdown, ListGroup, Modal, Nav, Row } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { SetStateAction, useContext, useState } from 'react';
import { AuthContext } from '../contexts/AuthContextProvider';
import loginStyles from "../components/LoginTemplate.module.css";
import { Logo, LogoW } from "./SvgImages";
import { SvgIcon } from "@mui/material";
import { stat } from "fs";
import { ReactComponent as CloseImage } from './../images/icn_close.svg';
import { ReactComponent as ArrowDown } from './../images/arrow-down.svg';
import { ReactComponent as IconMenuAskue } from './../images/menu_askue.svg';
import { ReactComponent as IconMenuSettings } from './../images/menu_settings.svg';
import { ReactComponent as IconMenuPasswd } from './../images/menu_passwd.svg';
import { ReactComponent as IconMenuExit } from './../images/menu_exit.svg';
import styles from "./LoggedTemplate.module.css";
import moment from 'moment';
import PullToRefresh from 'pulltorefreshjs';
import { ContractAccounts, NotifSet } from 'erp-utilities-umc-service';
import { isNotReadedNotif, NotificationPopup } from '../pages/Notifications';
import { DefaultDeSerializers } from '@sap-cloud-sdk/odata-v2';
import { getAddr, GetNotReadedNotifications, NotificationsAlerts } from './NotificationsAlerts';

export default function LoggedTemplate({ children }) {
    let context = useContext(AuthContext), user = context.user;
    const [state, setState] = useState({ menuClosed: true });
    const navigate = useNavigate();

    var ca = context.getCurrentContract();
    if (ca == null) return <>{children}</>;
    var addr = getAddr(ca)
    const handleOpenMenu = () => {
        setState((s) => ({ ...s, menuClosed: !s.menuClosed }));
    }


    if (context.isMobile) {
        PullToRefresh.init({
            mainElement: 'body',
            instructionsPullToRefresh: 'Потяните вниз для обновления информации',
            instructionsReleaseToRefresh: 'Отпустите для обновления',
            instructionsRefreshing: 'Обновление',
            onRefresh() {
                window.location.reload();
            }
        });

        return (
            <Container fluid style={{ minHeight: '100vh' }}>
                <Row style={{ minHeight: '100vh' }}>
                    <Col className='d-flex flex-column justify-content-between pb-3'>
                        {children}
                    </Col>
                </Row>
            </Container>
        );
    }

    //console.log('context.getCurrentContract()', context.getCurrentContract())
    return (
        <div>
            <Container className={loginStyles.loginForm} fluid id="LoggedAppContainer">
                <Row style={{ borderBottom: "0.5px solid #ACACAC" }}>
                    <Col>
                        <Container>
                            <Row className='align-items-center flex-nowrap'>
                                <Col className="col-auto">
                                    <Link to="/">{Logo({ maxWidth: "100%" })}</Link>
                                </Col>
                                <Col>
                                    <Nav
                                        className={`flex-nowrap justify-content-center`}
                                        activeKey="/"
                                        style={{ "borderRadius": "5px" }}>
                                        <Nav.Item className={styles.navitem}>
                                            <NavLink to="/" className={({ isActive }) => `nav-link ${styles.navlink} ${(isActive ? styles.navlinkactive : '')}`}>
                                                Сводная информация</NavLink>
                                        </Nav.Item>
                                        <Nav.Item className={styles.navitem}>
                                            <NavLink to="/bills" className={({ isActive }) => `nav-link ${styles.navlink} ${(isActive ? styles.navlinkactive : '')}`}>
                                                Оплата</NavLink>
                                        </Nav.Item>
                                        <Nav.Item className={styles.navitem}>
                                            <NavLink to="/readings" className={({ isActive }) => `nav-link ${styles.navlink} ${(isActive ? styles.navlinkactive : '')}`}>
                                                Показания</NavLink>
                                        </Nav.Item>
                                        <Nav.Item className={styles.navitem}>
                                            <NavLink to="/pays" className={({ isActive }) => `nav-link ${styles.navlink} ${(isActive ? styles.navlinkactive : '')}`}>
                                                Отчёты и история</NavLink>
                                        </Nav.Item>
                                        <Nav.Item className={styles.navitem}>
                                            <NavLink to="/docs" className={({ isActive }) => `nav-link ${styles.navlink} ${(isActive ? styles.navlinkactive : '')}`}>
                                                Договорная документация</NavLink>
                                        </Nav.Item>
                                    </Nav>



                                </Col>
                                <Col className="col-auto" style={{}}>
                                    <div className="d-flex w-100 align-items-center" style={{ position: "relative" }}>
                                        <div className='text-start' onClick={handleOpenMenu} style={{ cursor: "pointer" }}>
                                            <div style={{ "color": "#284695", fontSize: "16px", fontWeight: 600 }} className="pb-2">Л/с №{ca.vkona}</div>
                                            {context.user.Account.contractAccounts.length == 1 && addr &&
                                                <div style={{ "textOverflow": "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "200px", fontSize: "13px", color: "#666666" }}>
                                                    {addr}
                                                </div>}
                                            {context.user.Account.contractAccounts.length > 1 &&
                                                <div style={{ "textOverflow": "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "200px", fontSize: "13px", color: "#666666" }}>
                                                    Всего лицевых счетов: {context.user.Account.contractAccounts.length}
                                                </div>}
                                        </div>
                                        <a onClick={handleOpenMenu} style={{ cursor: "pointer" }} className="px-2">
                                            <ArrowDown />
                                        </a>

                                        {!state.menuClosed && <div style={{
                                            width: "20rem", minHeight: "10rem",
                                            backgroundColor: "white", position: "absolute", top: 0, right: 0,
                                            zIndex: 1000000, borderRadius: "5px", boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)"
                                        }} className="p-3">

                                            <a onClick={handleOpenMenu} style={{ position: "absolute", top: "1rem", right: "1rem", cursor: "pointer" }}>
                                                <CloseImage />
                                            </a>

                                            <div className='text-start px-2' style={{ borderBottom: "0.5px solid #EAEDF2" }}>
                                                {GetNotReadedNotifications(context).map(n => {
                                                    return <div className='py-3'>
                                                        <div className='d-flex justify-content-between align-items-center pb-2 gap-1 pe-4'>
                                                            <div style={{ "color": "#284695", fontSize: "16px", fontWeight: 600 }}>
                                                                Л/с №{n.vkona}
                                                            </div>
                                                            <div className='position-relative' onClick={() => navigate(`/notifications/${n.vkona}`)} style={{ cursor: "pointer" }}>
                                                                {n.notReaded > 0 && <Badge bg='danger' pill className='position-absolute top-0 start-100 translate-middle'>{n.notReaded}</Badge>}
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 16 16">
                                                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        {n.addr ? <div style={{ fontSize: "13px", color: "#666666" }}>{n.addr}</div> : <></>}
                                                    </div>
                                                })}


                                            </div>
                                            <ButtonGroup vertical className="w-100 mt-2">
                                                {/* <Button variant="light" onClick={() => alert('1')} className="text-start">
                                        <IconMenuAskue className="me-2" />
                                        Перейти в ЛК АСКУЭ
                                    </Button> */}
                                                <Button variant="light" onClick={() => navigate("/settings")} className="text-start">
                                                    <IconMenuSettings className="me-3" />
                                                    Личные данные
                                                </Button>
                                                <Button variant="light" onClick={() => navigate('/changepasswd')} className="text-start">
                                                    <IconMenuPasswd className="me-3" />
                                                    Изменить пароль
                                                </Button>
                                                <Button variant="light" onClick={() => navigate("/logout")} className="text-start">
                                                    <IconMenuExit className="me-3" />
                                                    Выйти
                                                </Button>
                                            </ButtonGroup>

                                        </div>}

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>

                <Container className="mb-4 mt-3">
                    <NotificationsAlerts context={context} />

                    <Row>
                        <Col xs="12">
                            {children}
                        </Col>
                    </Row>
                </Container>

                <Row className={`${styles.footer} mt-auto`}>
                    <Col xs="12">
                        <Container>
                            <Row>
                                <Col xs="12" className={`py-4 align-items-center d-flex justify-content-between`}>
                                    <div>
                                        <Link to="/">{LogoW({ maxWidth: "15rem" })}</Link>
                                    </div>
                                    <div>
                                        <div><a href="https://www.samaraenergo.ru/about/" target="_blank" rel="noreferrer">О компании</a></div>
                                        <div><a href="https://www.samaraenergo.ru/fiz-licam/headquarters/" target="_blank" rel="noreferrer">Форма обратной связи</a></div>
                                    </div>
                                    <div>
                                        <div><a href='https://www.samaraenergo.ru/files/Politika_v_otnoshenii_PDn.pdf' target="_blank" rel="noreferrer">Положение о  ПД</a></div>
                                        <div><a href="https://www.samaraenergo.ru/buyer/svodka/" target="_blank" rel="noreferrer">Раскрытие информации</a></div>
                                    </div>
                                    <div>
                                        <div><a href="tel:88001000763">8 (800) 1000 763</a></div>
                                        <div><a href={`mailto:lk@samaraenergo.ru?subject=Новый личный кабинет&body=${encodeURIComponent(`Номер личного кабинета: ${ca.vkona}\r\n\r\nСуть вопроса:`)}`}>Отправить сообщение</a></div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs="12" className={styles.footer2}>
                        <Container>
                            <Row>
                                <Col xs="12" className={`py-2 px-4 ${styles.footer2} text-center`}>
                                    © {moment().format("YYYY")} ПАО «Самараэнерго» Все права защищены и охраняются законом
                                </Col>
                            </Row>
                        </Container>
                    </Col>

                </Row>
            </Container>

            {
                !state.menuClosed && <div style={{
                    background: "linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 49.3%)",
                    position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 10000
                }}></div>
            }
        </div >
    );
}