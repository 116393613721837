import { erpUtilitiesUmcService, FolderPdfSet, GetPdfFoldersSet } from "erp-utilities-umc-service";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoggedTemplate from "../components/LoggedTemplate";
import { AuthContext, AuthData, IsCordova } from "../contexts/AuthContextProvider";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import TreeView from '@mui/lab/TreeView';
// import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import { TreeItem, TreeItemProps, treeItemClasses } from '@mui/x-tree-view';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';

import Typography from '@mui/material/Typography';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { Button, Col, Container, Row } from "react-bootstrap";
import { borderRight } from "@mui/system";

import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { Meter, MeterList } from "../components/MeterList";
import { MobileHeaderNav } from "../components/MobileHeaders";

import { transliterate as tr, slugify } from 'transliteration';
import { randomUUID } from "crypto";

declare module 'react' {
    interface CSSProperties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: '#000000',
    [`& .${treeItemClasses.content}`]: {
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover, &.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected}) !important`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
        [`&:hover svg, &.Mui-focused svg, &.Mui-selected svg, &.Mui-selected.Mui-focused svg`]: {
            filter: 'brightness(0%) invert(100%)'
        },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

function StyledTreeItem(props: StyledTreeItemProps) {
    const {
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        ...other
    } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1, overflowWrap: "anywhere" }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{
                '--tree-view-color': "#FFFFFF",
                '--tree-view-bg-color': "#6D81BF",
            }}
            {...other}
        />
    );
}

function ArrowDropDFolderownIcon(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 16 8" style={{ width: 16, height: 8 }} {...props}>
            <path d="M14.0283 0.656939L7.99938 6.23587L1.97051 0.656939C1.8628 0.557061 1.7182 0.501145 1.56762 0.501145C1.41705 0.501145 1.27245 0.557061 1.16473 0.656939C1.11258 0.705451 1.07114 0.763353 1.04286 0.827257C1.01457 0.891161 1 0.95978 1 1.0291C1 1.09841 1.01457 1.16703 1.04286 1.23094C1.07114 1.29484 1.11258 1.35274 1.16473 1.40125L7.5784 7.33745C7.69103 7.44167 7.8421 7.5 7.99938 7.5C8.15667 7.5 8.30773 7.44167 8.42037 7.33745L14.834 1.4024C14.8866 1.35385 14.9283 1.29581 14.9568 1.23169C14.9853 1.16758 15 1.09869 15 1.0291C15 0.959504 14.9853 0.890617 14.9568 0.826502C14.9283 0.762386 14.8866 0.704342 14.834 0.655794C14.7263 0.555915 14.5817 0.5 14.4311 0.5C14.2806 0.5 14.136 0.555915 14.0283 0.655794V0.656939Z" fill="#284695" stroke="#284695" />
        </SvgIcon>
    );
}

function Folder(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 19 16" style={{ width: 16, height: 16 }} {...props}>
            <path d="M17.0749 15H2.33333C1.97971 15 1.64057 14.8595 1.39052 14.6095C1.14048 14.3594 1 14.0203 1 13.6667V2.33338C1 1.97976 1.14048 1.64062 1.39052 1.39057C1.64057 1.14052 1.97971 1.00005 2.33333 1.00005H6.77498C7.06387 0.997575 7.34535 1.0914 7.57498 1.26671L9.89164 3.00004H16.9999C17.3536 3.00004 17.6927 3.14052 17.9428 3.39056C18.1928 3.64061 18.3333 3.97975 18.3333 4.33337V13.7417C18.3311 14.0747 18.1978 14.3935 17.9623 14.629C17.7268 14.8645 17.408 14.9978 17.0749 15ZM2.33333 2.33338V13.6667H16.9999V4.33337H9.89164C9.60275 4.33584 9.32127 4.24202 9.09164 4.0667L6.77498 2.33338H2.33333Z" fill="#284695" stroke="#284695" />
        </SvgIcon>
    );
}

function ArrowRightIcon(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 9 16" style={{ width: 9, height: 16 }} {...props}>
            <path d="M1.15694 1.97175L6.73587 8.00062L1.15694 14.0295C1.05706 14.1372 1.00115 14.2818 1.00115 14.4324C1.00115 14.583 1.05706 14.7275 1.15694 14.8353C1.20545 14.8874 1.26335 14.9289 1.32726 14.9571C1.39116 14.9854 1.45978 15 1.5291 15C1.59841 15 1.66703 14.9854 1.73094 14.9571C1.79484 14.9289 1.85274 14.8874 1.90125 14.8353L7.83745 8.4216C7.94167 8.30897 8 8.1579 8 8.00062C8 7.84333 7.94167 7.69227 7.83745 7.57963L1.9024 1.16597C1.85385 1.11344 1.79581 1.0717 1.73169 1.04319C1.66758 1.01468 1.59869 1 1.5291 1C1.4595 1 1.39062 1.01468 1.3265 1.04319C1.26239 1.0717 1.20434 1.11344 1.15579 1.16597C1.05592 1.27368 1 1.41829 1 1.56886C1 1.71943 1.05592 1.86403 1.15579 1.97175H1.15694Z" fill="#ACACAC" stroke="#ACACAC" />
        </SvgIcon>
    );
}

function FileIcon(type: string, props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 29 34" style={{ width: 29, height: 34 }} {...props}>
            <text fill="#284695" font-family="Inter" font-size="9" letter-spacing="-0.03em">
                <tspan x="14" y="24.8547" text-anchor="middle">{type}</tspan>
            </text>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8838 0H1.71717C0.768803 0 0 0.761116 0 1.7V32.3C0 33.2389 0.768803 34 1.71717 34H26.6161C27.5645 34 28.3333 33.2389 28.3333 32.3V12.3251L28.3333 12.325L28.3333 12.325L17.1717 1.275L15.8838 0ZM26.2395 12.0002L16.2044 2.02071V10.7502C16.2044 11.4406 16.764 12.0002 17.4544 12.0002H26.2395ZM14.7044 1.275H1.71717C1.48008 1.275 1.28788 1.46528 1.28788 1.7V32.3C1.28788 32.5347 1.48008 32.725 1.71717 32.725H26.6161C26.8532 32.725 27.0454 32.5347 27.0454 32.3V13.5002H17.4544C15.9356 13.5002 14.7044 12.269 14.7044 10.7502V1.275Z" fill="#ACACAC" />
        </SvgIcon>
    );
}

function DownloadIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" style={{ width: 28, height: 28 }} {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 3.25C12.676 3.25 13.0214 3.57671 13.0214 3.97973V13.8937L16.8474 10.2745C17.1486 9.98957 17.6371 9.98957 17.9383 10.2745C18.2396 10.5595 18.2396 11.0216 17.9383 11.3065L12.7955 16.1714C12.4942 16.4564 12.0058 16.4564 11.7045 16.1714L6.56166 11.3065C6.2604 11.0216 6.2604 10.5595 6.56166 10.2745C6.86292 9.98957 7.35136 9.98957 7.65263 10.2745L11.4786 13.8937V3.97973C11.4786 3.57671 11.824 3.25 12.25 3.25ZM4.02143 15.8986C4.44748 15.8986 4.79286 16.2254 4.79286 16.6284V18.5743C4.79286 18.8969 4.92832 19.2062 5.16943 19.4343C5.41055 19.6624 5.73758 19.7905 6.07857 19.7905H18.4214C18.7624 19.7905 19.0894 19.6624 19.3306 19.4343C19.5717 19.2062 19.7071 18.8969 19.7071 18.5743V16.6284C19.7071 16.2254 20.0525 15.8986 20.4786 15.8986C20.9046 15.8986 21.25 16.2254 21.25 16.6284V18.5743C21.25 19.284 20.952 19.9645 20.4215 20.4663C19.8911 20.9681 19.1716 21.25 18.4214 21.25H6.07857C5.32839 21.25 4.60893 20.9681 4.07847 20.4663C3.54801 19.9645 3.25 19.284 3.25 18.5743V16.6284C3.25 16.2254 3.59538 15.8986 4.02143 15.8986Z" fill="white" />
        </SvgIcon>
    );
}


interface IProps {
    context: AuthData;
}

export function Files(props: IProps) {

    const [state, setState] = useState({
        folders: [] as FolderPdfSet[],
        selected: '',
        files: [] as GetPdfFoldersSet[],
        selectedMeter: null as Meter
    });



    useEffect(() => {
        if (props.context.user == null) return;
        if (state.selectedMeter == null) return;
        (async () => {
            const { operations: { getPdfFolder } } = erpUtilitiesUmcService();

            try {
                const vkona = state.selectedMeter.ca.vkona,
                    type = vkona.length == 12 ? '1' : '2';
                var folderset = await getPdfFolder({ contractAccountId: vkona, type: type })
                    .addCustomQueryParameters(props.context.getOdataExtraFields())
                    .execute({ url: props.context.host });
                folderset = folderset.sort((a, b) => parseInt(a.sort) - parseInt(b.sort));
                console.log('FOLDERS loaded', folderset);
                setState((s) => ({
                    ...s, folders: folderset,
                    selected: folderset.length == 2 ? folderset[1].guid : s.selected
                }));

            } catch (e) {
                console.error('odata err', e);
                return;
            }
        })();
    }, [state.selectedMeter]);



    useEffect(() => {
        (async () => {
            if (props.context.user == null) return;
            if (state.selectedMeter == null) return;
            console.log('handleFolderSelect', state.selected);
            props.context.setLoading(true);
            const { operations: { getPdfFolderFile } } = erpUtilitiesUmcService();
            try {
                var folderset2 = await getPdfFolderFile({ contractAccountId: state.selectedMeter.ca.vkona, guid: state.selected })
                    .addCustomQueryParameters(props.context.getOdataExtraFields())
                    .execute({ url: props.context.host });
                console.log('FILES loaded', folderset2);
                setState((s) => ({ ...s, files: folderset2 }));
            } catch (e) {
                console.error('odata err', e);
            }
            props.context.setLoading(false);
        })();
    }, [state.selected, state.folders]);


    const handleDownloadAll = async () => {
        const zip = new JSZip();
        for (const f of state.files) {
            var decoded = Buffer.from(f.value, 'base64'); //Buffer.from(f.value, 'base64').toString('binary');
            zip.file(f.nameFile, decoded);
        }
        zip.generateAsync({ type: 'blob' }).then(function (content) {
            FileSaver.saveAs(content, 'Архив.zip');
        });
    }





    const GetByGuid = (parent: string) => state.folders.filter(f => f.parentGuid == parent);

    const RenderSubList = (folders: FolderPdfSet[]) => {
        return (
            <>
                {folders.map(f => {
                    var subs = GetByGuid(f.guid);
                    if (subs.length == 0) return <StyledTreeItem itemId={f.guid} labelText={f.textFolder} labelIcon={Folder} />;
                    return (
                        <StyledTreeItem itemId={f.guid} labelText={f.textFolder} labelIcon={Folder}>
                            {RenderSubList(subs)}
                        </StyledTreeItem>);
                })}
            </>
        );
    };

    if (props.context.isMobile)
        return (<LoggedTemplate>
            <div>
                <MobileHeaderNav context={props.context} title="Договорные документы" backUrl={"/"} ></MobileHeaderNav>
                <MeterList context={props.context} onSelect={(m) => { setState((s) => ({ ...s, selectedMeter: m })) }}></MeterList>

                {state.folders.length > 2 &&
                    <div className="mb-2">
                        <SimpleTreeView
                            aria-label="Договорные документы"
                            // defaultExpanded={['3']}
                            onSelectedItemsChange={(e, nodeIds) => {
                                setState((s) => ({ ...s, selected: nodeIds[0] }))
                            }}
                            selectedItems={[state.selected]}
                            slots={{
                                expandIcon: ArrowRightIcon,
                                collapseIcon: ArrowDropDFolderownIcon,
                                endIcon: () => <div style={{ width: 24 }} />,
                            }}>
                            {RenderSubList(GetByGuid(''))}
                        </SimpleTreeView>
                    </div>
                }
                <div>
                    {state.files.length > 0 &&
                        <>
                            {state.files.map(f => {
                                var ext = f.nameFile.indexOf('.') != -1 ? f.nameFile.split('.').reverse()[0] : '';
                                return (<>
                                    <Row className="py-2 align-items-center" style={{ "borderBottom": "1px solid #E7E8EE" }}>
                                        <Col style={{ "maxWidth": "fit-content" }}>{FileIcon(ext, {})}</Col>
                                        <Col>{f.nameFile}</Col>
                                        <Col style={{ "maxWidth": "fit-content" }}>
                                            <a download={f.nameFile}
                                                href={`data:application/octet-stream;charset=utf-16le;base64,${f.value}`}
                                                className="btn btn-primary p-2"
                                                onClick={(e) => {
                                                    if (IsCordova() && window.parent) {
                                                        window.parent.postMessage(JSON.stringify({
                                                            type: 'file',
                                                            base64: f.value,
                                                            mimeType: f.mimeType,
                                                            fileName: slugify(f.nameFile)
                                                        }), '*');
                                                        e.preventDefault();
                                                    }
                                                }}>
                                                <DownloadIcon />
                                            </a>
                                        </Col>
                                    </Row >
                                </>
                                )
                            })}
                            {/* {state.files.length > 1 &&
                                <div className="my-5">
                                    <Button onClick={() => handleDownloadAll()} variant="outline-primary" className="btn-xs w-100">Скачать все</Button>
                                </div>
                            } */}
                        </>
                    }

                    {state.files.length == 0 &&
                        <div className="text-center py-4" style={{ "maxWidth": "28rem", margin: "2rem auto" }}>
                            <h5 className="m-0 pt-3" style={{ color: "#000000" }}>Нет файлов для отображения</h5>
                        </div>
                    }
                </div>

            </div>
        </LoggedTemplate >);



    return (
        <LoggedTemplate>

            <MeterList context={props.context} onSelect={(m) => { setState((s) => ({ ...s, selectedMeter: m })) }}></MeterList>

            <Container>
                <Row className="my-3 mx-1 px-1 py-3" style={{ backgroundColor: "white", borderRadius: "5px" }}>
                    {state.folders.length > 2 &&
                        <Col xs="4" lg="3" style={{ borderRight: "1px solid #E7E8EE", overflowY: "hidden" }}>
                            <SimpleTreeView
                                sx={{ maxHeight: '30vh', flexGrow: 1, overflowY: 'auto' }}
                                aria-label="Договорные документы"
                                // defaultExpanded={['3']}
                                onSelectedItemsChange={(e, nodeIds) => {
                                    setState((s) => ({ ...s, selected: nodeIds[0] }))
                                }}
                                selectedItems={[state.selected]}
                                slots={{
                                    expandIcon: ArrowRightIcon,
                                    collapseIcon: ArrowDropDFolderownIcon,
                                    endIcon: () => <div style={{ width: 24 }} />,
                                }}>
                                {RenderSubList(GetByGuid(''))}
                            </SimpleTreeView>
                        </Col>}
                    <Col>
                        {state.files.length > 0 && <>
                            {state.files.map(f => {
                                var ext = f.nameFile.indexOf('.') != -1 ? f.nameFile.split('.').reverse()[0] : '';
                                return (
                                    <Row className="py-3 mx-4 align-items-center file" style={{ "borderBottom": "1px solid #E7E8EE" }}>
                                        <Col style={{ "maxWidth": "fit-content" }}>{FileIcon(ext, {})}</Col>
                                        <Col>{f.nameFile}</Col>
                                        {ext != '' &&
                                            <Col className="px-3 py-1 me-3" style={{ "maxWidth": "fit-content", "color": "#284695", "border": "1px solid #284695", borderRadius: "50px" }}>
                                                {ext}
                                            </Col>
                                        }
                                        <Col style={{ "maxWidth": "fit-content" }}>
                                            <a href={`data:application/octet-stream;charset=utf-16le;base64,${f.value}`} download={f.nameFile}
                                                className="btn btn-primary" style={{ "minWidth": "10rem" }}>
                                                Скачать
                                            </a>
                                        </Col>
                                    </Row>
                                )
                            })}
                            {state.files.length > 1 &&
                                <Row className="py-3 mx-4 align-items-center file" style={{ "borderBottom": "1px solid #E7E8EE" }}>
                                    <Col className="ms-auto" style={{ "maxWidth": "fit-content" }}>
                                        <Button onClick={() => handleDownloadAll()} variant="outline-primary" style={{ "minWidth": "10rem" }}>Скачать все</Button>
                                    </Col>
                                </Row>
                            }
                        </>}

                        {state.files.length == 0 &&
                            <div className="text-center py-4" style={{ "maxWidth": "28rem", margin: "2rem auto" }}>
                                <h5 className="m-0 pt-3" style={{ color: "#000000" }}>Нет файлов для отображения</h5>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        </LoggedTemplate>
    );
}